import React from 'react'
import { connect } from 'react-redux'
import {
  fetchPackage,
  removePackage,
  resetPackage,
  scanPackage,
} from '../../actions/barcode'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { PACKAGE_ACTION } from '../../constants'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Row,
  Col,
  Table,
  InputGroup,
  Input,
  Button,
  Card,
  CardHeader,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import Rounding from '../../utils/rounding'

class Export extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      barcode: '',
      modalToggle: false,
      modalPrintPackagesToggle: false,
      printId: '',
      printData: '',
      modalInvToggle: false,
      disableDownloadButton: false,
      printInvData: '',
    }

    this.printToggle = this.printToggle.bind(this)
    this.printPackagesToggle = this.printPackagesToggle.bind(this)
    this.onClickPrint = this.onClickPrint.bind(this)
    this.onClickPrintInv = this.onClickPrintInv.bind(this)
    this.onClickPrintPackages = this.onClickPrintPackages.bind(this)
    this.printInvToggle = this.printInvToggle.bind(this)
    this.handleInput = this.handleInput.bind(this)
  }

  handleInput = (e) => {
    const { value } = e.target
    this.setState({ barcode: value })
  }

  async getItem() {
    const { barcode } = this.state
    await this.props.dispatch(scanPackage(barcode, PACKAGE_ACTION.EXPORT))
    this.setState({ barcode: '' })
    const { message } = this.props
    if (!!message) {
      toast.error(message);
      return;
    }
    //document.getElementById("kgInput").focus();
  }

  onClickPrint(e, pgk) {
    this.setState({ printData: pgk })
    this.printToggle()
  }

  printToggle() {
    this.setState((prevState) => ({ modalToggle: !prevState.modalToggle }))
  }

  printPackagesToggle() {
    this.setState((prevState) => ({
      modalPrintPackagesToggle: !prevState.modalPrintPackagesToggle,
    }))
  }

  onClickPrintInv(e, pgk) {
    this.setState({ printInvData: pgk })
    this.printInvToggle()
  }

  onClickPrintPackages(e, pgk) {
    this.setState({ printInvData: pgk })
    this.printPackagesToggle()
  }

  printInvToggle() {
    this.setState((prevState) => ({
      modalInvToggle: !prevState.modalInvToggle,
    }))
  }

  componentDidMount() {
    this.props.dispatch(resetPackage())
  }

  async exportData() {
    await this.setState({ disableDownloadButton: true })
    const params = this.props.items.map((i) => i.kgCode)
    axios
      .get('/download', {
        params: { code: params },
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `DOWNLOAD_DATA_${new Date().toJSON().slice(0, 10)}.xlsx`,
        )
        this.setState({ disableDownloadButton: false })
      })
      .catch(() => {
        this.setState({ disableDownloadButton: false })
      })
    return
  }

  removeItem(code) {
    this.props.dispatch(removePackage(code))
  }

  resetItems() {
    this.props.dispatch(resetPackage())
  }

  render() {
    const { barcode } = this.state
    const { items } = this.props
    let totalPacks = 0
    items &&
      items.forEach((element) => {
        let packs = JSON.parse(element.packs)
        if (packs.length && packs[0].quantity !== 0) {
          totalPacks = totalPacks + JSON.parse(element.packs).length
        }
      })
    let totalWeight = parseFloat(0)
    items &&
      items.forEach((element) => {
        let packs = JSON.parse(element.packs)
        packs.forEach((packInfo) => {
          if (packInfo.weight) {
            totalWeight =
              totalWeight + Rounding(packInfo.weight * packInfo.quantity)
          }
        })
      })
    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>TRANG</BreadcrumbItem>
          <BreadcrumbItem>Barcode</BreadcrumbItem>
          <BreadcrumbItem active>Xuất hàng</BreadcrumbItem>
        </Breadcrumb>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Row>
          <Col sm={12} md={6}>
            <Card>
              <CardHeader tag="h5">Xuất hàng</CardHeader>
              <CardBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.getItem()
                  }}
                >
                  <InputGroup>
                    <Input
                      id="kgInput"
                      autoFocus
                      name="code"
                      value={barcode}
                      onChange={this.handleInput}
                    />
                    <Button type="submit" className="btn-search ml-2">Enter</Button>
                  </InputGroup>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} className="mt-3">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <p className="h5 font-weight-bold">Packages List </p>
                {items.length > 0 && (
                  <Button
                    disabled={this.state.disableDownloadButton}
                    onClick={() => this.exportData()}
                  >
                    Tải về Excel
                  </Button>
                )}
              </CardHeader>
              <CardBody>
                {items.length > 0 && (
                  <Table bordered hover responsive size="" striped>
                    <thead className="text-center">
                      <tr>
                        <th>#</th>
                        <th>TP Bill</th>
                        <th>RG Code</th>
                        <th>Contact</th>
                        <th>Address</th>
                        <th>Sent Date</th>
                        <th>Tracking</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {items.map((pkg, i) => (
                        <tr>
                          <td className="align-middle">{i + 1}</td>
                          <td className="align-middle">{pkg.kgCode || ''}</td>
                          <td className="align-middle">
                            {pkg.referenceCode || ''}
                          </td>
                          <td className="align-middle">
                            {' '}
                            {pkg.receiverCompanyName} <br />
                            {pkg.receiverName} <br />
                            {pkg.receiverEmail}
                          </td>
                          <td className="align-middle">
                            {' '}
                            {pkg.receiverCity +
                              ' / ' +
                              pkg.receiverCountry +
                              ' / ' +
                              pkg.receiverPostalCode}{' '}
                            <br /> {pkg.receiverAddr1} <br />{' '}
                            {pkg.receiverAddr2} <br /> {pkg.receiverAddr3}{' '}
                          </td>

                          <td className="align-middle">
                            {new Date(pkg.createdAt).toLocaleDateString()}{' '}
                          </td>

                          <td className="align-middle">
                            {pkg.logisticCode && (
                              <a
                                href={`/tracking/?code=${pkg.kgCode}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {pkg.logisticCode}
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </CardBody>
              {items.length == 0 && (
                <div className="text-center m-2 pb-3">
                  Danh sách trống. Vui lòng quéc mã barcode trên kiện hàng.
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.barcode.isFetching,
    items: state.barcode.items,
    message: state.barcode.message,
  }
}

export default connect(mapStateToProps)(Export)
