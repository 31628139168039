import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Im
import jwt from 'jsonwebtoken';
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  Row,
  Col,
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  FormGroup, Input, Label, Form,
  Pagination, PaginationItem, PaginationLink

} from 'reactstrap';

import Widget from '../../components/Widget';
import { fetchUsers, createUser, updateUser, deleteUser } from '../../actions/user';
import { USER_ROLE, USER_STATUS, PAGINATION_DEFAULT } from '../../constants';
import { getClassColorByStatus } from '../../utils';
import s from './User.module.scss';
import Select from 'react-select';
import axios from "axios";


class User extends Component {
  constructor(props) {
    super(props);
    this.onCreateUser = this.onCreateUser.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleUpdateUser = this.toggleUpdateUser.bind(this);
    this.onUpdateUser = this.onUpdateUser.bind(this);
    this.onDeleteUser = this.onDeleteUser.bind(this);
    this.onCreateUserChange = this.onCreateUserChange.bind(this);

    // update user
    this.onChangeUserCompanyName = this.onChangeUserCompanyName.bind(this);
    this.onChangeUserAddr = this.onChangeUserAddr.bind(this);
    this.onChangeUserEmail = this.onChangeUserEmail.bind(this);
    this.onChangeUserName = this.onChangeUserName.bind(this);
    this.onChangeUserRole = this.onChangeUserRole.bind(this);
    this.onChangeDiscount = this.onChangeDiscount.bind(this);
    this.onChangeUserLocation = this.onChangeUserLocation.bind(this);

    this.state = {
      modalToggle: false,
      modalToggleUpdateUser: false,
      modalUserInfo: {},
      currUser: {},
      params: PAGINATION_DEFAULT,

      createUser: {
        email: "",
        password: "",
        name: "",
        companyName: "",
        address: "",
        country: "",
        city: "",
        status: USER_STATUS.ACTIVE,
        role: USER_ROLE.CUSTOMER,
        discount: 0,
        location: 'HCM'
      },
      listUsers: [],
      salerSelected: []
    }

  }

  /* eslint-disable */
  static propTypes = {
    users: PropTypes.any,
    totalUsers: PropTypes.number,
    isFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };
  /* eslint-enable */

  static defaultProps = {
    users: [],
    totalUsers: 0,
    isFetching: false,
  };

  onCreateUserChange(e) {
    const { name, value } = e.target;
    let createUserInfo = this.state.createUser
    createUserInfo[name] = value
    this.setState({ createUser: createUserInfo })
  }

  async onCreateUser(e) {
    e.preventDefault()
    const data = {...this.state.createUser, saler: this.state.salerSelected.join(",")}
    console.log(data)
    await this.props.dispatch(createUser(data));
    this.setState((prevState, props) => ({ modalToggle: !prevState.modalToggle }));
    await this.props.dispatch(fetchUsers(this.state.params));
    e.preventDefault();
  }
  toggle() {
    this.setState((prevState, props) => ({ modalToggle: !prevState.modalToggle }));
  }
  routeChange=(user)=> {
    let path = `/app/users/${user.id}`;
    this.props.history.push(path);
    return
  }
  toggleUpdateUser(e, user) {
    if (user) {
      const currentRoles = this.state.currUser.role;
      if (currentRoles === USER_ROLE.ACCOUNTANT && user.role !== USER_ROLE.CUSTOMER) {
        return;
      }
      let copyOfUser = Object.assign({}, user)
      this.setState({ modalUserInfo: copyOfUser })
    }

    this.setState((prevState, props) => ({ modalToggleUpdateUser: !prevState.modalToggleUpdateUser }));
  }
  onUpdateUser() {
    this.toggleUpdateUser();

    confirmAlert({
      title: 'Xác nhận Cập nhật',
      message: 'Bạn có chắc muốn cập nhật thông tin về Người dùng này?',
      buttons: [
        {
          label: 'Có',
          onClick: () => {
            this.props.dispatch(updateUser(this.state.modalUserInfo));
          }
        },
        {
          label: 'Không',
          onClick: () => { }
        }
      ]
    });

  }
  onDeleteUser = () => {
    this.toggleUpdateUser();

    confirmAlert({
      title: 'Xác nhận Xoá',
      message: 'Bạn có chắc muốn xoá thông tin về Người dùng này?',
      buttons: [
        {
          label: 'Có',
          onClick: () => {
            this.props.dispatch(deleteUser(this.state.modalUserInfo.id));
          }
        },
        {
          label: 'Không',
          onClick: () => { }
        }
      ]
    });

  };

  /**** Update User's Info field****/
  onChangeUserCompanyName(value) {
    let newState = this.state.modalUserInfo
    newState.companyName = value
    this.setState({ modalUserInfo: newState })
  }
  onChangeUserAddr(value) {
    let newState = this.state.modalUserInfo
    newState.address = value
    this.setState({ modalUserInfo: newState })
  }
  onChangeUserEmail(value) {
    let newState = this.state.modalUserInfo
    newState.email = value
    this.setState({ modalUserInfo: newState })
  }
  onChangeUserName(value) {
    let newState = this.state.modalUserInfo
    newState.displayName = value
    this.setState({ modalUserInfo: newState })
  }
  onChangeUserRole(value) {
    let newState = this.state.modalUserInfo
    newState.role = value
    this.setState({ modalUserInfo: newState })
  }
  onChangeUserLocation(value) {
    let newState = this.state.modalUserInfo
    newState.location = value
    this.setState({ modalUserInfo: newState })
  }
  onChangeDiscount(value) {
    let newState = this.state.modalUserInfo
    newState.discount = value
    this.setState({ modalUserInfo: newState })
  }
  onChangeUserStatus(value) {
    let newState = this.state.modalUserInfo
    newState.status = value
    this.setState({ modalUserInfo: newState })
  }

  componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    this.setState({ currUser: currUser })
    this.props.dispatch(fetchUsers(this.state.params));
    this.getListUsers()
  }


  onSearch(e, page) {
    const params = this.state.params
    params.page = page ? page : this.state.params.page
    this.props.dispatch(fetchUsers(params));
    e.preventDefault();
  }

  async getListUsers() {
    const { data } = await axios.get(`/all-users`)
    console.log(data)
    if (data) {
      this.setState({listUsers: data})
      return
    }
  }

  handleChange(e) {
    const item = e.map(i => i.email)
    console.log(item)
    this.setState({salerSelected: item})
  }

  render() {
    const userRole = this.state.currUser.role === USER_ROLE.ADMIN ? USER_ROLE : {DOCUMENT: USER_ROLE.DOCUMENT, CUSTOMER: USER_ROLE.CUSTOMER}
    let totalPage = parseInt(this.props.totalUsers / this.state.params.limit);
    if ((this.props.totalUsers % this.state.params.limit) > 0) { totalPage++ };
    const locationOption = {
      HCM: "HCM",
      HN: 'HN',
      ĐN: 'ĐN'
    }

    const options = [
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' }
    ]
    return (
      <div>

        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem active> Người dùng </BreadcrumbItem>
        </Breadcrumb>
        <Row>
          {/* Toggle Form Create User */}
          <Modal isOpen={this.state.modalToggle} toggle={this.toggle} >
          <Form onSubmit={(e) => this.onCreateUser(e)}>
            <ModalHeader toggle={this.toggle}>Thêm người dùng</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="companyName">Company Name</Label>
                <Input type="text" name="companyName" id="companyName" placeholder="Company's name" onChange={this.onCreateUserChange} />
              </FormGroup>
              <FormGroup>
                <Label for="companyName">Country</Label>
                <Input type="text" name="country" id="country" placeholder="Country" onChange={this.onCreateUserChange} />
              </FormGroup>
              <FormGroup>
                <Label for="companyName">City</Label>
                <Input type="text" name="city" id="city" placeholder="City" onChange={this.onCreateUserChange} />
              </FormGroup>
              <FormGroup>
                <Label for="address">Address</Label>
                <Input type="text" name="address" id="userEmail" placeholder="Company's address" onChange={this.onCreateUserChange} />
              </FormGroup>
              <FormGroup>
                <Label for="userEmail">Email</Label>
                <Input type="email" name="email" id="userEmail" placeholder="email" onChange={this.onCreateUserChange} />
              </FormGroup>
              <FormGroup>
                <Label for="userPassword">Password</Label>
                <Input type="password" name="password" id="examplePassword" placeholder="password" onChange={this.onCreateUserChange} />
              </FormGroup>
              <FormGroup>
                <Label for="displayName">Name</Label>
                <Input name="displayName" id="displayName" placeholder="username" onChange={this.onCreateUserChange} />
              </FormGroup>
              <FormGroup>
                <Label for="location">Khu vực</Label>
                <Input id="location" name="location" type="select" onChange={this.onCreateUserChange} >
                  {Object.values(locationOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="userRole">Vai trò &nbsp; &nbsp;</Label>
                <Input id="userRole" name="role" type="select" onChange={this.onCreateUserChange} >
                  {Object.values(userRole).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object === 'ops' ? 'Đại lý' : object}</option>)}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="userRole">Đang quản lý Sales sau:</Label>
                <Select
                    defaultValue={[]}
                    isMulti
                    name="colors"
                    options={this.state.listUsers}
                    getOptionLabel ={(option)=>option.email}
                    getOptionValue ={(option)=>option.email}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.handleChange(e)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="discount">Chiếc Khấu (%)</Label>
                <Input name="discount"  type="number" id="discount" placeholder="discount" onChange={this.onCreateUserChange} />
              </FormGroup>

            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary" >Create</Button>{' '}
              <Button color="secondary" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
            </Form>
          </Modal>

        </Row>

          {/* Toggle Form Update User */}
          <Modal isOpen={this.state.modalToggleUpdateUser} toggle={this.toggleUpdateUser} >
            <Form onSubmit={(e) => this.onUpdateUser(e)}>
            <ModalHeader>Modal title</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="companyName">Company Name</Label>
                <Input value={this.state.modalUserInfo.companyName} type="text" name="companyName" id="companyName" placeholder="Company's name" onChange={e => this.onChangeUserCompanyName(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label for="address">Address</Label>
                <Input value={this.state.modalUserInfo.address} type="text" name="address" id="userEmail" placeholder="Company's address" onChange={e => this.onChangeUserAddr(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label for="userEmail">Email</Label>
                <Input value={this.state.modalUserInfo.email} type="email" name="email" id="userEmail" placeholder="email" onChange={e => this.onChangeUserEmail(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label for="userName">Name</Label>
                <Input value={this.state.modalUserInfo.displayName} name="userName" id="userName" placeholder="username" onChange={e => this.onChangeUserName(e.target.value)} />
              </FormGroup>
              {this.state.currUser.role === USER_ROLE.ADMIN &&
              (<FormGroup>
                <Label for="userStatus">Status</Label>
                <Input name="userStatus" id="status" type="select" value={this.state.modalUserInfo.status} onChange={e => this.onChangeUserStatus(e.target.value)}>
                  {Object.values(USER_STATUS).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                </Input>
              </FormGroup>)
              }
              <FormGroup>
                <Label for="userRole">Vai trò &nbsp; &nbsp;</Label>
                <Input id="userRole" name="role" type="select" value={this.state.modalUserInfo.role} onChange={e => this.onChangeUserRole(e.target.value)}>
                  {Object.values(userRole).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object === 'ops' ? 'Đại lý' : object}</option>)}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="location">Khu vực</Label>
                <Input id="location" name="location" type="select" onChange={e => this.onChangeUserLocation(e.target.value)} >
                  {Object.values(locationOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="discount">Chiếc Khấu (%)</Label>
                <Input value={this.state.modalUserInfo.discount} name="discount" id="discount" type="number" placeholder="discount" onChange={e => this.onChangeDiscount(e.target.value)} />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="success" onClick={(e) => this.onUpdateUser(e)}>Update</Button>
              {this.state.currUser.role === USER_ROLE.ADMIN && (<Button type="button" color="danger" onClick={(e) => this.onDeleteUser(e)}>Delete</Button>)}
              <Button color="secondary" onClick={this.toggleUpdateUser}>Cancel</Button>
            </ModalFooter>
            </Form>
          </Modal>
          <Card className="bg-transparent">
            <CardHeader tag="h5" className="d-flex justify-content-between align-items-center">
              <span>Danh sách người dùng</span>
              {
                  this.state.currUser.role === USER_ROLE.ADMIN && (
                      <Button type="button" className="butt" onClick={this.toggle}>+ Tạo mới</Button>
                  )
              }
            </CardHeader>
            <CardBody className="p-0">
              <Table responsive bordered hover className="mb-0 table-hover">
                <thead>
                  <tr>
                    <th>NO.</th>
                    <th>Tên Người dùng</th>
                    <th>Tên Công ty</th>
                    <th>Email</th>
                    <th>Địa chỉ</th>
                    <th>Vai trò</th>
                    <th>Trạng thái</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.users?.length ?
                    this.props.users.map((user, i) => (
                      <tr key={i + 1} onClick={() => this.routeChange(user)}>
                        <td>{i + 1}</td>
                        <td>{user.displayName}</td>
                        <td>{user.companyName}</td>
                        <td>{user.email}</td>
                        <td>{user.address}</td>
                        <td>
                          <span className={ 'px-3 py-2 text-white text-uppercase ' + getClassColorByStatus(user.role) }>{user.role}</span>
                        </td>
                        <td>
                          <span className={ 'px-3 py-2 text-white ' + getClassColorByStatus(user.status) }>{user.status}</span>
                        </td>
                      </tr>
                    )):(
                      <tr>
                        <td colSpan='100' className='text-center'>Danh sách trống.</td>
                      </tr>
                    )
                  }
                  {this.props.isFetching && (
                    <tr>
                      <td colSpan="100">Loading...</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          <CardFooter className="px-0 bg-transparent">
            <Pagination aria-label="Page navigation example">
              {
                [...Array(totalPage)].map((_, i) => (
                  <PaginationItem key={i}> <PaginationLink onClick={e => this.onSearch(e, i + 1)}> {i + 1} </PaginationLink></PaginationItem>
                ))
              }
            </Pagination>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.users.isCreating,
    users: state.users.users.rows,
    totalUsers: state.users.users.count,
  };
}

export default connect(mapStateToProps)(User);
