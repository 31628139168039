import React from 'react'
import axios from 'axios'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button, Card,
  CardBody,
  CardHeader, Col,
  Form,
  FormGroup,
  Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader,
  Row,
  Table
} from "reactstrap";
import {confirmAlert} from "react-confirm-alert";
import jwt from "jsonwebtoken";
import Pagination from "react-js-pagination";
import PrintPickup from "./PrintPickup";
import ReactToPrint from "react-to-print";
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select'
import {USER_ROLE} from "../../constants";
class Pickup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        total: 0,
        list: [],
        size: 20,
        from: 0,
        page: 1
      },
      modalPrintPackagesToggle: false,
      modalToggle: false,
      modalEditToggle: false,
      modalToggleUpdatePickup: false,
      modalPickupInfo: {},
      currUser: {},
      listCustomer: [],
      selectedCustomer: {
        id: '',
        name: '',
        khCode: '',
        phone: '',
        address: ''
      },
      createPickup: {
        customerName: "",
        customerId: "",
        customerAddress: "",
        customerPhone: "",
        note: "",
        paymentType: 'Tiền Mặt',
        statusType: "Chưa nhận",
        location: "HCM"
      },
      editPickup: {
        customerName: "",
        customerId: "",
        customerAddress: "",
        customerPhone: "",
        note: "",
        location: "HCM"
      },
      isEdit: false,
      printId: '',
      printData: '',
      loading: false,
      date: new Date(),
      search: '',
      qStatus: '',
      searchBy: 'pickupId',
      location: '',
      locationOption: {
        HCM: "HCM",
        HN: 'HN',
        ĐN: 'ĐN'
      },
      locationOption2: {
        HCM: "HCM",
        HN: 'HN',
        ĐN: 'ĐN'
      }
    }
    this.onDeletePickup = this.onDeletePickup.bind(this)
    this.openEditModal = this.openEditModal.bind(this)
    this.onEditPickup = this.onEditPickup.bind(this)
    this.onCreatePickup = this.onCreatePickup.bind(this);
    this.onEditPickupChange = this.onEditPickupChange.bind(this);
    this.toggleUpdatePickup = this.toggleUpdatePickup.bind(this);
    this.onCreatePickupChange = this.onCreatePickupChange.bind(this);
    this.onSelectedCustomer = this.onSelectedCustomer.bind(this);
    this.onSelectedEditCustomer = this.onSelectedEditCustomer.bind(this);
    this.onClickPrintPickup = this.onClickPrintPickup.bind(this);
    this.printPackagesToggle = this.printPackagesToggle.bind(this);
    this.handleDatetoSelect = this.handleDatetoSelect.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.toggle = this.toggle.bind(this);
    this.searchStatus = this.searchStatus.bind(this);
    this.searchBy = this.searchBy.bind(this);
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    await this.setState({ currUser, createPickup: {...this.state.createPickup, owner: currUser.displayName} })
    await this.getAllPickup()
    await this.getListCustomer()
  }

  async onCreatePickup(e) {
    e.preventDefault();
    try {
      const { createPickup } = this.state
      let message = ''
      if (!createPickup.customerId) {
        message = 'chọn khách hàng'
      } else if (!createPickup.customerName) {
        message = 'nhập tên khách hàng'
      } else if (!createPickup.customerAddress) {
        message = 'nhập địa chỉ khách hàng'
      } else if (!createPickup.customerPhone) {
        message = 'nhập số điện thoại khách hàng'
      } else if (!createPickup.packs) {
        message = 'nhập số kiện'
      } else if (!createPickup.weight) {
        message = 'nhập cân nặng'
      } else if (!createPickup.service) {
        message = 'chọn dịch vụ'
      } else if (!createPickup.group) {
        message = 'chọn nhóm hàng'
      }
      if (message) {
        toast.error('Vui lòng ' + message)
        return
      }
      await axios.post('/create-pickup', {...createPickup,date: this.state.date})
      this.setState((prevState) => ({ modalToggle: !prevState.modalToggle }));
      await this.getAllPickup()
    } catch (err) {
      toast.error('Đã có lỗi xảy ra ', err.error);
      return
    }
  }
  async onEditPickup(e) {
    const { editPickup } = this.state
    let message = '', createPickup = editPickup
    if (!createPickup.customerId) {
      message = 'chọn khách hàng'
    } else if (!createPickup.customerName) {
      message = 'nhập tên khách hàng'
    } else if (!createPickup.customerAddress) {
      message = 'nhập địa chỉ khách hàng'
    } else if (!createPickup.customerPhone) {
      message = 'nhập số điện thoại khách hàng'
    } else if (!createPickup.packs) {
      message = 'nhập số kiện'
    } else if (!createPickup.weight) {
      message = 'nhập cân nặng'
    } else if (!createPickup.service) {
      message = 'chọn dịch vụ'
    } else if (!createPickup.service) {
      message = 'chọn nhóm hàng'
    }
    if (message) {
      toast.error('Vui lòng ' + message)
      return
    }
    try {
      await axios.post(`/update-pickup/${editPickup.id}`, {...editPickup, date: this.state.date})
      this.setState((prevState) => ({ modalEditToggle: !prevState.modalEditToggle }));
      await this.getAllPickup()
      e.preventDefault();
    } catch (err) {
      toast.error('Đã có lỗi xảy ra');
      return
    }

  }
  toggle() {
    this.setState((prevState, props) => ({ modalToggle: !prevState.modalToggle }));
  }
  openEditModal(obj) {
    console.log(obj)
    this.setState({editPickup: obj, date: moment(obj.date).subtract(7,'h')})
    this.setState((prevState, props) => ({ modalEditToggle: !prevState.modalEditToggle }));

    //const currentCustomer = this.state.listCustomer.filter(i => i.id === obj.id)?[0] : ''
    //this.setState({selectedCustomer: currentCustomer})

  }
  toggleUpdatePickup() {

  }
  onCreatePickupChange(e) {
    const { name, value } = e.target;
    let createPickupInfo = this.state.createPickup
    createPickupInfo[name] = value
    this.setState({ createPickup: createPickupInfo })
  }
  onEditPickupChange(e) {
    const { name, value } = e.target;
    let editPickupInfo = this.state.editPickup
    editPickupInfo[name] = value
    this.setState({ editPickup: editPickupInfo })
  }
  formatNumber (num) {
    if (num) {
      return new Intl.NumberFormat().format(num)
    }
    return num
 }
  async onDeletePickup(id) {
    try {
      const self = this
      confirmAlert({
        title: "Delete Confirmation",
        message: "Are you sure to delete this Pickup?",
        buttons: [
          {
            label: "Yes",
            onClick: async() => {
              const res = await axios.get(`/delete-pickup/${id}`)
              console.log(res)
              await self.getAllPickup()
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
      });

    } catch (e) {
      console.error(e)
    }
  }

  onSelectChange = (e) => {
    const { value } = e
    this.setState({ filterDateType: value })
  }
  onSelectedCustomer = (customer) => {
    console.log('change ', customer)
    if (customer) {
      const { id } = customer
      const temp = this.state.listCustomer.filter(e => e.id == id)?.[0]
      if (temp) {
        let createPickupInfo = this.state.createPickup
        createPickupInfo['customerName'] = temp.name
        createPickupInfo['customerId'] = temp.id
        createPickupInfo['customerAddress'] = temp.address
        createPickupInfo['customerPhone'] = temp.phone
        this.setState({ selectedCustomer: temp, createPickup: createPickupInfo })

      }
    }

  }
  onSelectedEditCustomer = (customer) => {
    console.log('change ', customer)
    if (customer) {
      const { id } = customer
      const temp = this.state.listCustomer.filter(e => e.id == id)?.[0]
      if (temp) {
        let editPickupInfo = this.state.editPickup
        editPickupInfo['customerName'] = temp.name
        editPickupInfo['customerId'] = temp.id
        editPickupInfo['customerAddress'] = temp.address
        editPickupInfo['customerPhone'] = temp.phone
        this.setState({ selectedCustomer: temp, editPickup: editPickupInfo })

      }
    }
  }
  async getAllPickup(params = {}) {
    try {
      const { searchBy, search = '' } = this.state
      this.setState({loading: true})
      let res
      let url = `/get-all-pickup?page=${params.page || 1}&search=${search}&status=${this.state.qStatus}`
      if (searchBy === 'owner') {
        url += `&searchBy=${searchBy}`
      }


      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      if (this.state.location) {
        url +=`&location=${this.state.location}`
      }

      if (this.state.currUser.role === USER_ROLE.MANAGER && this.state.currUser.location) {
        url +=`&location=${this.state.currUser.location}`
        const options = {
          [this.state.currUser.location]: this.state.currUser.location
        }
        console.log(options)
        this.setState({locationOption: options})
        this.forceUpdate()
      }
      res = await axios.get(url)
      if (res && res.data) {
        console.log(res.data)
        this.setState({data: res.data})
      }
      this.setState({loading: false})
    } catch (e) {
      console.error(e)
      this.setState({loading: false})
    }
  }
  async getListCustomer(params = {}) {
    try {
      let url = `/get-all-customer?page=${params.page || 1}&size=3000`
      if (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT
          || this.state.currUser.role === USER_ROLE.OPS || this.state.currUser.role === USER_ROLE.CUSTOMER) {
        url += `&owner=${this.state.currUser.displayName}`
      }
      const res = await axios.get(url )
      if (res && res.data) {
        console.log(res.data)
        const list = res.data.list.map(i => {
          return {
            ...i,
            value: i.id,
            label: `KHRG${i.id}`
          }
        })
        this.setState({listCustomer: list || []})
      }
    } catch (e) {
      console.error(e)
    }
  }
  handlePageChange(pageNumber) {
    if (pageNumber === this.state.data.page) return
    this.getAllPickup({page: pageNumber})
  }

  printPackagesToggle() {
    this.setState((prevState) => ({
      modalPrintPackagesToggle: !prevState.modalPrintPackagesToggle,
    }));
  }

  onClickPrintPickup(obj) {
    this.setState({ printData: obj });
    this.printPackagesToggle();
  }
  handleDatetoSelect (date) {
    console.log(date)
    this.setState({date})
  }

  checkPermission() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS || role === USER_ROLE.SHIPPER) {
        return true
      }
      return false
    } catch (e) {
    }
  }
  checkPermission2() {
    try {
      const role = this.state.currUser.role
      if (role === USER_ROLE.ADMIN || role === USER_ROLE.SALER || role === USER_ROLE.CONSULTANT || role === USER_ROLE.MANAGER || role === USER_ROLE.OPS) {
        return true
      }
      return false
    } catch (e) {
    }
  }
  formatDate = (str) => {
    return moment(str).format('DD/MM/YY HH:mm')
  }
  handleInput = (e) => {
    const { value } = e.target
    this.setState({ search: value })
  }
  onSearch = (e) => {
    this.getAllPickup({search: this.state.search})
  }
  searchStatus(val) {
    console.log(val)
    this.setState({qStatus: val})
  }
  searchBy(val) {
    this.setState({searchBy: val})
  }
  searchBylocation(val) {
    this.setState({location: val})
  }
  render() {
    const { data, editPickup, createPickup, locationOption } = this.state
    const paymentTypeOption = {
      CASH: "Tiền mặt",
      BANK: "Chuyển khoản",
      ALL: "Tiền mặt & Chuyển khoản",
    }
    const statusTypeOption = {
      PROGRESS: "Chưa nhận",
      PROCESSING: "Đang nhận",
      SUCCESS: "Đã nhận",
      //DONE: 'Hoàn thành chứng từ'
    }

    const printPickup = () => {
      return `@media print {
        @page {
          size: A4 portrait;
          margin: 5mm;
          margin-top: 5mm;
        }  }
        `;
    };

    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG </BreadcrumbItem>
          <BreadcrumbItem active> Pickup </BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col sm={10} className='ml-auto'>
            <>
              <CardBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.onSearch()
                  }}
                >
                  <div className="d-flex align-items-end flex-wrap" style={{gap: "10px"}}>
                    <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                            onChange={(e) => this.searchStatus(e.target.value)}
                    >
                      <option value="">Tất cả trạng thái</option>
                      <option value="Đã nhận">Đã nhận</option>
                      <option value="Chưa nhận">Chưa nhận</option>
                    </select>
                    <div>
                      <Label>Search By: </Label>
                      <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                              onChange={(e) => this.searchBy(e.target.value)}
                      >
                        <option value="pickupId">Pickup Id</option>
                        <option value="owner">Người tạo</option>
                      </select>
                    </div>
                    <div>
                      <Label style={{marginRight: '8px'}}>Khu Vực: </Label>
                      <select className="custom-select" style={{width: '200px', marginRight: '10px'}}
                              onChange={(e) => this.searchBylocation(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="HCM">HCM</option>
                        <option value="HN">HN</option>
                        <option value="ĐN">ĐN</option>
                      </select>
                    </div>
                    <InputGroup>
                      <Input
                        id="kgInput"
                        style={{minWidth: '150px'}}
                        name="code"
                        value={this.state.search}
                        placeholder='Tìm kiếm...'
                        onChange={this.handleInput}
                      />
                      <Button type="submit" className="btn-search ml-2">Search</Button>
                    </InputGroup>
                  </div>
                </form>
              </CardBody>
            </>
          </Col>
        </Row>
        <Card style={{marginTop: 20}}>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <span>Danh sách thông tin hàng</span>
            {this.checkPermission() && (
              <Button type="button" className="butt" onClick={this.toggle}>+ Tạo thông tin hàng</Button>
            )}
          </CardHeader>
          <CardBody>
            {this.state.loading && (
              <div className="text-center">
                <span className="loader"></span>
              </div>
            )}
            <Table responsive bordered className="mb-0 table-hover">
              <thead>
              <tr>
                <th>#</th>
                <th>Mã Pickup</th>
                <th>Thông tin người gửi</th>
                <th>Thông tin hàng</th>
                <th>Trạng Thái</th>
                <th>Note</th>
                <th>Người tạo</th>
                <th>Ngày tạo</th>
                <th>Print</th>
                {this.checkPermission() && (
                  <th>Action</th>
                )}
              </tr>
              </thead>
              <tbody>
              {data.list &&
              data.list.map((obj, i) => (
                <tr key={i + 1}>
                  <td>{i + 1}</td>
                  <td>PK{obj.id}</td>
                  <td>
                    <p><strong>Name:</strong> {obj.customerName}</p>
                    <p><strong>Phone:</strong> {obj.customerPhone}</p>
                    <p><strong>Address:</strong> {obj.customerAddress}</p>
                  </td>
                  <td>
                    <div>
                      <p><strong>Số kiện:</strong> {obj.packs}</p>
                      <p><strong>Weight:</strong> {obj.weight}</p>
                      <p><strong>Nhóm hàng:</strong> {obj.group}</p>
                      <p><strong>Dịch vụ:</strong> {obj.service}</p>
                      <p><strong>Hình thức:</strong> {obj.paymentType}</p>
                      <p><strong>Thông tin hàng:</strong> {obj.info}</p>
                      <p><strong>Nhân viên nhận hàng:</strong> {obj.status}</p>
                    </div>
                  </td>
                  <td className='text-center'>
                    <button className={`btn-status ${obj.statusType ==='Đã nhận' ? "btn-success" : "btn-warning"} ${
                      obj.statusType ==='Chưa nhận' ? "btn-danger" : "" 
                    }`}>{obj.statusType}</button>
                  </td>
                  <td>{obj.note}</td>
                  <td>{obj.owner}</td>
                  <td>{this.formatDate(obj.createdAt)}</td>
                  <td className='text-center'>
                    <button className='btn-status' onClick={(e) => this.onClickPrintPickup(obj)}>Print Pickup</button>
                  </td>

                  {this.checkPermission() && (
                    <td>
                      <Button style={{marginRight: '5px', marginBottom: '5px'}} type="button" color="info" onClick={(e) => this.openEditModal(obj)}>Edit</Button>

                      {
                          this.checkPermission2() && (
                              <Button style={{marginBottom: '5px'}} type="button" color="danger" onClick={(e) => this.onDeletePickup(obj.id)}>Delete</Button>

                          )
                      }
                    </td>
                  )}
                </tr>
              ))}
              </tbody>
            </Table>
          </CardBody>

          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={data.page}
            itemsCountPerPage={data.size}
            totalItemsCount={data.total}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            hideFirstLastPages={true}
          />

        </Card>
        <Row>
          {/* Toggle Form Create Pickup */}

          <Modal isOpen={this.state.modalToggle} toggle={this.toggle} >
            <Form>
              <ModalHeader toggle={this.toggle}>Taọ thông tin hàng</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="eventNumber">Khách hàng</Label>
                  {/*<select style={{maxWidth: '100%', display: "block"}}
                          className="custom-select"
                          onChange={(e) => this.onSelectedCustomer(e.target.value)}
                  >
                    <option value="">Chọn khách hàng</option>
                    {this.state.listCustomer && this.state.listCustomer.map((option) => (
                      <option value={option.id} key={option.id}>{option.id}</option>
                    ))}
                  </select>*/}

                  <Select
                    options={this.state.listCustomer}
                    value={this.state.selectedCustomer[0]?.id}
                    onChange={this.onSelectedCustomer}
                    autosize={false}/>
                </FormGroup>
                <FormGroup>
                  <Label for="name">Tên Khách Hàng (*)</Label>
                  <Input type="text" name="customerName" value={createPickup.customerName} id="customerName" placeholder="Tên khách hàng" onChange={this.onCreatePickupChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="content">Địa chỉ (*)</Label>
                  <Input type="text" name="customerAddress" value={createPickup.customerAddress} id="customerAddress" placeholder="address" onChange={this.onCreatePickupChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="price">Phone (*)</Label>
                  <Input name="customerPhone" id="customerPhone" value={createPickup.customerPhone}  placeholder="phone" onChange={this.onCreatePickupChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="eventNumber">Số kiện (*)</Label>
                  <Input type="text" name="packs" id="packs" placeholder="Số kiện" onChange={this.onCreatePickupChange} />
                </FormGroup>
                {/*<FormGroup className="">
                  <Label for="eventNumber" style={{marginRight: '15px'}}>Ngày giờ lấy hàng</Label>
                  <DateTimePicker onChange={this.handleDatetoSelect} value={this.state.date} />
                </FormGroup>*/}
                <FormGroup>
                  <Label for="weight">Cân nặng (*)</Label>
                  <Input type="number" name="weight" id="weight" placeholder="Cân nặng" onChange={this.onCreatePickupChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="information">Thông tin hàng</Label>
                  <Input type="text" name="info" id="info" placeholder="Thông tin hàng" onChange={this.onCreatePickupChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="service">Dịch vụ (*)</Label>
                  <Input type="text" name="service" id="service" placeholder="Dịch vụ" onChange={this.onCreatePickupChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="typeGood">Nhóm hàng (*)</Label>
                  <Input type="text" name="group" id="group" placeholder="Nhóm hàng" onChange={this.onCreatePickupChange} />
                </FormGroup>
                {/*<FormGroup>
                  <Label for="moneyNumber">Số tiền thu khách</Label>
                  <Input type="number" name="totalPrice" id="totalPrice" placeholder="Số tiền thu khách" onChange={this.onCreatePickupChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="paymentType">Hình thức thanh toán</Label>
                  <Input id="paymentType" name="paymentType" type="select" onChange={this.onCreatePickupChange} >
                    {Object.values(paymentTypeOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                  </Input>
                </FormGroup>*/}
                <FormGroup>
                  <Label for="typeGood">Nhân viên nhận hàng</Label>
                  <Input type="text" name="status" id="status" placeholder="Nhân viên nhận hàng" onChange={this.onCreatePickupChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="location">Khu vực</Label>
                  <Input id="location" name="location" type="select" onChange={this.onCreatePickupChange} >
                    {Object.values(this.state.locationOption2).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="statusType">Trạng thái</Label>
                  <Input id="statusType" name="statusType" type="select" onChange={this.onCreatePickupChange} >
                    {Object.values(statusTypeOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="note">Ghi chú</Label>
                  <textarea
                    className="form-control"
                    rows="4"
                    id="note"
                    placeholder="Ghi chú"
                    onChange={this.onCreatePickupChange}
                    name="note"
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary" onClick={(e) => this.onCreatePickup(e)} >Tạo mới</Button>{' '}
                <Button color="secondary" onClick={this.toggle}>Hủy</Button>
              </ModalFooter>
            </Form>
          </Modal>

          {/* Toggle Form Edit Pickup */}
          <Modal isOpen={this.state.modalEditToggle} toggle={this.openEditModal} >
            <Form>
              <ModalHeader toggle={this.openEditModal}>Edit Thông Tin Hàng</ModalHeader>
              <ModalBody>
                {/*<FormGroup>
                  <Label for="eventNumber">Khách hàng</Label>
                  <select style={{maxWidth: '100%', display: "block"}}
                          className="custom-select"
                          onChange={(e) => this.onSelectedCustomer(e.target.value)}
                  >
                    <option value="">Chọn khách hàng</option>
                    {this.state.listCustomer && this.state.listCustomer.map((option) => (
                      <option value={option.id} key={option.id}>{option.name}</option>
                    ))}
                  </select>
                </FormGroup>*/}
                {
                  this.checkPermission2() && (
                      <div>
                        <FormGroup>
                          <Label for="eventNumber">Khách hàng</Label>
                          <Select
                              options={this.state.listCustomer}
                              value={this.state.selectedCustomer[0]?.id}
                              onChange={this.onSelectedEditCustomer}
                              autosize={false}/>
                        </FormGroup>
                        <FormGroup>
                          <Label for="name">Tên Khách Hàng</Label>
                          <Input type="text" name="customerName" value={editPickup.customerName} id="customerName" placeholder="Tên khách hàng" onChange={this.onEditPickupChange}/>
                        </FormGroup>
                        <FormGroup>
                          <Label for="content">Địa chỉ</Label>
                          <Input type="text" name="customerAddress" value={editPickup.customerAddress} id="customerAddress" placeholder="address" onChange={this.onEditPickupChange} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="price">Phone</Label>
                          <Input name="customerPhone" id="customerPhone" value={editPickup.customerPhone}  placeholder="phone" onChange={this.onEditPickupChange}/>
                        </FormGroup>
                        {/*<FormGroup className="">
                  <Label for="eventNumber" style={{marginRight: '15px'}}>Ngày giờ lấy hàng</Label>
                  <DateTimePicker onChange={this.handleDatetoSelect} value={this.state.date} />
                </FormGroup>*/}
                        <FormGroup>
                          <Label for="eventNumber">Số kiện</Label>
                          <Input type="number" name="packs" id="packs" value={editPickup.packs} placeholder="Số kiện" onChange={this.onEditPickupChange} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="weight">Cân nặng</Label>
                          <Input type="number" name="weight" id="weight"  value={editPickup.weight} placeholder="Cân nặng" onChange={this.onEditPickupChange} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="information">Thông tin hàng</Label>
                          <Input type="text" name="info" id="info" value={editPickup.info}  placeholder="Thông tin hàng" onChange={this.onEditPickupChange} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="service">Dịch vụ</Label>
                          <Input type="text" name="service" id="service" value={editPickup.service}  placeholder="Dịch vụ" onChange={this.onEditPickupChange} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="typeGood">Nhóm hàng</Label>
                          <Input type="text" name="group" id="group" value={editPickup.group} placeholder="Nhóm hàng" onChange={this.onEditPickupChange} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="typeGood">Nhân viên nhận hàng</Label>
                          <Input type="text" name="status" id="status" value={editPickup.status} placeholder="Nhân viên nhận hàng" onChange={this.onEditPickupChange} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="location">Khu vực</Label>
                          <Input id="location" name="location" value={editPickup.location} type="select" onChange={this.onEditPickupChange} >
                            {Object.values(locationOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                          </Input>
                        </FormGroup>
                      </div>
                    )
                }
                <FormGroup>
                  <Label for="statusType">Trạng thái</Label>
                  <Input id="statusType" name="statusType" type="select" value={editPickup.statusType} onChange={this.onEditPickupChange} >
                    {Object.values(statusTypeOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="note">Ghi chú</Label>
                  <textarea
                    className="form-control"
                    rows="4"
                    id="note"
                    value={editPickup.note}
                    placeholder="Ghi chú"
                    onChange={this.onEditPickupChange}
                    name="note"
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.onEditPickup(e)}>Update</Button>{' '}
                <Button color="secondary" onClick={this.openEditModal}>Cancel</Button>
              </ModalFooter>
            </Form>
          </Modal>

          <Modal
            isOpen={this.state.modalPrintPackagesToggle}
            scrollable
            centered
            size='lg'
            fullscreen="true |xxl-down'"
            toggle={this.printPackagesToggle}
          >
            <ModalHeader toggle={this.printPackagesToggle}>
              Print Pickup
            </ModalHeader>
            <ModalBody>
              <style> {printPickup()} </style>
              <div>
                <PrintPickup
                  billData={this.state.printData}
                  ref={(el) => (this.componentRef = el)}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <ReactToPrint
                trigger={() => {
                  return (
                    <Button href='#'> Print this out!</Button>
                  );
                }}
                content={() => this.componentRef}
              />
            </ModalFooter>
          </Modal>

        </Row>
      </div>
    )
  }
}
export default Pickup
