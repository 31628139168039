import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  InputGroup,
  FormGroup,
  Label,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormText,
} from 'reactstrap';

import s from "./Packet.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import { confirmAlert } from "react-confirm-alert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import BillToPrint from "./BillToPrint";
import InvoiceToPrint from "./InvoiceToPrint";
import PrintPackages from "./PrintPackages";
// import DateRangePickerCustom from "./dateRangePicker/DateRangePicker";
import fileDownload from 'js-file-download';

import {
  fetchPackages,
  updatePackage,
  deletePackage,
} from "../../actions/package";
import {USER_ROLE} from "../../constants";
import jwt from "jsonwebtoken";
import Rounding from "../../utils/rounding";
import PickupListTable from "./pickupListTable/PickupListTable";
import axios from 'axios'
import {createPickup, fetchPickup, deletePickup, updatePickup} from "../../actions/pickup";
import {DateRangePickerCalendar, START_DATE} from "react-nice-dates";

import EditPacketTitle from '../../images/edit-packet-title.png'
import moment from "moment/moment";


const FilterBillCode = "billCode";
const FilterReceiverCompanyName = "receiverCompanyName";
const FilterSenderCompanyName = "senderCompanyName";
const FilterReceiverAddr = "receiverAddr";
const FilterReferenceCode = "referenceCode";
const FilterLogisticCode = "logisticCode";
const FIRST_PAGE = 1;
const INIT_LIMIT = 20;

class Packet extends React.Component {
  constructor(props) {
    super(props);

    this.filterCriteriaRef = React.createRef();
    this.filterValueRef = React.createRef();

    this.state = {
      currUser: {},
      disableDownloadButton: false,
      fromdate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7,
        new Date().getHours(),
        new Date().getMinutes()
      ), // get last 7 days
      todate: new Date(),
      modalToggle: false,
      modalPrintPackagesToggle: false,
      printId: '',
      printData: '',
      modalInvToggle: false,
      printInvData: '',

      filterLimit: INIT_LIMIT,
      filterPage: FIRST_PAGE,

      modalToggleUpdatePackage: false,
      modalTogglePickup: false,
      pickupStatus: 1,
      currentPage: 1,
      updatingPackage: {},
      oldStatus: "",
      pickup: {
        type: 'Xe tải',
        address: '',
        packageId: '',
        remark: '',
        datetime: '',
        status: 'printed',
        grossWeight: ''
      },
      listPickup: [],
      keySearch: ''
    };
    this.handleDatefromSelect = this.handleDatefromSelect.bind(this);
    this.handleDatetoSelect = this.handleDatetoSelect.bind(this);
    this.printToggle = this.printToggle.bind(this);
    this.printPackagesToggle = this.printPackagesToggle.bind(this);
    this.toggleUpdatePackage = this.toggleUpdatePackage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onClickPrint = this.onClickPrint.bind(this);
    this.onClickPrintInv = this.onClickPrintInv.bind(this);
    this.onClickPrintPackages = this.onClickPrintPackages.bind(this);
    this.printInvToggle = this.printInvToggle.bind(this);
    this.onUpdatePackage = this.onUpdatePackage.bind(this);
    this.onChangeUpdatePackage = this.onChangeUpdatePackage.bind(this);
    this.onDeletePackage = this.onDeletePackage.bind(this);
    this.onChangeLimit = this.onChangeLimit.bind(this);
    this.toggleShowPickupModal = this.toggleShowPickupModal.bind(this);
    this.togglePickupStatus = this.togglePickupStatus.bind(this);
    this.onCreatePickupChange = this.onCreatePickupChange.bind(this);
    this.handleDatePickupSelect = this.handleDatePickupSelect.bind(this);
    this.deletePickup = this.deletePickup.bind(this);
    this.updatePickup = this.updatePickup.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
  }
  handleDatefromSelect(date) {
    this.setState({ fromdate: date });
  }
  handleDatetoSelect(date) {
    this.setState({ todate: date });
  }
  handleDatePickupSelect(date) {
    let createPickupInfo = this.state.pickup
    createPickupInfo.datetime = date
    this.setState({ pickup: createPickupInfo });
  }

  onCreatePickupChange(e) {
    const { name, value } = e.target;
    let createPickupInfo = this.state.pickup
    createPickupInfo[name] = value
    this.setState({ pickup: createPickupInfo })
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    packages: PropTypes.any, // eslint-disable-line
    totalPackages: PropTypes.number,
    isFetching: PropTypes.bool,
    isFetchingPickup: PropTypes.bool,
    pickup: PropTypes.any,
    totalPickup: PropTypes.number,

  };

  static defaultProps = {
    isFetching: false,
    packages: [],
    totalPackages: 0,
    pickup: []
  };

  toggleUpdatePackage(e, pack) {
    if (pack) {
      let copyOfPack = Object.assign({}, pack);
      this.setState({ updatingPackage: copyOfPack, oldStatus: copyOfPack.status });
    }

    this.setState((prevState, props) => ({
      modalToggleUpdatePackage: !prevState.modalToggleUpdatePackage,
    }));
  }

  toggleShowPickupModal() {
    this.setState((prevState) => ({
      modalTogglePickup: !prevState.modalTogglePickup,
    }));
  }

  togglePickupStatus(status) {
    this.setState({
      pickupStatus: status,
    });
  }

  onUpdatePackage(e) {
    this.toggleUpdatePackage();

    confirmAlert({
      title: 'Xác nhận Cập nhật',
      message: 'Bạn có chắc muốn cập nhật thông tin package này?',
      buttons: [
        {
          label: 'Có',
          onClick: async () => {
            this.props.dispatch(updatePackage(this.state.updatingPackage));
            if (this.state.oldStatus !== this.state.updatingPackage.status && this.state.updatingPackage.status === "Sắp giao") {
              try {
                const billupInfo = await this.getBillupById(this.state.updatingPackage.kgCode)
                const { data: user} = await axios.get(`/user-by-name/${billupInfo.owner}`)
                if (user && (user.role !== 'ops')) {
                  axios.post(`/send-gapo`, {
                    requestId: this.state.updatingPackage.kgCode,
                    senderPhone: billupInfo.customerPhone,
                    receiverPhone: billupInfo.receivePhone,
                    ngayTao: moment(this.state.updatingPackage.createdAt).format('DD/MM/YYYY'),
                    tenkh: billupInfo.receiveName,
                    type: "receive"
                  })
                }
              } catch (e) {
                console.log(e)
              }

            }
          },
        },
        {
          label: 'Không',
          onClick: () => {},
        },
      ],
    });
  }

  async getBillupById(kgCode) {
    if (kgCode) {
      try {
        const { data } = await axios.post(`/get-billup/${kgCode}`)
        return data
      } catch (e) {
        return ''
      }
    }
    return ''
  }
  handleChange (value) {
    this.setState({ keySearch: value })
  }
  onDeletePackage = () => {
    this.toggleUpdatePackage();

    confirmAlert({
      title: 'Xác nhận Xoá',
      message: 'Bạn có chắc muốn xoá thông tin kiện hàng này?',
      buttons: [
        {
          label: 'Có',
          onClick: () => {
            this.props.dispatch(deletePackage(this.state.updatingPackage.id));
          },
        },
        {
          label: 'Không',
          onClick: () => {},
        },
      ],
    });
  };

  onChangeUpdatePackage(e) {
    const { name, value } = e.target;
    var newInfo = this.state.updatingPackage;
    newInfo[name] = value;
    this.setState(newInfo);
  }
  handleFocusChange(newFocus) {
    const focus = newFocus ?? START_DATE
    this.setState({ focus })
  }
  setStartDate(startDate) {
    this.setState({ startDate })
  }
  setEndDate(endDate) {
    this.setState({ endDate })
  }

  async componentDidMount() {
    let token = localStorage.getItem('id_token');
    let currUser = jwt.decode(token);
    this.setState({ currUser: currUser });

    this.props.dispatch(
      fetchPackages({
        companyUserID: (currUser.role === USER_ROLE.SALER || currUser.role === USER_ROLE.CONSULTANT || currUser.role === USER_ROLE.OPS) ? currUser.id : "",
        receiverCompanyName: "",
        owner: (currUser.role === USER_ROLE.SALER || currUser.role === USER_ROLE.CONSULTANT || currUser.role === USER_ROLE.OPS) ? currUser.displayName : "",
        senderCompanyName: "",
        receiverAddr: "",
        referenceCode: "",
        logisticCode: "",
        billCode: "",
        limit: this.state.filterLimit,
        page: FIRST_PAGE,
        fromDate: this.state.fromdate,
        toDate: this.state.todate,
      })
    );

    //await this.fetchPickup()
  }

  formatDate = (str) => {
    return str.replace(/,.*$/, '');
  };

  onClickPrint(e, pgk) {
    this.setState({ printData: pgk });
    this.printToggle();
  }

  printToggle() {
    this.setState((prevState) => ({ modalToggle: !prevState.modalToggle }));
  }

  printPackagesToggle() {
    this.setState((prevState) => ({
      modalPrintPackagesToggle: !prevState.modalPrintPackagesToggle,
    }));
  }

  onClickPrintInv(e, pgk) {
    this.setState({ printInvData: pgk });
    this.printInvToggle();
  }

  onClickPrintPackages(e, pgk) {
    this.setState({ printInvData: pgk });
    this.printPackagesToggle();
  }

  printInvToggle() {
    this.setState((prevState) => ({
      modalInvToggle: !prevState.modalInvToggle,
    }));
  }

  onChangeLimit(e) {
    this.setState({
      filterLimit: e.target.value,
    });
  }

  onSearch(e, page) {
    const {currUser} = this.state
    let filterParams = {
      companyUserID:
      (this.state.currUser.role === USER_ROLE.SALER || this.state.currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS)
          ? this.state.currUser.id
          : '',
      owner:  (currUser.role === USER_ROLE.SALER || currUser.role === USER_ROLE.CONSULTANT || this.state.currUser.role === USER_ROLE.OPS) ? currUser.displayName : "",
      limit: this.state.filterLimit,
      page: page ? page : FIRST_PAGE,
      fromDate: this.state.fromdate,
      toDate: this.state.todate,
      referenceCode: ''
    };
    switch (this.filterCriteriaRef.current.value) {
      case FilterBillCode:
        filterParams.billCode = this.state.keySearch;
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        break;
      case FilterReceiverCompanyName:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = this.state.keySearch;
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        break;
      case FilterReceiverAddr:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = this.state.keySearch;
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        break;
      case FilterSenderCompanyName:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = this.state.keySearch;
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        break;
      case FilterReferenceCode:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.logisticCode = '';
        filterParams.referenceCode = this.state.keySearch;
        break;
      case FilterLogisticCode:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = this.state.keySearch;
        break;
      default:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
    }
    this.setState({currentPage: filterParams.page})
    this.props.dispatch(fetchPackages(filterParams));
    e.preventDefault();
  }

  async exportData () {
    let filterParams = {
      companyUserID:
        this.state.currUser.role === USER_ROLE.CUSTOMER
          ? this.state.currUser.id
          : '',
      fromDate: this.state.fromdate,
      toDate: this.state.todate,
    };
    switch (this.filterCriteriaRef.current.value) {
      case FilterBillCode:
        filterParams.code = this.state.keySearch;
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        break;
      case FilterReceiverCompanyName:
        filterParams.code = '';
        filterParams.receiverCompanyName = this.state.keySearch;
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        break;
      case FilterReceiverAddr:
        filterParams.code = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = this.state.keySearch;
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        break;
      case FilterSenderCompanyName:
        filterParams.code = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = this.state.keySearch;
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
        break;
      case FilterReferenceCode:
        filterParams.code = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = this.state.keySearch;
        filterParams.logisticCode = '';
        break;
      case FilterLogisticCode:
        filterParams.billCode = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = this.state.keySearch;
        break;
      default:
        filterParams.code = '';
        filterParams.receiverCompanyName = '';
        filterParams.receiverAddr = '';
        filterParams.senderCompanyName = '';
        filterParams.referenceCode = '';
        filterParams.logisticCode = '';
    }
    await this.setState({disableDownloadButton: true});
    axios.get('/download', {
      params: filterParams,
      responseType: 'blob',
    }).then(res => {
      fileDownload(res.data, `DOWNLOAD_DATA_${new Date().toJSON().slice(0, 10)}.xlsx`);
      this.setState({disableDownloadButton: false});
    }).catch(() => { this.setState({disableDownloadButton: false}) });
    return
  }

  async submitPickup() {
    let { pickup } = this.state
    if (!pickup.packageId || !pickup.datetime || !pickup.address) {
      toast.error('Vui lòng nhập đầy đủ thông tin');
      return
    }
    const currentPackage = await this.getLogisticCode(pickup.packageId)
    console.log(currentPackage)
    if (!currentPackage.data) {
      toast.error('Đơn hàng không tồn tại trong hệ thống');
      return;
    }
    pickup.companyUserID = this.state.currUser.id
    if (this.state.currUser.role === USER_ROLE.ADMIN || this.state.currUser.role === USER_ROLE.OPS || this.state.currUser.id === currentPackage.data.companyUserID) {
      this.props.dispatch(createPickup(pickup))
      this.setState({ pickup: {}, pickupStatus: 2 })
      toast.success('Tạo pickup thành công!');
      await this.fetchPickup()
    } else {
      toast.error('Account không có quyền tạo pickup cho đơn hàng này');
      return;
    }

  }

  async getLogisticCode(code) {
    return axios.get(`/package-by-code/${code}`)
  }

  async fetchPickup() {
    let token = localStorage.getItem('id_token');
    let currUser = jwt.decode(token);
    const a = await axios.post('/list-pickup', {
      isAdmin: currUser.role === USER_ROLE.ADMIN || currUser.role === USER_ROLE.OPS,
      userId: currUser.id
    })
    if (a.data && a.data.list) {
      this.setState({listPickup: a.data.list})
    }
  }
  async deletePickup(id) {
    this.props.dispatch(deletePickup(id));
    setTimeout(async () => {
      await this.fetchPickup()
    }, 500)
  }

  async updatePickup(id) {
    await axios.post('/update-pickup', { id, status: 'Delivered' })
    await this.fetchPickup()
  }

  render() {
    const printBill = () => {
      return `@media print {
        @page { 
          size: A4 portrait;
          margin: 5mm;
        }  }
        
        table, tbody,td,tr {  
         border: 1px solid black ;
         padding-left: 10px; 
         font-familly: "Times New Roman"; 
        }
      
      `;
    };

    const printInvPage = () => {
      return `@media print {
        @page {
          size: A4 portrait;
          margin: 5mm;
          margin-top: 20mm;
        }  }
         
        table, tbody,th,td,tr {  
          border: 1px solid black;
          font-familly: "Times New Roman"; 
          font-size="40px";
         }
        `;
    };

    const printPackages = () => {
      return `@media print {
        @page {
          size: A4 portrait;
          margin: 5mm;
          margin-top: 5mm;
        }  }
         
        table, tbody,th,td,tr {  
          border: 1px solid black;
          font-familly: "Times New Roman"; 
          font-size="40px";
         }
        `;
    };

    let totalPacks = 0;
    this.props.packages &&
      this.props.packages.forEach((element) => {
        let packs = JSON.parse(element.packs);
        if (packs.length && packs[0].quantity) {
          totalPacks = packs.reduce(function (a, b) {
            return a + parseInt(b['quantity'])
          }, 0)
        }
      });
    let totalWeight = parseFloat(0);
    this.props.packages &&
      this.props.packages.forEach((element) => {
        let packs = JSON.parse(element.packs);
        packs.forEach((packInfo) => {
          if (packInfo.weight) {
            totalWeight =
              totalWeight + Rounding(packInfo.weight * packInfo.quantity);
          }
        });
      });

    // paging
    let totalPage = parseInt(this.props.totalPackages / this.state.filterLimit);
    if (this.props.totalPackages % this.state.filterLimit > 0) {
      totalPage++;
    }

    const { currentPage, disableDownloadButton } = this.state
    let pageLimit = 10
    let start = 0
    let end = pageLimit
    if (totalPage <= pageLimit) {
      pageLimit = totalPage
    }
    if (currentPage - 5 >= 0) {
      start = currentPage - 4
    }
    if (start + pageLimit >= totalPage) {
      start = totalPage - pageLimit
    }
    if (currentPage + 5 >= pageLimit) {
      end = currentPage + 6
      pageLimit = end
      if (totalPage <= pageLimit) {
        pageLimit = totalPage
      }
    }

    const statusTypeOption = {
      PROGRESS: "Đang vận chuyển",
      PROCESSING: "Sắp giao"
    }
    return (
      <div className='packet-list'>
        {/* Update/Delete package */}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Modal
          isOpen={this.state.modalToggleUpdatePackage}
          toggle={this.toggleUpdatePackage}
          size="lg"
          className="modal-edit-packet"
          centered
        >
          <ModalBody>
            <img src={EditPacketTitle} alt="title"/>
            <Row>
              <Col>     <FormGroup>
              <Label for='receiverCompanyName'>Receiver Company Name</Label>
              <Input
                value={this.state.updatingPackage.receiverCompanyName}
                name='receiverCompanyName'
                id='receiverCompanyName'
                placeholder='receiver name'
                onChange={(e) => this.onChangeUpdatePackage(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='receiverName'>Receiver Name</Label>
              <Input
                value={this.state.updatingPackage.receiverName}
                name='receiverName'
                id='receiverName'
                placeholder='receiver name'
                onChange={(e) => this.onChangeUpdatePackage(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='receiverPhone'>Receiver Phone</Label>
              <Input
                value={this.state.updatingPackage.receiverPhone}
                name='receiverPhone'
                id='receiverPhone'
                placeholder='receiver phone'
                onChange={(e) => this.onChangeUpdatePackage(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='receiverCountry'>Receiver Country</Label>
              <Input
                value={this.state.updatingPackage.receiverCountry}
                name='receiverCountry'
                id='receiverCountry'
                placeholder='receiver country'
                onChange={(e) => this.onChangeUpdatePackage(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='receiverCity'>Receiver City</Label>
              <Input
                value={this.state.updatingPackage.receiverCity}
                name='receiverCity'
                id='receiverCity'
                placeholder='receiver city'
                onChange={(e) => this.onChangeUpdatePackage(e)}
              />
            </FormGroup></Col>
              <Col><FormGroup>
              <Label for='receiverProvince'>Receiver Province</Label>
              <Input
                value={this.state.updatingPackage.receiverProvince}
                name='receiverProvince'
                id='receiverProvince'
                placeholder='receiver province'
                onChange={(e) => this.onChangeUpdatePackage(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='referenceCode'>RG Code</Label>
              <Input
                value={this.state.updatingPackage.referenceCode}
                name='referenceCode'
                id='referenceCode'
                placeholder='RG Code'
                onChange={(e) => this.onChangeUpdatePackage(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='receiverAddr1'> Address 1</Label>
              <Input
                value={this.state.updatingPackage.receiverAddr1}
                name='receiverAddr1'
                id='receiverAddr1'
                placeholder='Receiver Address 1'
                onChange={(e) => this.onChangeUpdatePackage(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='receiverAddr2'> Address 2</Label>
              <Input
                value={this.state.updatingPackage.receiverAddr2}
                name='receiverAddr2'
                id='receiverAddr2'
                placeholder='Receiver Address 2'
                onChange={(e) => this.onChangeUpdatePackage(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='logisticCode'> Tracking Code</Label>
              <Input
                value={this.state.updatingPackage.logisticCode}
                name='logisticCode'
                id='logisticCode'
                placeholder='Tracking Code'
                onChange={(e) => this.onChangeUpdatePackage(e)}
              />
            </FormGroup>

            <FormGroup>
              <Label for='status'>Trạng thái đơn hàng</Label>
              <Input id="status" name="status" type="select" onChange={this.onChangeUpdatePackage} value={this.state.updatingPackage.status} >
                {Object.values(statusTypeOption).map((object, i) => <option className="text-capitalize" value={object} key={i}>{object}</option>)}
              </Input>
            </FormGroup>
            </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type='submit'
              color='success'
              className="btn-add btn-update"
              onClick={(e) => this.onUpdatePackage(e)}
            >
              Update
            </Button>
            {
              (this.state.currUser.role === USER_ROLE.ADMIN) && (
                    <Button
                        type='submit'
                        color='danger'
                        className="btn-delete"
                        onClick={(e) => this.onDeletePackage(e)}
                    >
                      Delete
                    </Button>
                )
            }

            <Button color='secondary' className="btn-cancel" onClick={this.toggleUpdatePackage}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {/* Print Bill */}
        <Modal
          isOpen={this.state.modalToggle}
          scrollable
          centered
          size='xl'
          fullscreen="true |xxl-down'"
          toggle={this.printToggle}
        >
          <ModalHeader toggle={this.printToggle}>Bill</ModalHeader>
          <ModalBody>
            <style> {printBill()} </style>{' '}
            <div>
              {' '}
              <BillToPrint
                billData={this.state.printData}
                ref={(el) => (this.componentRef = el)}
              />{' '}
            </div>
          </ModalBody>
          <ModalFooter>
            <ReactToPrint
              trigger={() => {
                return <Button href='#/app/bill'> Print this out!</Button>;
              }}
              content={() => this.componentRef}
            />
          </ModalFooter>
        </Modal>

        {/* Print Invoice */}
        <Modal
          isOpen={this.state.modalInvToggle}
          scrollable
          centered
          size='xl'
          fullscreen="true |xxl-down'"
          toggle={this.printInvToggle}
        >
          <ModalHeader toggle={this.printInvToggle}>Invoice</ModalHeader>
          <ModalBody>
            <style> {printInvPage()} </style>{' '}
            <InvoiceToPrint
              invData={this.state.printInvData}
              ref={(el) => (this.componentRef = el)}
            />
          </ModalBody>
          <ModalFooter>
            <ReactToPrint
              trigger={() => {
                return <Button href='#/app/packet'> Print this out!</Button>;
              }}
              content={() => this.componentRef}
            />
          </ModalFooter>
        </Modal>

        {/* Print Packages */}
        <Modal
          isOpen={this.state.modalPrintPackagesToggle}
          scrollable
          centered
          size='xl'
          fullscreen="true |xxl-down'"
          toggle={this.printPackagesToggle}
        >
          <ModalHeader toggle={this.printPackagesToggle}>
            Shipping Mark
          </ModalHeader>
          <ModalBody>
            <style> {printPackages()} </style>{' '}
            <div className="print-wrapper">
              {' '}
              <PrintPackages
                billData={this.state.printInvData}
                ref={(el) => (this.componentRef = el)}
              />{' '}
            </div>
          </ModalBody>
          <ModalFooter>
            <ReactToPrint
              trigger={() => {
                return (
                  <Button href='#/app/printPackages'> Print this out!</Button>
                );
              }}
              content={() => this.componentRef}
            />
          </ModalFooter>
        </Modal>

        {/* Pickup modal */}
        <Modal
          isOpen={this.state.modalTogglePickup}
          toggle={this.toggleShowPickupModal}
          size="xl"
          fullscreen="true |xxl-down'"
          style={{ transform: "translateY(100px)" }}
        >
          <div className={s.contentBoxPickupModal}>
            <ModalHeader
              toggle={this.toggleShowPickupModal}
              style={{ paddingRight: '15px' }}
            >
            </ModalHeader>
            <div className={s.headerPickupModal}>
                <div
                  className={`${s.headerItem} ${this.state.pickupStatus === 1 &&
                    s.headerItemChecked}`}
                  onClick={() => this.togglePickupStatus(1)}
                >
                  Request a pickup
                </div>
                <div
                  className={`${s.headerItem} ${this.state.pickupStatus === 2 &&
                    s.headerItemChecked}`}
                  onClick={() => this.togglePickupStatus(2)}
                >
                  Pickup List
                </div>
              </div>
            <ModalBody style={{ padding: "20px" }}>
              {this.state.pickupStatus === 1 ? (
                <div className={s.bodyPickupModal}>
                  <form>
                    <Row>
                      <Col>
                        <textarea
                          rows="14"
                          style={{
                            minWidth: "100%",
                            border: "1px solid #d9d9d9",
                            borderRadius: "4px",
                            padding: "4px 11px",
                          }}
                          name="packageId"
                          onChange={this.onCreatePickupChange}
                          placeholder="Please input your order number here that need pickup service"
                        />
                      </Col>
                      <Col>
                        <Form>
                          <FormGroup row>
                            <Label for="pickupType" sm={4}>
                              Pickup Type
                            </Label>
                            <Col sm={8}>
                              <Input
                                id="pickupType"
                                name="type"
                                type="select"
                                onChange={this.onCreatePickupChange}
                                required
                              >
                                <option>Xe tải</option>
                                <option>Bán tải</option>
                                <option>Xe máy</option>
                              </Input>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="pickupDate" sm={4}>
                              Pickup Datetime
                            </Label>
                            <Col sm={8}>
                              <DatePicker
                                className={`form-control pickup-date ${s.pickupDateTimeModal}`}
                                id="pickupDate"
                                placeholderText="Pickup Datetime"
                                name="datetime"
                                dateFormat="dd/MM/yy"
                                selected={this.state.pickup.datetime}
                                onChange={this.handleDatePickupSelect}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="pickupAddress" sm={4}>
                              Pickup Address
                            </Label>
                            <Col sm={8}>
                              <Input id="pickupAddress" name="address"
                                     onChange={this.onCreatePickupChange} required/>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="pickupAddress" sm={4}>
                              Gross Weight
                            </Label>
                            <Col sm={8}>
                              <Input id="grossWeight" name="grossWeight" onChange={this.onCreatePickupChange} required/>
                            </Col>
                          </FormGroup>
                          <FormGroup row style={{ paddingBottom: "50px" }}>
                            <Label for="pickupRemark" sm={4}>
                              Remark
                            </Label>
                            <Col sm={8}>
                              <Input id="pickupRemark" name="remark" onChange={this.onCreatePickupChange}/>
                            </Col>
                          </FormGroup>
                          <Button
                            style={{
                              position: "absolute",
                              bottom: "0",
                              right: "20px",
                              minWidth: "155px",
                            }}
                            onClick={() =>this.submitPickup()}
                          >
                            Send pickup request
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </form>
                </div>
              ) : (
                <>
                  <div>
                    {/*<DateRangePickerCustom
                      onStartDateChange={(e) => this.setStartDate(e)}
                      onEndDateChange={(e) => this.setEndDate(e)}
                      startDate={startDate}
                      endDate={endDate}
                      focus={focus}
                      onFocusChange={(e) => this.handleFocusChange(e)}
                    />*/}
                    <PickupListTable data={this.state.listPickup} deletePickup={this.deletePickup} updatePickup={this.updatePickup} key={this.state.pickupStatus} />
                  </div>
                </>
              )}
            </ModalBody>
          </div>
        </Modal>

        <Breadcrumb>
          <BreadcrumbItem>TRANG</BreadcrumbItem>
          <BreadcrumbItem active>Kiện hàng</BreadcrumbItem>
        </Breadcrumb>

        {
          true ? (
            <div>
              <div className={s.root}>
                <Row>
                  <Col lg={2} md='4' sm='12' xs='12'>
                    <label className='mr-sm-2' htmlFor='type'>
                      Page Size
                    </label>
                    <select
                      className="custom-select mr-sm-2 page-size"
                      style={{ maxWidth: "90px", display: "block" }}
                      id="type"
                      onChange={(e) => this.onChangeLimit(e)}
                    >
                      <option defaultValue='20'>20</option>
                      <option value='40'>40</option>
                      <option value='60'>60</option>
                      <option value='80'>80</option>
                      <option value='100'>100</option>
                    </select>
                  </Col>
                  <Col lg={2} md='4' sm="6" xs="6" className="mb-sm-15">
                    <div style={{ display: "flex", maxWidth: "200px" }}>
                      <InputGroup>
                        <label className='mr-sm-2' htmlFor='fromDate'>
                          From Date
                        </label>
                        <DatePicker
                          id='fromDate'
                          selected={this.state.fromdate}
                          onSelect={this.handleDatefromSelect}
                          className={s.datePickerFilter}
                        />
                      </InputGroup>
                    </div>
                  </Col>
                  <Col lg={2} md='4' sm="6" xs="6" className="mb-sm-15">
                    <div style={{ display: "flex", maxWidth: "200px" }}>
                      <InputGroup>
                        <label className='mr-sm-2' htmlFor='toDate'>
                          To Date
                        </label>
                        <DatePicker
                          id='toDate'
                          selected={this.state.todate}
                          onSelect={this.handleDatetoSelect}
                          className={s.datePickerFilter}
                        />
                      </InputGroup>
                    </div>
                  </Col>
                  <Col lg={2} md='6' sm='4' xs='12'>
                    <label className='mr-sm-2' htmlFor='type'>
                      Search By
                    </label>
                    <select
                      className="custom-select"
                      style={{ maxWidth: "100%", display: "block", paddingLeft: '15px' }}
                      id="type"
                      ref={this.filterCriteriaRef}
                    >
                      <option value='receiverCompanyName' defaultValue>
                        Receiver Company Name
                      </option>
                      <option value='senderCompanyName'>Sender Company Name</option>
                      <option value='billCode'>TP Bill</option>
                      <option value='receiverAddr'>Address</option>
                      <option value='referenceCode'>Reference Code</option>
                      <option value='logisticCode'>Logistic Code</option>
                    </select>
                  </Col>
                  <Col lg={2} md='6' sm='6' xs='9'>
                    <label className='mr-sm-2' htmlFor=''>
                      &nbsp;
                    </label>
                    <div className='wrap-search'>
                      <input
                        style={{ width: '100%', padding: '5px 15px' }}
                        type='text'
                        className='form-control input-search'
                        id='tim'
                        value={this.state.keySearch}
                        placeholder='Search'
                        onChange={(e) => this.handleChange(e.target.value)}
                        ref={this.filterValueRef}
                      />
                      <div className={`remove-text ${!this.state.keySearch?'d-none':''}`} onClick={()=>this.handleChange('')}>
                        Hủy
                      </div>
                    </div>
                  </Col>
                  <Col lg={2} md='12' sm='2' xs='3'>
                    <label className='mr-sm-2' htmlFor=''>
                      &nbsp;
                    </label>
                    <Button
                      className="form-control ml-auto"
                      style={{ maxWidth: "100px", display: "block" }}
                      id="tim"
                      onClick={(e) => this.onSearch(e)}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "20px" }} xs="3" sm="3" md="6">
                  <Col lg={2} md='3' sm="3" xs="3">
                    <label className='mr-sm-2' htmlFor=''>
                      Total Pcs
                    </label>
                    <br />
                    <label className='mr-sm-2 border-input' htmlFor=''>{totalPacks}
                    </label>
                  </Col>
                  <Col lg={2} md='3' sm="5" xs="5">
                    <label className='mr-sm-2'> Total Weight (kg): </label>
                    <br />
                    <label
                      style={{ width: '85px', padding: '5px' }}
                      className='mr-sm-2 border-input'
                      htmlFor=''
                    >
                       {Rounding(totalWeight)}
                    </label>
                  </Col>
                  <Col lg={2} md='3' sm="4" xs="4">
                    <label className='mr-sm-2' htmlFor=''>
                      Total Result
                    </label>
                    <br />
                    <label
                      style={{ width: '80px', padding: '5px' }}
                      className='mr-sm-2 border-input'
                      htmlFor=''
                    >
                      {this.props.totalPackages}
                    </label>
                  </Col>
                  <Col></Col>
                  <Col>
                  <label className='mr-sm-2' htmlFor=''>
                        &nbsp;
                      </label>
                    <div className='d-flex justify-content-end'>
                      {
                        this.state.currUser.role !== USER_ROLE.OPS && (
                          <Button
                            className="form-control"
                            style={{ maxWidth: "100px", display: "block" }}
                            onClick={() => this.exportData()}
                            disabled={disableDownloadButton}
                          >Download</Button>
                        )
                      }
                    </div>
                  </Col>
                </Row>
              </div>

              <div className={s.root}>
                <Table responsive bordered hover size="md">
                  <thead className="text-uppercase table-light font-weight-bold text-center">
                    <tr>
                      <th width='30px'> No. </th>
                      {/*<th>TP Bill</th>*/}
                      {(this.state.currUser.role !== USER_ROLE.OPS && this.state.currUser.role !== USER_ROLE.SALER && this.state.currUser.role !== USER_ROLE.MANAGER) && (
                          <th>Ref Code</th>
                      )}
                      <th>RG Code</th>
                      <th>Contact</th>

                      <th>Address</th>
                      <th>Sent Date</th>
                      <th>Tracking</th>
                      <th>Service</th>
                      <th style={{width: '18%'}}>Bill/Invoice</th>
                      {(this.state.currUser.role === USER_ROLE.ADMIN || this.state.currUser.email == 'customerserviceringo@gmail.com') && (
                        <th>Update/Delete</th>
                      )}
                    </tr>
                  </thead>

                  <tbody className='text-center'>
                    {this.props.packages?.length ?
                    this.props.packages.map((pkg, i) => (
                      <tr>
                        <td className='align-middle'>{i + 1}</td>
                        {/*<td className='align-middle'>{pkg.kgCode || ''}</td>*/}
                        {(this.state.currUser.role !== USER_ROLE.OPS && this.state.currUser.role !== USER_ROLE.SALER && this.state.currUser.role !== USER_ROLE.MANAGER) && (
                            <td className='align-middle'>{pkg.referenceCode || ''}</td>
                        )}
                        <td className='align-middle'>{pkg.kgCode || ''}</td>
                        <td className='align-middle'>
                          {' '}
                          {pkg.receiverCompanyName} <br />
                          {pkg.receiverName} <br />
                          {pkg.receiverPhone} <br /> {pkg.receiverEmail}
                        </td>
                        <td className='align-middle'>
                          {' '}
                          {pkg.receiverCity +
                          ' / ' +
                          pkg.receiverCountry +
                          ' / ' +
                          pkg.receiverPostalCode}{' '}
                          <br /> {pkg.receiverAddr1} <br /> {pkg.receiverAddr2}{' '}
                          <br /> {pkg.receiverAddr3}{' '}
                        </td>

                        <td className='align-middle'>
                          {new Date(pkg.createdAt).toLocaleDateString()}{' '}
                        </td>
                        <td className='align-middle'>
                          {pkg.logisticCode && (
                            <a href={`/tracking/?code=${pkg.kgCode}`} target='_blank' rel="noopener noreferrer">{pkg.logisticCode}</a>
                          )}
                        </td>
                        <td className='align-middle'>
                          {pkg.logisticService}
                        </td>
                        <td className='align-middle btn-wrap'>
                          {
                            (this.state.currUser.role !== USER_ROLE.SALER) && (
                              <div className='input-group'>

                            <button
                              type="button"
                              className="btn btn-sm btn-warning"
                              id={pkg.id}
                              value={pkg}
                              style={{marginBottom: '5px'}}
                              onClick={(e) => this.onClickPrint(e, pkg)}
                            >
                              Print Bill
                            </button>


                            <button
                              type="button"
                              className="btn btn-sm btn-warning"
                              id={pkg.id}
                              style={{marginBottom: '5px'}}
                              value={pkg}
                              onClick={(e) => this.onClickPrintInv(e, pkg)}
                            >
                              Print Invoice
                            </button>

                            <button
                              type="button"
                              className="btn btn-sm btn-warning"
                              id={pkg.id}
                              value={pkg}
                              style={{marginBottom: '5px'}}
                              onClick={(e) => this.onClickPrintPackages(e, pkg)}
                            >
                              Print Packages
                            </button>
                                {(this.state.currUser.role !== USER_ROLE.SALER && this.state.currUser.role !== USER_ROLE.OPS && this.state.currUser.role !== USER_ROLE.CUSTOMER) && (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-warning"
                                    style={{marginBottom: '5px'}}
                                    onClick={(e) => this.props.history.push(`/app/bill/${pkg.kgCode}`)}
                                  >
                                    Edit Package
                                  </button>
                                )}
                              </div>
                            )
                          }
                        </td>

                        {(this.state.currUser.role === USER_ROLE.ADMIN
                          || this.state.currUser.role === USER_ROLE.DOCUMENT || this.state.currUser.email == 'customerservicetinphat@gmail.com' ) && (
                          <td className="align-middle">
                            <div className="d-flex justify-content-center">
                              <Button
                                className="btn-add"
                                style={{width: '120px'}}
                                onClick={(e) => this.toggleUpdatePackage(e, pkg)}
                              >
                                Edit
                              </Button>
                            </div>
                          </td>
                        )}
                      </tr>
                    )):(
                      <tr>
                        <td colSpan='100' className='text-center'>Danh sách trống. Vui lòng kiểm tra lại bộ lọc.</td>
                      </tr>
                    )}

                    {this.props.isFetching && (
                      <tr>
                        <td colSpan='100'>Loading...</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                { totalPage > 2 && (
                  <Pagination aria-label="Page navigation example">
                    <PaginationItem>
                      <PaginationLink first href="#" onClick={(e) => this.onSearch(e, 1)}/>
                    </PaginationItem>
                    {[...Array(pageLimit)].map((_, i) => {
                      if (i >= start && i < end) {
                        return (
                          <PaginationItem active={i === currentPage - 1} key={i}>
                            {' '}
                            <PaginationLink
                              onClick={(e) => this.onSearch(e, i + 1)}
                            >
                              {' '}{i + 1}{' '}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      }
                    })}
                    <PaginationItem>
                      <PaginationLink last href="#" onClick={(e) => this.onSearch(e, totalPage)}/>
                    </PaginationItem>
                  </Pagination>
                )}
              </div>
            </div>
          ) : (
            <Button
              className="form-control"
              style={{ maxWidth: "100px", display: "block" }}
              onClick={this.toggleShowPickupModal}
            >
              Pickup
            </Button>
          )
        }

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.packages.isFetching,
    packages: state.packages.packages.rows,
    totalPackages: state.packages.packages.count,
    pickupList: state.pickup,
  };
}

export default connect(mapStateToProps)(Packet);
