import React from 'react'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { USER_ROLE, PACKAGE_ACTION, SHIPPING_SERVICES } from '../../constants'

import {
  Row,
  Col,
  Table,
  FormGroup,
  Label,
  Input,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import s from "./Barcode.module.scss";
import Rounding from '../../utils/rounding'
import { format } from 'date-fns'
import { vi } from 'date-fns/locale'
import { DateRangePickerCalendar, START_DATE } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import jwt from "jsonwebtoken";
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.css";

class Barcode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: PACKAGE_ACTION.IMPORT,
      logisticService: '',
      isShowCalendar: false,
      disableDownloadButton: false,
      startDate: new Date(),
      endDate: new Date(),
      focus: START_DATE,
      items: [],
      currUser: {}
    }

    this.setStartDate = this.setStartDate.bind(this)
    this.setEndDate = this.setEndDate.bind(this)
    this.handleFocusChange = this.handleFocusChange.bind(this)
    this.handleService = this.handleService.bind(this)
  }

  handleService = (e) => {
    const { value } = e.target
    this.setState({ logisticService: value })
  }

  onSelectChange = (value) => {
    this.setState({ type: value })
  }

  componentDidMount() {
    let token = localStorage.getItem('id_token')
    let currUser = jwt.decode(token)
    this.setState({ currUser })
    this.getItem();
  }

  async exportData() {
    await this.setState({ disableDownloadButton: true })
    const { startDate, endDate, type, logisticService } = this.state
    axios
      .get('/download', {
        params: { fromDate: startDate, toDate: endDate, type, logisticService },
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `DOWNLOAD_DATA_${new Date().toJSON().slice(0, 10)}.xlsx`,
        )
        this.setState({ disableDownloadButton: false })
      })
      .catch(() => {
        this.setState({ disableDownloadButton: false })
      })
    return
  }
  setStartDate(startDate) {
    this.setState({ startDate })
  }
  setEndDate(endDate) {
    this.setState({ endDate })
  }

  handleFocusChange(newFocus) {
    this.setStartDate(newFocus && newFocus[0])
    this.setEndDate(newFocus && newFocus[1])
    const focus = newFocus[0] ?? START_DATE
    this.setState({ focus })
  }

  async getItem() {
    try {
      const { startDate, endDate, type, logisticService } = this.state
      const { data } = await axios.get('/packages', {
        params: { startDate, endDate, type, logisticService },
      })
      this.setState({ items: data })
    } catch (error) {}
  }

  render() {
    const {
      items,
      startDate,
      endDate,
      logisticService,
      currUser
    } = this.state

    const options = [
      { value: PACKAGE_ACTION.EXPORT, label: 'Xuất hàng' },
      { value: PACKAGE_ACTION.IMPORT, label: 'Nhập hàng' },
    ]
    let totalPacks = 0
    items &&
      items.forEach((element) => {
        let packs = JSON.parse(element.packs)
        if (packs.length && packs[0].quantity !== 0) {
          totalPacks = totalPacks + JSON.parse(element.packs).length
        }
      })
    let totalWeight = parseFloat(0)
    items &&
      items.forEach((element) => {
        let packs = JSON.parse(element.packs)
        packs.forEach((packInfo) => {
          if (packInfo.weight) {
            totalWeight =
              totalWeight + Rounding(packInfo.weight * packInfo.quantity)
          }
        })
      })
    return (
      <div>
        <Breadcrumb>
          <BreadcrumbItem>TRANG</BreadcrumbItem>
          <BreadcrumbItem active>Barcode</BreadcrumbItem>
        </Breadcrumb>

        <Row>
          <Col sm={12} md={12} lg={7}>
            <div className={s.barcodeFilter}>
              <div className={s.barcodeFilterHeader}>
                <div className={s.barcodeFilterName}>
                  <i className="fa fa-filter" aria-hidden="true" />
                  <span> Filter</span>
                </div>
                <div className={s.wrapRangeDate}>
                  <label>Date range</label>
                  <DateRangePicker
                    className="range-pickup-date"
                    placeholder="Last month"
                    locale={vi}
                    format="dd/MM/yyyy"
                    onChange={this.handleFocusChange}
                  />
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  this.getItem()
                }}
              >
                <div className="d-flex justify-content-between">
                  <div>
                    {startDate && (
                      <div className='group-form'>
                        <label>
                          Ngày bắt đầu:{' '}{format(startDate, 'dd/MM/yyyy', { locale: vi })}
                        </label>
                      </div>
                    )}
                    {endDate && (
                      <div className='group-form'>
                        <label>
                          Ngày Kết thúc:{' '}{format(endDate, 'dd/MM/yyyy', { locale: vi })}
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <Row className="m-sm-0">
                    <Col sm={12} md={6} className="pd-sm-0 p-0">
                      <FormGroup>
                        <Label for="logisticService">
                          Dịch vụ vận chuyển (Services)
                        </Label>
                        <Input
                          placeholder="Chọn hoặc nhập"
                          name="logisticService"
                          type="text"
                          value={logisticService}
                          id="logisticService"
                          onChange={(e) => this.handleService(e)}
                          list="logisticServiceList"
                        />
                        <datalist id="logisticServiceList">
                          {SHIPPING_SERVICES.map((i, index) => (
                            <option value={i} key={index}> {i} </option>
                          ))}
                        </datalist>
                      </FormGroup>
                    </Col>
                    <Col sm={12} md={6} className="pd-sm-0 pr-0">
                      <FormGroup>
                        <Label for="logisticService">Hoạt động</Label>
                        <select
                          id="logisticService"
                          name="logisticService"
                          onChange={(e) => this.onSelectChange(e.target.value)}
                          className="custom-select"
                        >
                            {options?.map((item, index)=>(
                              <option value={item.value} key={index}>{item.label}</option>
                            ))}
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <span>
                        <strong>Total Pcs: {totalPacks}</strong>
                      </span>
                      <span className="mx-3">
                        <strong>Total Weight (kg): {totalWeight}</strong>
                      </span>
                      <span>
                        <strong>Total Result: {items.length}</strong>
                      </span>
                    </div>
                    <Button className="btn-add btn-download" type="submit">
                      Tìm kiếm
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Col>
          <Col sm={12} className="mt-3">
            {(items.length > 0 && currUser.role !== USER_ROLE.OPS) ? (
              <div className='text-right d-flex justify-content-end mb-3'>
                <Button
                  className="btn-download btn-add"
                  disabled={this.state.disableDownloadButton}
                  onClick={() => this.exportData()}
                >
                  <i class="mr-1 fa fa-download" aria-hidden="true"></i>
                  Tải về
                </Button>
              </div>
            ) : null}

              <Table responsive bordered hover size="md">
                <thead className="text-center">
                  <tr>
                    <th>NO.</th>
                    <th>RG Bill</th>
                    <th>RG Code</th>
                    <th>Contact</th>
                    <th>Address</th>
                    <th>Sent Date</th>
                    <th>Tracking</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {items.length?items.map((pkg, i) => (
                    <tr key={i}>
                      <td className="align-middle">{i + 1}</td>
                      <td className="align-middle">{pkg.kgCode || ''}</td>
                      <td className="align-middle">
                        {pkg.referenceCode || ''}
                      </td>
                      <td className="align-middle">
                        {' '}
                        {pkg.receiverCompanyName} <br />
                        {pkg.receiverName} <br />
                        {pkg.receiverEmail}
                      </td>
                      <td className="align-middle">
                        {' '}
                        {pkg.receiverCity +
                          ' / ' +
                          pkg.receiverCountry +
                          ' / ' +
                          pkg.receiverPostalCode}{' '}
                        <br /> {pkg.receiverAddr1} <br />{' '}
                        {pkg.receiverAddr2} <br /> {pkg.receiverAddr3}{' '}
                      </td>

                      <td className="align-middle">
                        {new Date(pkg.createdAt).toLocaleDateString()}{' '}
                      </td>

                      <td className="align-middle">
                        {pkg.logisticCode && (
                          <a
                            href={`/tracking/?code=${pkg.kgCode}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {pkg.logisticCode}
                          </a>
                        )}
                      </td>
                    </tr>
                  )): (
                    <tr>
                      <td colSpan='100' className='text-center'>Danh sách trống. Vui lòng kiểm tra lại bộ lọc.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Barcode
