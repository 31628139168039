import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import { Redirect } from 'react-router';
import jwt from 'jsonwebtoken';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  deleteDashboard,
  fetchDashboardById,
  updateDashboard,
} from '../../actions/dashboard';
import { USER_ROLE } from '../../constants';
import s from './Dashboard.module.scss';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import EditorArea from './EditorArea';

class DashboardDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currUser: {},
      redirect: false,
      modalToggleUpdateDashboard: false,
      dashboardContent: { title: '', body: '', highlight: false },
      isOpenToast: false
    };
    this.onUpdateDashboard = this.onUpdateDashboard.bind(this);
    this.onChangeUpdateDashboard = this.onChangeUpdateDashboard.bind(this);
    this.toggleUpdateDashboard = this.toggleUpdateDashboard.bind(this);
  }

  /* eslint-disable */
  static propTypes = {
    dashboardDetail: PropTypes.any,
    isFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string
  };
  /* eslint-enable */

  static defaultProps = {
    isFetching: false,
    dashboardDetail: null,
    message: null
  };

  toggleUpdateDashboard() {
    this.setState({
      modalToggleUpdateDashboard: !this.state.modalToggleUpdateDashboard,
    });
  }

  onUpdateDashboard(e) {
    e.preventDefault();
    this.props.dispatch(updateDashboard(this.state.dashboardContent));
    this.toggleUpdateDashboard();
    this.setState({isOpenToast: true})
  }

  onChangeUpdateDashboard(e, name) {
    if (name) {
      this.setState({
        dashboardContent: {
          ...this.state.dashboardContent,
          [name]: e,
        },
      });
      return;
    }
    if (e.target.name === 'highlight') {
      this.setState({
        dashboardContent: {
          ...this.state.dashboardContent,
          [e.target.name]: e.target.checked,
        },
      });
      return;
    }
    this.setState({
      dashboardContent: {
        ...this.state.dashboardContent,
        [e.target.name]: e.target.value,
      },
    });
  }

  updateDashboard(item) {
    this.setState({
      dashboardContent: item,
      modalToggleUpdateDashboard: !this.state.modalToggleUpdateDashboard,
    });
  }

  deleteNotification(id) {
    confirmAlert({
      title: 'Xác nhận Xoá',
      message: 'Bạn có chắc muốn xoá thông báo này?',
      buttons: [
        {
          label: 'Có',
          onClick: () => {
            this.props.dispatch(deleteDashboard(id));
            this.setState({ redirect: true });
          },
        },
        { label: 'Không', onClick: () => {} },
      ],
    });
  }

  componentDidMount() {
    let { dashboardId } = this.props.match.params;

    let token = localStorage.getItem('id_token');
    let currUser = jwt.decode(token);
    this.setState({ currUser: currUser });

    this.props.dispatch(fetchDashboardById(dashboardId));
  }

  render() {
    const { dashboardDetail } = this.props;
    const { redirect } = this.state;

    if (this.props.message && this.state.isOpenToast) {
      toast.error(this.props.message);
      this.setState({isOpenToast: false})
    }

    if (redirect) {
      return <Redirect to='/app/main' />;
    }
    return (
      <div className={s.root}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG</BreadcrumbItem>
          <BreadcrumbItem active>Thông báo chi tiết</BreadcrumbItem>
        </Breadcrumb>

        {/* Update content of dashboard */}
        <Modal
          isOpen={this.state.modalToggleUpdateDashboard}
          toggle={this.toggleUpdateDashboard}
        >
          <ModalHeader>Chỉnh sửa thông báo</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for='title'>Tiêu đề</Label>
              <Input
                value={this.state.dashboardContent.title}
                name='title'
                id='title'
                placeholder='title'
                onChange={(e) => this.onChangeUpdateDashboard(e)}
              />
            </FormGroup>
            <FormGroup style={{ minHeight: '400px' }}>
              <Label for='content'>Nội dung</Label>
              <EditorArea
                value={this.state.dashboardContent.body}
                onChange={(e) => this.onChangeUpdateDashboard(e, 'body')}
              />
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type='checkbox'
                  name='highlight'
                  value={true}
                  checked={this.state.dashboardContent.highlight}
                  onChange={(e) => this.onChangeUpdateDashboard(e)}
                />
                Hiển thị thông báo nỗi bật
                {this.state.dashboardContent.highlight}
              </Label>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type='submit'
              color='success'
              onClick={(e) => this.onUpdateDashboard(e)}
            >
              Update
            </Button>
            <Button color='secondary' onClick={this.toggleUpdateDashboard}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Row>
          <Col sm={12} className='mb-2'>
            <Link to='/app/main/'>
              <Button>
                <i className='fa fa-chevron-left' /> Quay lại trang danh sách thông báo
              </Button>
            </Link>
          </Col>
          <Col>
            {dashboardDetail && (
              <Card
                className={
                  dashboardDetail?.highlight ? 'border border-danger' : ''
                }
              >
                <CardHeader tag='h5' className='text-danger'>
                  {dashboardDetail?.title || ''}
                </CardHeader>
                <CardBody>
                  {/* <EditorArea value={dashboardDetail?.body} /> */}
                  {/* <TextWithNewLine text={dashboardDetail?.body || ""} /> */}
                  <div> { ReactHtmlParser (dashboardDetail?.body) } </div>
                </CardBody>
                {(this.state.currUser.role === USER_ROLE.ADMIN || this.state.currUser.role === USER_ROLE.DOCUMENT) ? (
                  <CardFooter className='justify-content-end d-flex cardbody'>
                    <Button
                      onClick={() => this.updateDashboard(dashboardDetail)}
                    >
                      <i className='fa fa-pencil' /> Chỉnh sửa
                    </Button>
                    <Button
                      color='danger'
                      className='ml-2'
                      onClick={() =>
                        this.deleteNotification(dashboardDetail?.id)
                      }
                    >
                      <i className='fa fa-recycle' /> Xoá
                    </Button>
                  </CardFooter>
                ) : null }
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

// function TextWithNewLine(props) {
//   const text = props.text;
//   const lines = text.split('\n').map(str => <p>{str || <br />}</p>);
//   return lines;
// }

function mapStateToProps(state) {
  return {
    isFetching: state.dashboard.isFetching,
    dashboardDetail: state.dashboard.dashboardById,
    message: state.dashboard.message
  };
}

export default connect(mapStateToProps)(DashboardDetail);
