import {    
  FETCH_PACKAGE_REQUEST,
  FETCH_PACKAGE_SUCCESS,
  FETCH_PACKAGE_FAILURE,
  DELETE_PACKAGE,
  RESET_PACKAGE,
} from '../actions/barcode';
  
  export default function barcode(
    state = {
      isFetching: false,
      items: []
    },
    action,
  ) {
    switch (action.type) {
      case FETCH_PACKAGE_REQUEST:
        return Object.assign({}, state, {
          isFetching: action.isFetching,
          message: null,
        });
      case FETCH_PACKAGE_SUCCESS:
        
        const newItem = state.items.some(element => (element.id === action.item.id));
        const items = newItem ? state.items : [...state.items, action.item]

        return Object.assign({}, state, {
          isFetching: action.isFetching,
          items,
        });
      case FETCH_PACKAGE_FAILURE:
        return Object.assign({}, state, {
          isFetching: action.isFetching,
          message: action.message || 'get packages failed',
        });
      case DELETE_PACKAGE:
      {
        const items = state.items.filter(i => i.kgCode !== action.code)
        return Object.assign({}, state, {
          isFetching: action.isFetching,
          items,
        });
      };
      case RESET_PACKAGE:
      {
        return Object.assign({}, state, {
          items: [],
        });
      }
      default:
        return state;
  }
}
  