import React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Alert, Button, FormGroup, Input, Col, Row, Form } from "reactstrap";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import s from "./reset-password.module.scss";
import Widget from "../../components/Widget";
import Footer from "../../components/Footer";
import jwt from "jsonwebtoken";
import config from "../../config";
import logo from "../../images/ringo/logo.png";
import bgLogin from "../../images/ringo/bg.jpg";

class ResetPassword extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    isFetching: PropTypes.bool,
    location: PropTypes.any, // eslint-disable-line
    errorMessage: PropTypes.string,
  };

  static defaultProps = {
    isAuthenticated: false,
    isFetching: false,
    location: {},
    errorMessage: null,
  };

  static isAuthenticated(token) {
    // We check if app runs with backend mode
    if (!config.isBackend && token) return true;
    if (!token) return;
    const date = new Date().getTime() / 1000;
    const data = jwt.decode(token);
    return date < data.exp;
  }

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isFetching: false,
      errorMsg: "",
    };
  }

  changeEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  doGetResetEmail = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    this.setState({ isFetching: true });
    try {
      const res = await axios.post("/reset-password", { email });
      confirmAlert({
        title: "Thành công",
        message: res.data.message,
        buttons: [{ label: "Đóng", onClick: () => {} }],
      });
    } catch (error) {
      console.log(error.response.data.message);

      this.setState({ errorMsg: error.response.data.message });
      // confirmAlert({
      //   title: 'Thất bại',
      //   message: error.response.data.message,
      //   buttons: [{label: 'Đóng',onClick: () => {}}]});
    } finally {
      this.setState({ isFetching: false, email: "" });
    }
  };

  render() {
    const { errorMsg } = this.state;
    const { from } = this.props.location.state || {
      from: { pathname: "/app" },
    };

    if (this.props.isAuthenticated) {
      // cant access login page while logged in
      return <Redirect to={from} />;
    }

    return (
      <div className={s.root}>
        <div className={s.wrap + " px-0 overflow-auto"}>
            <Row className="m-0">
              <Col xs={12} sm={6} className={s.xx}>
                <div className={s.textWrap}>
                  <img src={bgLogin} alt="bg-login" className={s.bgLogin} />
                </div>
              </Col>
              <Col
                xs={12}
                sm={6}
                className={s.loginForm + " ml-auto login-form"}
              >
                <p className="text-center">
                  <img src={logo} alt="LOGO" className={s.loginLogo} />
                </p>
                <Widget className={s.widget}>
                  <h4 className={s.title + " mt-0"}>Quên mật khẩu</h4>
                  {/*<p className={s.subTitle}>*/}
                  {/*  Nhập địa chỉ email để nhận thông tin <br />*/}
                  {/*  lấy lại mật khẩu*/}
                  {/*</p>*/}
                  <Form className="mt" onSubmit={this.doGetResetEmail}>
                    {errorMsg && (
                      <Alert size="sm" color="danger">
                        {errorMsg}
                      </Alert>
                    )}
                    <FormGroup className="form-group w-">
                      <Input
                        className="no-border"
                        value={this.state.email}
                        onChange={this.changeEmail}
                        type="text"
                        required
                        name="email"
                        placeholder="Email"
                      />
                    </FormGroup>
                    <div>
                      <Link className={s.login} to="/login">
                        Đăng Nhập
                      </Link>
                      <div>
                        <Button
                          className={s.btnSubmit}
                          color="success"
                          size="sm"
                          type="submit"
                          disabled={this.state.isFetching}
                        >
                          {this.state.isFetching ? "Loading..." : "GỬI EMAIL"}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Widget>
              </Col>
            </Row>
        </div>
        <Footer className="text-center" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(ResetPassword));
