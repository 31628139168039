import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import logo from "../../images/logo.png";
import EditPacketTitle from '../../images/bill-ringo.jpg'
import "./pickup.scss";
class PrintPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      billCreator: {}
    }
  }
  render() {
    const {billData = {}} = this.props;
    return (
      <div className="modal-pickup pickup">
        <div style={{pageBreakAfter: 'always'}}>
          <div>
            <div className="modal-pickup__logo">
              <div className="logo">
                <img src={logo} alt="Logo"/>
              </div>
            </div>
            <div className="modal-pickup__header" style={{marginBottom: '20px'}}>
              <div className="title-bill">
                <h2>Đơn lấy hàng: PK{billData.id}</h2>
                <p>
                  <strong>Track & Trace: </strong>tracking.gopost.vn
                </p>
              </div>
              <div className="tracking">
                <FormGroup>
                  <Label>Tracking:</Label>
                  <Input
                    type="text"
                    name=""
                    placeholder="Tracking"
                    readOnly
                    onChange={this.onCreatePickupChange}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="wrap-table-content">
              <div className="left">
                <div className="table table-1">
                  <div className="table__header">
                    <span className="box">1</span>
                    <h2>THÔNG TIN NGƯỜI GỬI: KH{billData.id}</h2>
                  </div>
                  <div className="table__content">
                    <div className="item d-flex">
                      <label htmlFor="">Người gửi:</label>
                      <span className="text-nowrap">
                    {billData.customerName}
                  </span>
                    </div>
                    <div className="item">
                      <div className="item-3line">
                        <label htmlFor="">Địa chỉ:</label>{billData.customerAddress}
                        .........................................................................................................
                      </div>
                    </div>
                    <div className="item d-flex">
                      <label htmlFor="">Tel:</label>
                      <span className="text-nowrap">
                    {billData.customerPhone}...................................................................................
                  </span>
                    </div>
                  </div>
                </div>
                <div className="table table-1">
                  <div className="table__header">
                    <span className="box">2</span>
                    <h2>THÔNG TIN NGƯỜI NHẬN:</h2>
                  </div>
                  <div className="table__content">
                    <div className="item d-flex">
                      <label htmlFor="">Người nhận:</label>
                      <span className="text-nowrap">
                   .............................................................................................................................
                  </span>
                    </div>
                    <div className="item">
                      <div className="text-nowrap">
                        <label htmlFor="">Địa chỉ:</label>................................................................................................................
                      </div>
                    </div>
                    <div className="item d-flex">
                      <label htmlFor="">Tel:</label>
                      <span className="text-nowrap">.....................................................................................................
                  </span>
                    </div>
                  </div>
                </div>
                <div className="table-2">
                  <div className="item">
                    <p>Ngày giờ lấy hàng:</p>
                    <p>
                      ....................h...........................;................./.................../....................
                    </p>
                  </div>
                </div>
                <div className="group-table">
                  <div className="table-3 w-45 d-flex">
                    <span className="box">3</span>
                    <div className="table-3__left w-50">
                      <h2 className="text-left ml-0">QUỐC TẾ</h2>
                      <div className="list-check-box">
                        <div className="item-check">
                          <label>DHL VN</label>
                        </div>
                        <div className="item-check">
                          <label>UPSVN</label>
                        </div>
                        <div className="item-check">
                          <label>TNT VN</label>
                        </div>
                        <div className="item-check">
                          <label>FEDEX VN</label>
                        </div>
                        <div className="item-check">
                          <label>DHL SIN</label>
                        </div>
                        <div className="item-check">
                          <label>......................</label>
                        </div>
                      </div>
                    </div>
                    <div className="table-3__right w-50">
                      <h2 className="text-left ml-0">NỘI ĐỊA</h2>
                      <div className="list-check-box">
                        <div className="item-check">
                          <label>CPNEXPRESS</label>
                        </div>
                        <div className="item-check">
                          <label>48H/TIẾT KIỆM</label>
                        </div>
                        <div className="item-check">
                          <label>ĐƯỜNG BỘ</label>
                        </div>
                        <div className="item-check">
                          <label>HOẢ TỐC</label>
                        </div>
                        <div className="item-check">
                          <label>NỘI THÀNH</label>
                        </div>
                        <div className="item-check">
                          <label>......................</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-55 ml-1">
                    <div className="table-3 w-100">
                      <span className="box">4</span>
                      <div className="list-check-box d-flex">
                        <div className="item-check mb-0">
                          <label>HÀNG THƯỜNG</label>
                        </div>
                        <div className="item-check mb-0 ml-2">
                          <label>HÀNG KHÓ</label>
                        </div>
                      </div>
                    </div>
                    <div className="table-3 w-100 mt-2">
                      <span className="box position-left">5</span>
                      <h2 className="mt-4 ml-0">Nội dung hàng hoá</h2>
                      <p className="item-3line">{billData.info || ''}
                        <br/> ..............................................................................................................................................................................................................
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="ml-2">
                  <div className="group-table mt-0">
                    <div className="w-100">
                      <div className="table table-1">
                        <div className="table__header border-0">
                          <span className="box">6</span>
                          <h2 className="ml-0 text-center w-100">CƯỚC PHÍ</h2>
                        </div>
                        <div className="table__content">
                          <div className="item d-flex">
                            <label htmlFor="">Cước chính:</label>
                            <span className="text-nowrap">....................................................................
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Ngoại vùng xa:</label>
                            <span className="text-nowrap">
                          ..............................................................................................
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Bảo hiểm:</label>
                            <span className="text-nowrap">
                          ..............................................................................................
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Thu hồi:</label>
                            <span className="text-nowrap">
                          ..............................................................................................
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Đóng góp:</label>
                            <span className="text-nowrap">
                          ..............................................................................................
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Cước USD:</label>
                            <span className="text-nowrap">
                          ..............................................................................................
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Phí kiểm đếm:</label>
                            <span className="text-nowrap">
                          ..............................................................................................
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Phí khác:</label>
                            <span className="text-nowrap">
                          ..............................................................................................
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="" className="text-bold">TỔNG:</label>
                            <span className="text-nowrap">
                          ..............................................................................................
                        </span>
                          </div>
                          <div className="item item-bold">
                            <h2 className="mt-2 ml-0 mb-2 text-center">HÌNH THỨC THANH TOÁN</h2>
                            <div className="list-check-box d-flex">
                              <div className={`item-check mb-0 ${billData.paymentType === 'Tiền mặt' ? "active" : ""}`}>
                                <label>TIỀN MẶT</label>
                              </div>
                              <div
                                className={`item-check mb-0 ml-2 ${billData.paymentType === 'Chuyển khoản' ? "active" : ""}`}>
                                <label>CHUYỂN KHOẢN</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table table-1 mb-0">
                        <div className="table__header border-0">
                          <span className="box position-right">7</span>
                        </div>
                        <div className="table__content">
                          <div className="item d-flex">
                            <label htmlFor="">Số kiện:</label>
                            <span className="text-nowrap max-w-100">............{billData.packs || ''}...........................
                        </span>
                            <label htmlFor="">Kiện</label>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Trọng lượng thực:</label>
                            <span className="text-nowrap max-w-80">
                            ......{billData.weight || ''}
                        </span>
                            <label htmlFor="">Kg</label>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Trọng lượng quy đổi:</label>
                            <span className="text-nowrap max-w-80">
                          ..................................................................................
                        </span>
                            <label htmlFor="">Kg</label>
                          </div>
                          <div className="item item-bold">
                            <div className="list-check-box d-flex justify-content-between">
                              <div className="item-2">
                                <h2 className="mt-2 ml-0 text-center">Dài</h2>
                                <p
                                  className="item-3line">...................................................................................................................................................</p>
                              </div>
                              <div className="item-2">
                                <h2 className="mt-2 ml-0 text-center">Rộng</h2>
                                <p
                                  className="item-3line">...................................................................................................................................................</p>
                              </div>
                              <div className="item-2">
                                <h2 className="mt-2 ml-0 text-center">Cao</h2>
                                <p
                                  className="item-3line">...................................................................................................................................................</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table-3 w-100 mt-2">
                        <span className="box position-left">10</span>
                        <h2 className="ml-0 text-center w-100 mt-3">GHI CHÚ:</h2>
                        <p
                            className="item-4line">.........................................................................................................................................................................</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="wrap-table-content w-100 w-100-custom">
                <div className="table table-1 h-140 w-40">
                  <div className="table__header border-0">
                    <span className="box position-right">8</span>
                    <h2 className="ml-0 text-center w-100">NGƯỜI GỬI</h2>
                  </div>
                  <div className="table__content mt-3 w-40">
                    <p
                        className="text-word">...........................................................................................</p>
                    <p className="text-center mt-2 font-8"><i>Họ tên và chữ ký người gửi hàng<br/> (Đồng ý với
                      điều khoản sử dụng dịch vụ)</i></p>
                  </div>
                </div>
                <div className="table table-1 h-140 w-40">
                  <div className="table__header border-0">
                    <span className="box position-right">9</span>
                    <h2 className="ml-0 text-center w-100">NHÂN VIÊN</h2>
                  </div>
                  <div className="table__content mt-3">
                    <p
                        className="text-word text-center">{billData.status || '.................'}</p>
                    <p className="text-center mt-2 font-8"><i>Họ tên và chữ ký người nhân viên thực hiện</i></p>
                  </div>
                </div>

              </div>
            </div>
            <div className="box-footer">
              Hotline: 1900 4797 - Website: ringologistics.com - 21/7 Trường Sơn, Phường 4, Quận Tân Bình, TP Hồ Chí Minh
            </div>
          </div>
        </div>
        <div>
          <div className="page-laster text-center">
            <img src={EditPacketTitle} alt="" />
          </div>
          <div className="box-footer">
            Hotline: 1900 4797 - Website: ringologistics.com - 21/7 Trường Sơn, Phường 4, Quận Tân Bình, TP Hồ Chí Minh
          </div>
        </div>
      </div>
    );
  }
}

export default PrintPackages;
