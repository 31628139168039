import React from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  Input,
  Button,
  Table,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import fileDownload from 'js-file-download'
import Select from 'react-select'
import { format } from 'date-fns'
import { vi } from 'date-fns/locale'
import 'react-nice-dates/build/style.css'
import { DateRangePickerCalendar, START_DATE } from 'react-nice-dates'
import { DOC_TYPE } from '../../constants'
import CurrencyInput from 'react-currency-input-field'
import Rounding from '../../utils/rounding'

class Report extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      kgCode: '',
      date: '',
      filterDateType: '',
      filterOptionsType: '',
      searchValue: '',
      filterDateTypeValidation: false,
      filterOptionsTypeValidation: false,
      items: [],
      disableDownloadButton: false,
      isShowCalendar: false,
      startDate: new Date(),
      endDate: new Date(),
      focus: START_DATE,
      modalToggleUpdate: false,
      isShowStatusNone: true,
      pkg: {},
      index: null,
    }
    this.handleInput = this.handleInput.bind(this)
    this.handleDatetoSelect = this.handleDatetoSelect.bind(this)
    this.toggleUpdateItem = this.toggleUpdateItem.bind(this)
    this.onUpdateDashboard = this.onUpdateDashboard.bind(this)
    this.onRemovePackageDetails = this.onRemovePackageDetails.bind(this)
    this.onUpdateSearchValue = this.onUpdateSearchValue.bind(this)
  }

  toggleUpdateItem() {
    this.setState({
      modalToggleUpdate: !this.state.modalToggleUpdate,
    })
  }

  onUpdateDashboard(item, i) {
    this.setState({
      pkg: item,
      index: i,
      modalToggleUpdate: !this.state.modalToggleUpdate,
    })
  }

  handleDatetoSelect(date) {
    this.setState({ date })
  }

  handleInput(e) {
    this.setState({ kgCode: e.target.value })
  }

  onUpdateSearchValue (e) {
    this.setState({ searchValue: e.target.value })
  }

  async componentDidMount() {
    let { name } = this.props.match.params
    const { data } = await axios.get(`/reports/${name}?isShowStatusNone=true`)
    // ?datetype=createdAt&value=2022-02-28T01:59:01.000Z
    const dateArray = name.split('-')
    const lastDayOfMonth = new Date(dateArray[1], Number(dateArray[0]), 0)
    const firstDateOfMonth = new Date(dateArray[1], Number(dateArray[0]) - 1, 1)
    this.setState({
      items: data.packages,
      startDate: firstDateOfMonth,
      endDate: lastDayOfMonth,
    })
  }

  async onSubmit() {
    const { items } = this.state
    let { name } = this.props.match.params
    const { data } = await axios.post(`/reports/${name}/managed-package`, {
      kgCode: this.state.kgCode,
      reportAt: new Date(),
    })
    this.setState({ items: [...items, data] })
  }

  async exportData() {
    await this.setState({ disableDownloadButton: true })
    let { name } = this.props.match.params
    const { filterDateType, startDate, endDate, searchValue, filterOptionsType, isShowStatusNone} = this.state
    let qs = `name=${name}`
    if (filterDateType) {
      qs += `&datetype=${filterDateType}&startDate=${startDate}&endDate=${endDate}`
    }
    if (searchValue && filterOptionsType) {
      qs += `&${filterOptionsType}=${searchValue}`
    }
    if (isShowStatusNone) {
      qs += `&isShowStatusNone=${isShowStatusNone}`
    }

    axios
      .get(`/download-report?${qs}`, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `DOWNLOAD_DATA_${new Date().toJSON().slice(0, 10)}.xlsx`,
        )
        this.setState({ disableDownloadButton: false })
      })
      .catch(() => {
        this.setState({ disableDownloadButton: false })
      })
    return
  }
  async onChangeInput(e) {
    const { name, value } = e.target
    const item = { ...this.state.pkg }
    item[name] = value
    await this.setState({ pkg: item })
  }

  async onValueChange(value, name) {
    const item = { ...this.state.pkg }
    item[name] = value
    await this.setState({ pkg: item })
  }
  async onRemovePackageDetails(i) {
    const { id } = this.state.items[i]
    axios
      .put(`/packages/${id}/managed-report`, {})
      .then(async (res) => {
        toast.success('Đã xoá ra khỏi báo cáo')
        const { items } = this.state
        const list = [...items]
        list.splice(i, 1)
        await this.setState({ items: list })
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Đã xảy ra lỗi vui lòng thử lại sau.')
        }
      })
    return
  }

  async onUpdatePackageDetails() {
    const {
      id,
      note,
      paymentCash,
      paymentBanking,
      paymentCod,
      logisticService,
      receiverCountry,
      senderCompanyName,
      logisticCode,
      receiverName,
      paymentNum,
      chargeableWeight,
      reportName,
      reportStatus,
    } = this.state.pkg
    axios
      .put('/packages/' + id, {
        note,
        paymentCash,
        paymentBanking,
        paymentCod,
        logisticService,
        receiverCountry,
        senderCompanyName,
        logisticCode,
        receiverName,
        paymentNum,
        chargeableWeight,
        reportName,
        reportStatus,
      })
      .then(async (res) => {
        toast.success('Cập nhật đơn hàng thành công')
        const { pkg, items, index } = this.state
        const list = [...items]
        list[index] = pkg
        await this.setState({ items: list })
        this.toggleUpdateItem()
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Đã xảy ra lỗi vui lòng thử lại sau.')
        }
      })
    return
  }

  onSelectChange = (e) => {
    const { value } = e
    this.setState({ filterDateType: value })
  }

  onFilterChange = (e) => {
    const { value } = e
    this.setState({ filterOptionsType: value })
  }

  setDate = (e) => {
    const { value } = e
    this.setState({ filterDateType: value })
  }

  handleFocusChange(newFocus) {
    const focus = newFocus ?? START_DATE
    this.setState({ focus })
  }
  setStartDate(startDate) {
    this.setState({ startDate })
  }
  setEndDate(endDate) {
    this.setState({ endDate })
  }

  async handleShowResult(e) {
    const { checked } = e.target
    await this.setState({ isShowStatusNone: checked })
    this.filterData()
  }

  async filterData() {
    const { filterDateType, startDate, endDate, searchValue, filterOptionsType, isShowStatusNone } = this.state
    let params = {}
    if (filterDateType) {
      params.datetype = filterDateType
      params.startDate = startDate
      params.endDate = endDate
    }
    if (isShowStatusNone) {
      params.isShowStatusNone = isShowStatusNone
    }
    let { name } = this.props.match.params
    if (searchValue && filterOptionsType) {
      params[filterOptionsType] = searchValue
    }
    const { data } = await axios.get(`/reports/${name}`, {params})
    this.setState({
      items: data.packages,
    })
  }
  totalNum(packages) {
    const packs = JSON.parse(packages.packs || '{}')
    if (Object.keys(packs).length > 0) {
      return (
        packs.reduce(function (a, b) {
          return a + parseFloat(b['quantity'])
        }, 0) || 0
      )
    }
    return 0
  }
  totalWeight(packages) {
    if (packages.chargeableWeight) return packages.chargeableWeight
    const packs = JSON.parse(packages.packs || '{}')
    if (Object.keys(packs).length > 0) {
      return (
        Rounding(
          packs.reduce(function (a, b) {
            return a + parseFloat(b['chargedWeight'])
          }, 0),
        ) || ''
      )
      /*return (
        Math.round(
          packs.reduce(function (a, b) {
            return a + parseFloat(b['chargedWeight'])
          }, 0),
        ) || 0
      )*/
    }
    return 0
  }

  isExpired (item) {
    const updatedDate = new Date(item.createdAt)
    updatedDate.setDate(updatedDate.getDate() + 1);
    const now = new Date()
    return (updatedDate.getTime() <= now.getTime()) && !item.paymentBanking && !item.paymentCash
  }

  render() {
    const {
      kgCode,
      items,
      filterDateType,
      startDate,
      endDate,
      focus,
      isShowCalendar,
      pkg,
      searchValue,
      filterOptionsType,
      isShowStatusNone
    } = this.state
    let paymentCodTotal = 0,
      paymentCashTotal = 0,
      paymentBankingTotal = 0
    if (items.length > 0) {
      paymentCodTotal = items.reduce(function (a, b) {
        return a + parseFloat(b.paymentCod || 0)
      }, 0)
      paymentCashTotal = items.reduce(function (a, b) {
        return a + parseFloat(b.paymentCash || 0)
      }, 0)
      paymentBankingTotal = items.reduce(function (a, b) {
        return a + parseFloat(b.paymentBanking || 0)
      }, 0)
    }
    const options = [
      { value: 'createdAt', label: 'Ngày Tạo' },
      { value: 'reportAt', label: 'Ngày Tạo Báo Cáo' },
      { value: 'importAt', label: 'Ngày Nhập Hàng' },
      { value: 'exportAt', label: 'Ngày Xuất Hàng' },
    ]
    const filterOptions = [
      { value: 'code', label: 'RG Bill' },
      { value: 'senderCompanyName', label: 'Người gửi' },
      { value: 'receiverCompanyName', label: 'Người nhận' },
    ]

    return (
      <>
        <Modal
          isOpen={this.state.modalToggleUpdate}
          toggle={this.toggleUpdateItem}
          backdrop="static"
          size="xl"
        >
          <ModalHeader>Chỉnh sửa Thông tin</ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="title">SHIPPER ACCT:</Label>
                  <Input
                    type="text"
                    value={pkg.senderCompanyName}
                    onChange={(e) => this.onChangeInput(e)}
                    name="senderCompanyName"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="title">RECEIVER ( CONTACT NAME ):</Label>
                  <Input
                    type="text"
                    value={pkg.receiverName}
                    onChange={(e) => this.onChangeInput(e)}
                    name="receiverName"
                  />
                </FormGroup>
                {pkg.logisticService && (
                  <>
                    <FormGroup>
                      <Label for="title">SERVICE:</Label>
                      <Input
                        type="text"
                        value={pkg.logisticService}
                        onChange={(e) => this.onChangeInput(e)}
                        name="logisticService"
                      />
                    </FormGroup>
                  </>
                )}
                {pkg.logisticCode && (
                  <>
                    <FormGroup>
                      <Label for="title">TRACKING:</Label>
                      <Input
                        type="text"
                        value={pkg.senderCompanyName}
                        onChange={(e) => this.onChangeInput(e)}
                        name="senderCompanyName"
                      />
                    </FormGroup>
                  </>
                )}
                <FormGroup>
                  <Label for="title">ARRIVAL COUNTRY:</Label>
                  <Input
                    type="text"
                    value={pkg.receiverCountry}
                    onChange={(e) => this.onChangeInput(e)}
                    name="receiverCountry"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="title">Chargeable Weight:</Label>
                  <Input
                    type="text"
                    value={this.totalWeight(pkg)}
                    onChange={(e) => this.onChangeInput(e)}
                    name="chargeableWeight"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="status">Trạng thái:</Label>
                  <Input
                    id="status"
                    name="reportStatus"
                    type="select"
                    value={pkg.reportStatus}
                    onChange={(e) => this.onChangeInput(e)}
                  >
                    <option>-------------------</option>
                    <option value="Duyệt công nợ tháng">
                      Duyệt công nợ tháng
                    </option>
                    <option value="Duyệt công nợ tuần">
                      Duyệt công nợ tuần
                    </option>
                    <option value="Duyệt công nợ 15 ngày">
                      Duyệt công nợ 15 ngày
                    </option>
                    <option value="Duyệt theo yêu cầu">
                      Duyệt theo yêu cầu
                    </option>
                  </Input>
                </FormGroup>
              </Col>

              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="title">Thu Hộ:</Label>
                  <CurrencyInput
                    className="form-control"
                    thousandSeparator={true}
                    type="text"
                    value={pkg.paymentCod}
                    onValueChange={(value, name) =>
                      this.onValueChange(value, name)
                    }
                    name="paymentCod"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="title">Tiền Mặt:</Label>
                  <CurrencyInput
                    className="form-control"
                    thousandSeparator={true}
                    type="text"
                    value={pkg.paymentCash}
                    onValueChange={(value, name) =>
                      this.onValueChange(value, name)
                    }
                    name="paymentCash"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="title">Chuyển khoản:</Label>
                  <CurrencyInput
                    className="form-control"
                    thousandSeparator={true}
                    type="text"
                    value={pkg.paymentBanking}
                    onValueChange={(value, name) =>
                      this.onValueChange(value, name)
                    }
                    name="paymentBanking"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="title">Chủ TK Nhận:</Label>
                  <Input
                    type="text"
                    value={pkg.paymentNum}
                    onChange={(e) => this.onChangeInput(e)}
                    name="paymentNum"
                  />
                </FormGroup>{' '}
                <FormGroup>
                  <Label for="title">Kế Toán:</Label>
                  <Input
                    type="text"
                    value={pkg.reportName}
                    onChange={(e) => this.onChangeInput(e)}
                    name="reportName"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="title">Ghi chú:</Label>
                  <textarea
                    className="form-control"
                    rows="4"
                    value={pkg.note}
                    onChange={(e) => this.onChangeInput(e)}
                    name="note"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              color="success"
              onClick={(e) => this.onUpdatePackageDetails()}
            >
              Cập nhật
            </Button>
            <Button color="secondary" onClick={this.toggleUpdateItem}>
              Huỷ
            </Button>
          </ModalFooter>
        </Modal>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Row>
          <Col sm={12} md={6}>
            <Card>
              <CardHeader tag="h5">Nhập mã kiện hàng</CardHeader>
              <CardBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.onSubmit()
                  }}
                >
                  <InputGroup>
                    <Input
                      autoFocus
                      name="code"
                      value={kgCode}
                      onChange={this.handleInput}
                    />
                    <Button type="submit" className="btn-search ml-2">Enter</Button>
                  </InputGroup>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card>
              <CardHeader tag="h5">Lọc</CardHeader>
              <CardBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    this.filter()
                  }}
                >
                  <Row>
                    <Col className="mt-2" sm={12} md={6}>
                      <Select
                        options={options}
                        onChange={(value) => this.onSelectChange(value)}
                        id="logisticService"
                        name="logisticService"
                        value={options.find((o) => o.value === filterDateType)}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            borderColor: this.state.filterDateTypeValidation
                              ? 'red'
                              : styles.borderColor,
                            height: 'calc(1.5em + 0.75rem + 2px)',
                            borderRadius: 0,
                          }),
                        }}
                      />
                      <span className="text-danger">
                        {this.state.filterDateTypeValidation && 'Vui lòng chọn'}
                      </span>

                      <Select
                        options={filterOptions}
                        onChange={(value) => this.onFilterChange(value)}
                        value={filterOptions.find((o) => o.value === filterOptionsType)}
                        className="mt-3"
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            borderColor: styles.borderColor,
                            height: 'calc(1.5em + 0.75rem + 2px)',
                            borderRadius: 0,
                          }),
                        }}
                      />

                      <Input
                       type="text"
                       value={searchValue}
                       onChange={(e) => this.onUpdateSearchValue(e)}
                       name="searchValue"
                        className='form-control mt-3'
                      />
                      <span className="text-danger">
                        {this.state.filterOptionsTypeValidation && 'Vui lòng chọn'}
                      </span>
                    </Col>
                    <Col className="mt-2" sm={12} md={6}>
                      <div className="d-flex justify-content-between">
                        <div>
                          {startDate && (
                            <p>
                              Bắt đầu:{' '}
                              {format(startDate, 'dd MMM yyyy', {
                                locale: vi,
                              })}
                              .
                            </p>
                          )}
                          {endDate && (
                            <p>
                              Kết thúc:{' '}
                              {format(endDate, 'dd MMM yyyy', { locale: vi })}.
                            </p>
                          )}
                        </div>
                        <div>
                          <Button
                            onClick={() =>
                              this.setState({
                                isShowCalendar: !isShowCalendar,
                              })
                            }
                          >
                            {isShowCalendar ? 'Đóng ' : 'Hiển thị '}
                            Lịch
                          </Button>
                        </div>
                      </div>
                      {isShowCalendar && (
                        <DateRangePickerCalendar
                          startDate={startDate}
                          endDate={endDate}
                          focus={focus}
                          onStartDateChange={(e) => this.setStartDate(e)}
                          onEndDateChange={(e) => this.setEndDate(e)}
                          onFocusChange={(e) => this.handleFocusChange(e)}
                          locale={vi}
                        />
                      )}
                    </Col>
                    <Col className="mt-2 d-flex justify-content-between align-items-center" sm={12} md={6}>
                      <Button onClick={() => this.filterData()}>
                        Lọc kết quả
                      </Button>
                      <FormGroup check>
                        <Input type="checkbox"
                          checked={isShowStatusNone}
                          onChange={(e) => this.handleShowResult(e)} />
                        {' '}
                        <Label check>
                          Ẩn mục đã duyệt
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} className="mt-3">
            <Card>
              <CardHeader className="d-flex justify-content-between align-items-center">
                <p className="h5 font-weight-bold m-0">Danh sách kiện hàng </p>
                {items.length > 0 && (
                  <Button
                    disabled={this.state.disableDownloadButton}
                    onClick={() => this.exportData()}
                  >
                    Tải về Excel
                  </Button>
                )}
              </CardHeader>
              <CardBody>
                {items.length > 0 && (
                  <Table bordered hover responsive size="" striped>
                    <thead className="text-center">
                      <tr>
                        <th>#</th>
                        <th>Mã KG</th>
                        <th>Thông tin</th>
                        <th>
                          <span className="text-uppercase d-block">Chi hộ</span>
                          <strong>
                            {new Intl.NumberFormat().format(paymentCodTotal) ||
                              0}
                          </strong>
                        </th>
                        <th>
                          <span className="text-uppercase d-block">
                            TIỀN MẶT
                          </span>
                          <strong>
                            {new Intl.NumberFormat().format(paymentCashTotal)}
                          </strong>
                        </th>
                        <th>
                          <span className="text-uppercase d-block">
                            CK KHOẢN
                          </span>
                          <strong>
                            {new Intl.NumberFormat().format(
                              paymentBankingTotal,
                            )}
                          </strong>
                        </th>
                        <th>CHỦ TK NHẬN</th>
                        <th>Ghi chú</th>
                        <th>Trạng thái</th>
                        <th>Kế Toán</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {items.map((pkg, i) => (
                        <tr className={
                          !pkg.reportStatus ? 'bg-danger' : ''
                        }>
                          <td className="align-middle">{i + 1}</td>
                          <td className="align-middle">{pkg.kgCode || ''}</td>
                          <td className="align-middle text-left">
                            <span className="text-uppercase">
                              {' '}
                              SHIPPER ACCT:
                              <strong>{pkg.senderCompanyName}</strong>
                            </span>
                            <br />
                            <span className="text-uppercase"> Date:</span>{' '}
                            <strong>
                              {' '}
                              {new Date(
                                pkg.reportAt?.toString(),
                              ).toLocaleDateString('vi')}{' '}
                            </strong>
                            <br />
                            {pkg.logisticCode && (
                              <>
                                <span className="text-uppercase">
                                  {' '}
                                  TRACKING:
                                  <strong>{pkg.logisticCode}</strong>
                                </span>
                                <br />
                              </>
                            )}
                            <span className="text-uppercase">
                              {' '}
                              RECEIVER ( CONTACT NAME ):
                              <strong>{pkg.receiverName}</strong>
                            </span>
                            <br />
                            {pkg.logisticService && (
                              <>
                                <span className="text-uppercase">
                                  {' '}
                                  SERVICE:
                                  <strong>{pkg.logisticService}</strong>
                                </span>
                                <br />
                              </>
                            )}
                            <span className="text-uppercase">
                              {' '}
                              ARRIVAL COUNTRY:
                              <strong>{pkg.receiverCountry}</strong>
                            </span>
                            <br />
                            {pkg.packageType !== DOC_TYPE && (
                              <>
                                <span className="text-uppercase">
                                  {' '}
                                  Total number of Packages:
                                </span>{' '}
                                {this.totalNum(pkg)} <br />
                                <span className="text-uppercase">
                                  {' '}
                                  Chargeable Weight:
                                </span>{' '}
                                {this.totalWeight(pkg)} <br />
                              </>
                            )}
                          </td>
                          <td className="align-middle">
                            <strong>
                              {new Intl.NumberFormat().format(pkg.paymentCod) ||
                                0}
                            </strong>
                          </td>
                          <td className="align-middle">
                            <strong>
                              {new Intl.NumberFormat().format(
                                pkg.paymentCash,
                              ) || 0}
                            </strong>
                          </td>
                          <td className="align-middle">
                            <strong>
                              {new Intl.NumberFormat().format(
                                pkg.paymentBanking,
                              ) || 0}
                            </strong>
                          </td>
                          <td className="align-middle">
                            <strong>{pkg.paymentNum}</strong>
                          </td>
                          <td className="align-middle">
                            <span>{pkg.note}</span>
                          </td>
                          <td className="align-middle">
                            <strong>{pkg.reportStatus}</strong>
                          </td>
                          <td className="align-middle">
                            <strong>{pkg.reportName}</strong>
                          </td>
                          <td className="align-middle">
                            <button
                              type="button"
                              className="btn btn-warning"
                              onClick={() => this.onUpdateDashboard(pkg, i)}
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className="btn btn-info ml-2"
                              onClick={() => this.onRemovePackageDetails(i)}
                            >
                              Xoá
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </CardBody>
              {items.length == 0 && (
                <div className="text-center m-2 pb-3">
                  Danh sách trống. Vui lòng quéc mã kiện hàng.
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}
export default Report
