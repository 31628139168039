import React from "react";

import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Table,
  Button,
  Label,
  Input,
  FormGroup,
  Form,
  CardBody,
  Card,
  CardHeader,
  FormFeedback,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

import Widget from "../../components/Widget/Widget";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import s from "./Bill.module.scss";
import { connect } from "react-redux";
import {
  createPackage,
  CREATE_PACKAGE_FAILURE,
  CREATE_PACKAGE_SUCCESS,
} from "../../actions/package";
import PropTypes from "prop-types";
import jwt from "jsonwebtoken";
import { DOC_TYPE, PACK_TYPE, CURRENCY, SHIPPING_SERVICES, SHIPPING_SERVICES_SPECIFIC,
    SHIPPING_SERVICES_5500, USER_ROLE } from "../../constants";
import { confirmAlert } from "react-confirm-alert"; // Import
import moment from 'moment';
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { Country, City } from "country-state-city";
import validator from "validator";
import Rounding from "../../utils/rounding";
import axios from "axios";
class Bill extends React.Component {
  initBillValidation = {
    errMsg: "",

    senderCompanyName: false,
    senderAddress: false,
    senderPhone: false,
    senderName: false,
    senderEmail: false,

    receiverCompanyName: false,
    receiverName: false,
    receiverPhone: false,
    receiverCountry: false,
    receiverPostalCode: false,
    receiverCity: false,
    receiverAddr1: false,
    receiverAddr2: false,
    packContent: false,

    docCount: false,
    docWeight: false,

    logisticService: false,
    packs: false,
    kangoUsers: [],
    selectedUser: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      termData: '',
      isOpenTerm: false,
      disableSubmitBtn: false,
      checkDk: false,
      validDkMess: "",
      currUser: {},
      currUser2: {},
      packList: [
        {
          quantity: "",
          type: "",
          length: "",
          width: "",
          height: "",
          weight: "",
          convertRatio: "",
          chargedWeight: "",
        },
      ],
      packInvoiceList: [
        {
          description: "",
          unit: "",
          quantity: "",
          unitPrice: "",
          subTotal: "",
        },
      ],
      billInfo: {
        companyUserID: "",
        senderCompanyName: "",
        senderName: "",
        senderContact: "",
        senderPhone: "",
        senderEmail: "",
        senderCountry: "",
        receiverCompanyName: "",
        receiverName: "",
        receiverPhone: "",
        receiverEmail: "",
        receiverCountry: "",
        receiverPostalCode: "",
        receiverCity: "",
        receiverProvince: "",
        receiverAddr1: "",
        receiverAddr2: "",
        receiverAddr3: "",
        logisticService: "",
        referenceCode: "",
        docCount: 0,
        docWeight: 0,
        packContent: "",
        packInvoiceValue: "",
        packInvoiceUnit: "USD",
        packageType: DOC_TYPE,
        hawbCode: "",
        invoices: JSON.stringify([
          {
            description: "",
            unit: "",
            quantity: "",
            unitPrice: "",
            subTotal: "",
          },
        ]).replaceAll(`\"`, `\\"`),
        packs: JSON.stringify([
          {
            quantity: "",
            type: "",
            length: "",
            width: "",
            height: "",
            weight: "",
            convertRatio: "",
            chargedWeight: "",
          },
        ]).replaceAll(`\"`, `\\"`),
        invoiceExportFormat: "",
      },

      billValidation: this.initBillValidation,
      packsDetailsValidation: [
        {
          errMsg: "",
          quantity: false,
          length: false,
          width: false,
          height: false,
          weight: false,
        },
      ],
      tpValid: true,
      billupInfo: ''
    };

    this.handlePackageTypeChange = this.handlePackageTypeChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPackInfoChange = this.onPackInfoChange.bind(this);
    this.onRemoveRecord = this.onRemoveRecord.bind(this);
    this.onRemovePackInvoiceRecord = this.onRemovePackInvoiceRecord.bind(this);
    this.addPackage = this.addPackage.bind(this);
    this.addPackageInvoice = this.addPackageInvoice.bind(this);
    this.validateBillInfo = this.validateBillInfo.bind(this);
    this.onSelectedUser = this.onSelectedUser.bind(this);
    this.viewBillup = this.viewBillup.bind(this);
  }

  /* eslint-disable */
  static propTypes = {
    package: PropTypes.any,
    isCreating: PropTypes.bool,
    errMsg: PropTypes.string,
    type: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  };
  /* eslint-enable */

  static defaultProps = {
    package: {},
    isCreating: false,
    errMsg: "",
    type: "",
  };


  onInfoChange = (e) => {
    const { name, value } = e.target;
    var newInfo = this.state.billInfo;
    newInfo[name] = value;
    console.log(newInfo)
    this.setState(newInfo);
    this.validateBillOnInfoChange(name, value)
    if (name === 'logisticService' && this.state.billInfo.packageType === PACK_TYPE) {
      let ratioConstant = this.state.currUser.role === USER_ROLE.CUSTOMER &&
       value && SHIPPING_SERVICES_SPECIFIC.includes(value.toUpperCase()) ? 6000 : 5000

      ratioConstant = (this.state.currUser?.companyName.indexOf('TIN PHAT EXPRESS CO.,LTD') !== -1)
      && value && SHIPPING_SERVICES_5500.includes(value.toUpperCase()) ? 5500 : ratioConstant

      ratioConstant = (this.state.currUser?.companyName.indexOf('KANGO') !== -1) ||
        this.state.currUser.role === USER_ROLE.SALES ? 5000 : ratioConstant

      const list = [...this.state.packList]
      for (let index = 0; index < list.length; index++) {
        list[index]["convertRatio"] = Rounding((list[index]["length"] * list[index]["width"] * list[index]["height"]) / ratioConstant)
        list[index]['chargedWeight'] = list[index]['weight'] > list[index]['convertRatio']
        ? Rounding(parseFloat(list[index]['weight']).toFixed(1) * parseInt(list[index]['quantity']))
        : Rounding(list[index]['convertRatio'] * list[index]['quantity'])
      }
      this.setState((prevState) => ({
        packList: list,
        billInfo: {
          ...prevState.billInfo,
          packs: JSON.stringify(list).replaceAll(`\"`, `\\"`),
        },
      }))
    }
  };

  onSelectChange = (value, action) => {
    var newInfo = this.state.billInfo;
    newInfo[action.name] = value.value;
    this.setState(newInfo)
    this.validateBillOnInfoChange(action.name, value)
  }
  onSelectedUser = (e) => {
    const value = e.target.value
    if (value) {
      const obj = this.state.kangoUsers.find(e => e.id == value)
      this.setState({selectedUser: value, currUser2: obj});
    } else {
      this.setState({selectedUser: '', currUser2: this.state.currUser});
    }
  }
  async viewBillup(e) {
    e.preventDefault();
    const {kgCode} = this.state.billInfo
    if (kgCode) {
      const data = await this.getBillupById(kgCode)
      if (data) {
        this.setState({billupInfo: data})
        return
      }
    }
    this.setState({billupInfo: ''})
  }
  async getBillupById(id) {
    const { kgCode } = this.state.billInfo
    if (kgCode) {
      try {
        const { data } = await axios.post(`/get-billup/${kgCode}`)
        if (!data) {
          this.setState({tpValid: false})
          return
        }
        this.setState({tpValid: true})
        return data
      } catch (e) {
        return ''
      }
    }
    return ''
  }
  formatNumber (num) {
    if (num) {
      return new Intl.NumberFormat().format(num)
    }
    return num
  }

  onPackInfoChange = (e, index) => {
    // validate here
    const { name, value } = e.target;
    const list = [...this.state.packList];
    list[index][name] = value;

    // update convertRatio
    if (name === "length" || name === "width" || name === "height") {
      const { logisticService } = this.state.billInfo
      let ratioConstant = this.state.currUser.role === USER_ROLE.CUSTOMER &&
      logisticService && SHIPPING_SERVICES_SPECIFIC.includes(logisticService.toUpperCase()) ? 6000 : 5000

      ratioConstant = (this.state.currUser?.companyName.indexOf('TIN PHAT EXPRESS CO.,LTD') !== -1)
      && logisticService && SHIPPING_SERVICES_5500.includes(logisticService.toUpperCase()) ? 5500 : ratioConstant

      ratioConstant = (this.state.currUser?.companyName.indexOf('KANGO') !== -1) ||
        this.state.currUser.role === USER_ROLE.SALES ? 5000 : ratioConstant

      list[index]["convertRatio"] = Rounding(
        (list[index]["length"] * list[index]["width"] * list[index]["height"]) / ratioConstant
      );
    }
    // update charged weight
    list[index]["weight"] > list[index]["convertRatio"]
      ? (list[index]["chargedWeight"] = Rounding(
          parseFloat(list[index]["weight"]).toFixed(1) *
            parseInt(list[index]["quantity"])
        ))
      : (list[index]["chargedWeight"] = Rounding(
          list[index]["convertRatio"] * list[index]["quantity"]
        ));

    this.setState((prevState) => ({
      packList: list,
      billInfo: {
        ...prevState.billInfo,
        packs: JSON.stringify(list).replaceAll(`\"`, `\\"`),
      },
    }));
    this.validatePackOnPackInfoChange(name, value, index);
  };

  validatePacksDetails(packsDetails) {
    let validations = this.state.packsDetailsValidation;
    let isValid = true;

    packsDetails.forEach((pack, i) => {
      let errMsgs = [];

      if (pack.quantity <= 0) {
        isValid = false;

        validations[i].quantity = true;
        errMsgs.push("Quantity is required.");
      } else {
        validations[i].quantity = false;
      }

      if (pack.height <= 0) {
        isValid = false;

        validations[i].height = true;
        errMsgs.push("Height is required.");
      } else {
        validations[i].height = false;
      }

      if (pack.width <= 0) {
        isValid = false;

        validations[i].width = true;
        errMsgs.push("Width is required.");
      } else {
        validations[i].width = false;
      }

      if (pack.length <= 0) {
        isValid = false;

        validations[i].length = true;
        errMsgs.push("Length is required.");
      } else {
        validations[i].length = false;
      }

      if (pack.weight <= 0) {
        isValid = false;
        validations[i].weight = true;
        errMsgs.push("Weight is required.");
      } else {
        validations[i].weight = false;
      }

      if (pack.weight > 0 && pack.weight < 30) {
        const dec = pack.weight - parseInt(pack.weight);
        if (dec.toFixed(1) != 0.5 && dec.toFixed(1) != 0.0) {
          isValid = false;

          validations[i].weight = true;
          errMsgs.push(
            "Hãy làm tròn số thập phân .5 hoặc số nguyên. VD: 1.5 hoặc 1"
          );
        } else {
          validations[i].weight = false;
        }
      } else {
        const dec = pack.weight - parseInt(pack.weight);
        if (dec.toFixed(1) != 0.0) {
          isValid = false;

          validations[i].weight = true;
          errMsgs.push("Hãy làm tròn số nguyên. VD: 30.5 -> 30 hoặc 31");
        } else {
          validations[i].weight = false;
        }
      }

      validations[i].errMsg = errMsgs.join(" ");
    });

    this.setState((prevState) => ({
      ...prevState,
      packsDetailsValidation: validations,
    }));

    return isValid;
  }

  validatePackOnPackInfoChange(name, value, i){
    let validations = this.state.packsDetailsValidation;

      if (name === 'quantity' && value <= 0) {
        validations[i].quantity = true;
      } else {
        validations[i].quantity = false;
      }

      if (name === 'height' && value <= 0) {
        validations[i].height = true;
      } else {
        validations[i].height = false;
      }

      if (name ==='width' && value <= 0) {
        validations[i].width = true;
      } else {
        validations[i].width = false;
      }

      if (name === 'length' && value <= 0) {
        validations[i].length = true;
      } else {
        validations[i].length = false;
      }

      if (name === 'weight' && value <= 0) {
        validations[i].weight = true;
      } else {
        validations[i].weight = false;
      }

    this.setState((prevState) => ({
      ...prevState,
      packsDetailsValidation: validations,
    }));

  }

  onChangeInvoice(e, i) {
    const { name, value } = e.target;
    const list = [...this.state.packInvoiceList];
    if (name === "receiverCountry") {
      // value is country object
      list[i][name] = value.name;
    } else {
      list[i][name] = value;
    }

    list[i]["subTotal"] = list[i]["quantity"] * list[i]["unitPrice"]; // calculate subTotal

    // calculate invoice value
    let invVal = 0;
    if (name === "packInvoiceValue") {
      invVal = value;
    } else {
      invVal = (
        list.reduce(function(a, b) {
          return a + parseFloat(b["subTotal"]);
        }, 0) || 0
      ).toFixed(1);
    }

    this.setState((prevState) => ({
      packInvoiceList: list,
      billInfo: {
        ...prevState.billInfo,
        invoices: JSON.stringify(list).replaceAll(`\"`, `\\"`),
        packInvoiceValue: invVal,
      },
    }));
  }

  onRemoveRecord = (index) => {
    const list = [...this.state.packList];

    confirmAlert({
      title: "Delete Confirmation",
      message: "Are you sure to delete this Pack?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            list.splice(index, 1);
            this.setState({ packList: list });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  onRemovePackInvoiceRecord = (index) => {
    const list = [...this.state.packInvoiceList];

    confirmAlert({
      title: "Delete Confirmation",
      message: "Are you sure to delete this Invoice?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            list.splice(index, 1);
            this.setState({ packInvoiceList: list });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  addPackage = () => {
    this.setState((prevState) => ({
      packList: [
        ...prevState.packList,
        {
          quantity: "",
          type: "",
          length: "",
          width: "",
          height: "",
          weight: "",
          convertRatio: "",
          chargedWeight: "",
        },
      ],
      packsDetailsValidation: [
        ...prevState.packsDetailsValidation,
        {
          errMsg: "",
          quantity: false,
          length: false,
          width: false,
          height: false,
          weight: false,
        },
      ],
    }));
  };
  addPackageInvoice = () => {
    this.setState((prevState) => ({
      packInvoiceList: [
        ...prevState.packInvoiceList,
        {
          description: "",
          unit: "",
          quantity: "",
          unitPrice: "",
          subTotal: "",
        },
      ],
    }));
  };

  validateBillInfo(info) {
    let validationState = this.state.billValidation;
    let errors = [];
    if (info.senderCompanyName === "") {
      validationState.senderCompanyName = true;
      errors.push("your company name");
    } else validationState.senderCompanyName = false;
    if (info.senderName === "") {
      validationState.senderName = true;
      errors.push("your name");
    } else validationState.senderName = false;
    if (info.senderPhone === "") {
      validationState.senderPhone = true;
      errors.push("your phone");
    } else  validationState.senderPhone = false;
    if (this.state.currUser && this.state.currUser.address === "") {
      validationState.senderAddress = true;
      errors.push("your company's address");
    } else validationState.senderAddress = false;
    if (info.senderEmail !== "" && !validator.isEmail(info.senderEmail)) {
      validationState.senderEmail = true;
      errors.push("sender email");
    } else validationState.senderEmail = false;

    if (info.receiverCompanyName === "") {
      validationState.receiverCompanyName = true;
      errors.push("receiver company's name");
    } else validationState.receiverCompanyName = false;
    if (info.receiverName === "") {
      validationState.receiverName = true;
      errors.push("receiver name");
    } else validationState.receiverName = false;
    if (info.receiverPhone === "") {
      validationState.receiverPhone = true;
      errors.push("receiver phone");
    } else validationState.receiverPhone = false;
    if (info.receiverCountry === "") {
      validationState.receiverCountry = true;
      errors.push("receiver country");
    } else validationState.receiverCountry = false;
    if (info.receiverPostalCode === "") {
      validationState.receiverPostalCode = true;
      errors.push("receiver postal code");
    } else validationState.receiverPostalCode = false;
    if (info.receiverCity === "") {
      validationState.receiverCity = true;
      errors.push("receiver city");
    } else validationState.receiverCity = false;
    if (info.receiverAddr1 === "") {
      validationState.receiverAddr1 = true;
      errors.push("receiver address 1");
    } else validationState.receiverAddr1 = false;
    if (info.receiverAddr2 === "") {
      validationState.receiverAddr2 = true;
      errors.push("receiver address 2");
    } else validationState.receiverAddr2 = false;
    if (info.packageType === PACK_TYPE && info.packContent === "") {
      validationState.packContent = true;
      errors.push("package name");
    } else validationState.packContent = false;

    if (info.logisticService === "") {
      validationState.logisticService = true;
      errors.push("logistic service");
    } else validationState.logisticService = false;

    if (info.packageType === DOC_TYPE && info.docCount <= 0) {
      validationState.docCount = true;
      errors.push("doc count");
    } else validationState.docCount = false;
    if (info.packageType === DOC_TYPE && info.docWeight < 0.01) {
      validationState.docWeight = true;
      errors.push("doc weight");
    } else validationState.docWeight = false;

    if (errors.length > 0) {
      validationState.errMsg = "Please input valid " + errors.join(", ");
      this.setState({ billValidation: validationState });
      return false;
    }
    return true;
  }

  validateBillOnInfoChange(name, value){
    let validationState = this.state.billValidation;

    let arrStr = ['senderCompanyName', 'senderName', 'senderPhone', 'receiverCompanyName', 'receiverName', 'receiverPhone', 'receiverCountry',
     'receiverPostalCode', 'receiverCity', 'receiverAddr1', 'receiverAddr2', 'logisticService' ];
    if (arrStr.indexOf(name) && value === "") {
      validationState[name] = true;
    } else validationState[name] = false;

    if (name === 'senderEmail' && value !== "" && !validator.isEmail(value)) {
      validationState.senderEmail = true;
    } else validationState.senderEmail = false;

    if (name === 'docCount' && value <= 0) {
      validationState.docCount = true;
    } else validationState.docCount = false;
    if (name === 'docWeight' && value < 0.01) {
      validationState.docWeight = true;
    } else validationState.docWeight = false;

    this.setState((prevState) => ({
      ...prevState,
      billValidation: validationState,
    }));
  }

  async onSubmit(e) {
    if (this.state.disableSubmitBtn) return
    this.setState({
      disableSubmitBtn: true,
    });
    if (
      !this.validatePacksDetails(this.state.packList) &&
      this.state.packageType === PACK_TYPE
    ) {
      for (const validation of this.state.packsDetailsValidation) {
        if (validation.errMsg !== "") {
          toast.error(validation.errMsg);
        }
      }
      this.setState({isOpenTerm: true})
      this.setState({
        disableSubmitBtn: false,
      });
      return;
    }

    if (!this.validateBillInfo(this.state.billInfo)) {
      toast.error(this.state.billValidation.errMsg);
      this.setState({
        disableSubmitBtn: false,
      });
      return;
    }

    const { checkDk } = this.state
    if (!checkDk) {
      this.setState({isOpenTerm: true})
      this.setState({
        disableSubmitBtn: false,
      });
      return;
    }

    const { kgCode } = this.state.billInfo
    if (kgCode) {
      try {
        const { data } = await axios.post(`/get-billup/${kgCode}`)
        if (!data) {
          this.setState({tpValid: false, disableSubmitBtn: false})
          return
        }
        this.setState({tpValid: true, billupInfo: data})
      } catch (e) {
      }
    } else {
      this.setState({tpValid: false, disableSubmitBtn: false,})
      return
    }

    const { data: isExist } = await axios.get(`/package-by-tp-code/${kgCode}`)
    if (isExist) {
      toast.error('Đơn hàng đã tồn tại trong hệ thống');
      this.setState({disableSubmitBtn: false,})
      return
    }

    this.setState({
      validDkMess: "",
      billValidation: this.initBillValidation,
      disableSubmitBtn: true,
    });
    if (this.state.selectedUser) {
      this.props.dispatch(createPackage({...this.state.billInfo, owner: this.state.billupInfo.owner, companyUserID: this.state.selectedUser}));
    } else {
      this.props.dispatch(createPackage({...this.state.billInfo, owner: this.state.billupInfo.owner}));
    }
    this.setState({disableSubmitBtn: false})
    await axios.post(`/update-billup/${kgCode}`, {statusType: 'Đã làm chứng từ'})
    try {
      const { data: user} = await axios.get(`/user-by-name/${this.state.billupInfo.owner}`)
      if (user && (user.role !== 'ops')) {
        await axios.post(`/send-gapo`, {
          requestId: kgCode,
          senderPhone: this.state.billupInfo.customerPhone,
          receiverPhone: this.state.billupInfo.receivePhone,
          ngayTao: moment(this.state.billupInfo.createdAt).format('DD/MM/YYYY'),
          tenkh: this.state.billupInfo.receiveName,
          type: "sending"
        })
      }
    } catch (e) {
      console.log(e)
    }
    e.preventDefault();
  }

  async componentDidMount() {
    let token = localStorage.getItem("id_token");
    let currUser = jwt.decode(token);
    this.setState({ currUser: currUser, currUser2: currUser });
    const termData = await fetch('/term.txt').then((r) => r.text())
    await this.setState({ termData })
    let newState = this.state.billInfo;
    newState.companyUserID = currUser.id;
    newState.senderCompanyName = currUser.companyName;

    this.setState({ billInfo: newState });

    if (currUser.role === USER_ROLE.ADMIN || currUser.role === USER_ROLE.DOCUMENT) {
      const { data } = await axios.get('/users-kango')
      console.log('userrr ', data)
      if (data && data.length) {
        this.setState({kangoUsers: data})
      }
    }
  }

  handlePackageTypeChange(e) {
    let newBillInfo = this.state.billInfo;
    newBillInfo.packageType = e.target.value;
    this.setState(newBillInfo);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isCreating && !this.props.isCreating) {
      if (this.props.type === CREATE_PACKAGE_FAILURE) {
        toast.error(this.props.errMsg);
      } else if (this.props.type === CREATE_PACKAGE_SUCCESS) {
        toast.success("Success! Created bill");
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 3000)
      }
    }
  }

  getInvoiceVal(e) {
    this.setState((prevState) => ({
      billInfo: {
        ...prevState.billInfo,
        packInvoiceValue: this.state.packInvoiceList.reduce(function(a, b) {
          return a + parseFloat(b["subTotal"]);
        }, 0),
      },
    }));
  }
  toggleChange = async () => {
    await this.setState({
      checkDk: !this.state.checkDk,
    });
    if (this.state.checkDk) {
      this.setState({
        isOpenTerm: false,
      });
    }
  }
  render() {
    const {isOpenTerm, billupInfo = ''} = this.state
    var packTable = "";
    if (this.state.billInfo.packageType === PACK_TYPE) {
      packTable = (
        <div>
          <Row>
            <Col sm="12" md={{ size: 10, offset: 1 }}>
              <Widget
                title={
                  <h4>
                    {" "}
                    <span className="fw-semi-bold">PACK DETAILS</span>
                  </h4>
                }
                settings
                close
              >
                <Row>
                  <Col>
                    <p style={{ color: "red" }}>
                      {" "}
                      *Chú ý: Gross Weight và Dimension phải được làm tròn
                    </p>
                    <p>
                      - Làm tròn 0,5kg đối với những kiện hàng dưới 30kg.
                      <br />
                      Ví dụ: 25.1kg làm tròn 25.5kg hoặc 25.6kg làm tròn 26kg
                      <br />
                      - Làm tròn 1kg đối với những kiện hàng trên 30kg
                      <br />
                      Ví dụ: 30.1kg -{">"} 30.9kg làm tròn 31kg
                    </p>
                  </Col>
                  <Col className="text-right">
                    <Label>
                      {" "}
                      Total Number of Packs: &nbsp;{" "}
                      {this.state.packList.reduce(function(a, b) {
                        return a + parseInt(b["quantity"]);
                      }, 0) || 0}
                    </Label>{" "}
                    <br />
                    <Label>
                      {" "}
                      Total Charged Weight: &nbsp;{" "}
                      {Rounding(
                        this.state.packList.reduce(function(a, b) {
                          return a + parseFloat(b["chargedWeight"]);
                        }, 0)
                      ) || 0}{" "}
                    </Label>{" "}
                    <br />
                    <br />
                    {this.state.packsDetailsValidation.packs ? (
                      <p style={{ color: "red" }}>
                        {" "}
                        *Please input valid package details
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Table
                  responsive
                  className="table-bordered table-sm"
                  width="100%"
                >
                  <thead className="text-uppercase table-light">
                    <tr>
                      <th className="text-center">Quantity</th>
                      <th className="text-center">Type</th>
                      <th className="text-center">Length (cm)</th>
                      <th className="text-center">Width (cm)</th>
                      <th className="text-center">Height (cm)</th>
                      <th className="text-center">Weight (kg)</th>
                      <th className="text-center">Converted Weight</th>
                      <th className="text-center">Charged Weight</th>
                      <th className="text-center">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.packList.map((x, i) => {
                      return (
                        <tr>
                          <td className="text-center">
                            <div className="input-group input-group-sm">
                              <Input
                                className="form-control quantity"
                                name="quantity"
                                value={x.quantity}
                                invalid={
                                  this.state.packsDetailsValidation[i].quantity
                                }
                                type="number"
                                id="quantity"
                                onChange={(e) => this.onPackInfoChange(e, i)}
                              />
                            </div>
                          </td>
                          <td>
                            <select
                              name="type"
                              className={s.selectCustom}
                              aria-label="Default select example"
                              onChange={(e) => this.onPackInfoChange(e, i)}
                            >
                              <option value="" defaultValue>
                                Loại
                              </option>
                              <option value="carton">Carton</option>
                              <option value="pallet">Pallet</option>
                              <option value="tui">Túi (Phong bì)</option>
                            </select>
                          </td>

                          <td className="text-center">
                            <div className="input-group input-group-sm ">
                              <Input
                                name="length"
                                value={x.length}
                                type="number"
                                id="chieudai"
                                invalid={
                                  this.state.packsDetailsValidation[i].length
                                }
                                onChange={(e) => this.onPackInfoChange(e, i)}
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="input-group input-group-sm ">
                              <Input
                                name="width"
                                value={x.width}
                                type="number"
                                id="chieurong"
                                invalid={
                                  this.state.packsDetailsValidation[i].width
                                }
                                onChange={(e) => this.onPackInfoChange(e, i)}
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="input-group input-group-sm ">
                              <Input
                                name="height"
                                value={x.height}
                                type="number"
                                id="chieucao"
                                invalid={
                                  this.state.packsDetailsValidation[i].height
                                }
                                onChange={(e) => this.onPackInfoChange(e, i)}
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="input-group input-group-sm">
                              <Input
                                type="number"
                                name="weight"
                                value={x.weight}
                                id="trongluong"
                                invalid={
                                  this.state.packsDetailsValidation[i].weight
                                }
                                onChange={(e) => this.onPackInfoChange(e, i)}
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <label id="tlquydoi"> {x.convertRatio} </label>
                          </td>
                          <td className="text-center">
                            <label id="chargedweight">
                              {" "}
                              {x.chargedWeight}{" "}
                            </label>
                          </td>
                          <td className="text-center">
                            <button
                              type="button"
                              className="btn btn-default"
                              aria-label="glyphicon-trash"
                              onClick={() => this.onRemoveRecord(i)}
                            >
                              <span
                                className="glyphicon glyphicon-trash"
                                aria-hidden="true"
                              ></span>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="text-center d-flex align-content-center justify-content-center">
                  <Button
                    type="button"
                    id="plus"
                    className="btn btn-default btn-add"
                    aria-label="Left Align"
                    onClick={this.addPackage}
                  >
                    <i class="fa fa-plus-circle"></i>
                    Thêm Kiện hàng
                  </Button>
                </div>
              </Widget>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md={{ size: 10, offset: 1 }}>
              <Widget
                title={
                  <h4>
                    {" "}
                    <span className="fw-semi-bold">Invoice</span>
                  </h4>
                }
                settings
                close
              >
                <FormGroup name="invoiceExportFormat" className="form-inline">
                  <Label id="invoiceExportFormat" name="invoiceExportFormat">
                    Export as: &nbsp;{" "}
                  </Label>
                  <Input
                    placeholder="Choose"
                    name="invoiceExportFormat"
                    className="form-control"
                    type="text"
                    onChange={(e) => this.onInfoChange(e)}
                    list="invoiceExportForms"
                  />
                  <datalist id="invoiceExportForms">
                    <option value="Gift (no commercial value)" />
                    <option value="Sample" />
                    <option value="Other" />
                  </datalist>
                </FormGroup>
                <Table responsive className="table-bordered table-sm mb-0">
                  <thead className="text-uppercase table-light">
                    <tr>
                      <th className="text-center">
                        Goods Details
                        <br />
                        (Product names, materials, stamps, ...)
                      </th>
                      <th className="text-center">Quantity</th>
                      <th className="text-center">Unit</th>
                      <th className="text-center">Price</th>
                      <th className="text-center">Total Value</th>
                      <th className="text-center">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.packInvoiceList.map((x, i) => {
                      return (
                        <tr>
                          <td className="text-center">
                            <div className="input-group">
                              <Input
                                name="description"
                                value={x.description}
                                type="textarea"
                                className="description"
                                id="description"
                                onChange={(e) => this.onChangeInvoice(e, i)}
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="input-group flex-nowrap">
                              <Input
                                name="quantity"
                                value={x.quantity}
                                type="number"
                                className="quantity"
                                id="quantity"
                                placeholder="quantity"
                                onChange={(e) => this.onChangeInvoice(e, i)}
                              />
                            </div>
                          </td>
                          <td>
                            <Input
                              placeholder="Choose"
                              name="unit"
                              className="form-control"
                              type="text"
                              onChange={(e) => this.onChangeInvoice(e, i)}
                              list="unitList"
                            />
                            <datalist id="unitList">
                              <option value="Bag" />
                              <option value="Pcs" />
                              <option value="Box" />
                              <option value="Jar" />
                              <option value="Set" />
                              <option value="Other" />
                            </datalist>
                          </td>
                          <td className="text-center">
                            <div className="input-group flex-nowrap">
                              <Input
                                name="unitPrice"
                                value={x.unitPrice}
                                type="number"
                                className="unitPrice"
                                id="unitPrice"
                                onChange={(e) => this.onChangeInvoice(e, i)}
                              />
                            </div>
                          </td>
                          <td name="subTotal" className="text-center">
                            {parseFloat(x.subTotal || 0).toFixed(1)}
                          </td>
                          <td className="text-center">
                            <button
                              type="button"
                              className="btn btn-default"
                              aria-label="glyphicon-trash"
                              onClick={() => this.onRemovePackInvoiceRecord(i)}
                            >
                              <span
                                className="glyphicon glyphicon-trash"
                                aria-hidden="true"
                              ></span>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                <br />
                <div className="text-center">
                  <Button
                    type="button"
                    id="plus"
                    className="btn btn-default"
                    aria-label="Left Align"
                    onClick={this.addPackageInvoice}
                  >
                    <span
                      className="glyphicon glyphicon-plus"
                      aria-hidden="true"
                    ></span>
                    Thêm
                  </Button>
                </div>
              </Widget>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <div>
         <Modal isOpen={isOpenTerm} backdrop="static" size="xl">
          <ModalHeader className="text-danger text-uppercase">điều khoản sử dụng dịch vụ</ModalHeader>
          <ModalBody>
            { ReactHtmlParser (this.state.termData) }
          </ModalBody>
          <ModalFooter>

              <div className="container mt-3 d-flex justify-content-center ">
                <div className="form-check mb-3">
                  <div>
                    {' '}
                    <input
                      name="checkDk"
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      defaultChecked={this.state.checkDk}
                      onChange={this.toggleChange}
                    />
                  </div>
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Tôi đã đọc, hiểu và đồng ý với các điều khoản sử dụng dịch vụ
                  </label>
                  <small className="form-text  text-danger">
                    {this.state.validDkMess}
                  </small>
                </div>
              </div>
          </ModalFooter>
        </Modal>
        <Form className="needs-validation">
          <div className={s.root} id="BillForm">
            <Breadcrumb>
              <BreadcrumbItem>TRANG</BreadcrumbItem>
              <BreadcrumbItem active>Hoá đơn</BreadcrumbItem>
            </Breadcrumb>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
            />
            <Row lg="3" md="2" xs="1">
              <Col lg="3" className="mb-4">
                <Card>
                  <CardHeader tag="h5">Thông tin Đơn hàng (Shipment Info)</CardHeader>
                  <CardBody>
                    {/*{
                      (this.state.currUser.role === USER_ROLE.ADMIN || this.state.currUser.role === USER_ROLE.DOCUMENT) ? (
                        <FormGroup>
                          <Label for="logisticService">Tạo đơn hàng với account</Label>
                          <select value={this.state.selectedUser}
                                  style={{maxWidth: '100%', display: "block"}}
                                  className="custom-select"
                                  onChange={(e) => this.onSelectedUser(e)}
                          >
                            <option value=""></option>
                            {this.state.kangoUsers && this.state.kangoUsers.map((option) => (
                              <option value={option.id}>{option.email}</option>
                            ))}
                          </select>
                        </FormGroup>
                      ) : null
                    }*/}

                    <FormGroup>
                      <Label for="logisticService">Dịch vụ vận chuyển (Services) <span className={s.required}>*</span></Label>
                      <Input
                        placeholder="Chọn hoặc nhập"
                        name="logisticService"
                        type="text"
                        id="logisticService"
                        value={this.state.billInfo.logisticService}
                        onChange={(e) => this.onInfoChange(e)}
                        list="logisticServiceList"
                      />
                      <datalist id="logisticServiceList">
                        {SHIPPING_SERVICES.map(i => (<option value={i}> {i} </option>))}
                      </datalist>
                      <div className={s.error}>{this.state.billValidation.logisticService&&"Please input valid services"}</div>
                    </FormGroup>

                    <FormGroup>
                    <Label for="referenceCode">Ref Code</Label>
                    <div className="d-flex flex-row">
                      <Input
                        type="text"
                        id="referenceCode"
                        value={this.state.billInfo.referenceCode} name="referenceCode"
                        onChange={(e) => this.onInfoChange(e)} />
                    </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="referenceCode">HAWB Code</Label>
                      <div className="d-flex flex-row">
                        <Input
                            type="text"
                            id="referenceCode"
                            value={this.state.billInfo.hawbCode} name="hawbCode"
                            onChange={(e) => this.onInfoChange(e)} />
                      </div>
                    </FormGroup>
                    <FormGroup>
                    <Label for="referenceCode">RG Code</Label>
                    <div className="d-flex flex-row">
                      <Input
                        type="text"
                        id="referenceCode"
                        value={this.state.billInfo.kgCode} name="kgCode"
                        onChange={(e) => this.onInfoChange(e)} />
                      <button className="btn btn-secondary ml-1" style={{borderRadius: "8px",height: '38px'}} onClick={e => this.viewBillup(e)}>Xem Billup</button>
                    </div>
                      {
                        !this.state.tpValid && (
                          <FormFeedback style={{display: 'block'}}>RG Cde không tồn tại trong hệ thống</FormFeedback>
                        )
                      }
                    </FormGroup>
                    {billupInfo && (
                      <div style={{borderColor: '#BFE04B', borderWidth: '1px', borderStyle: 'solid', backgroundColor: '#fff'}} className="p-2 mb-1">
                        <Label for="referenceCode">Thông tin Billup</Label>
                        <div>
                          <strong>RG Code</strong>: RG{billupInfo.id}
                          <div>
                            <p><strong>Receiver Name:</strong> {billupInfo.receiveName}</p>
                            <p><strong>Receiver Phone:</strong> {billupInfo.receivePhone}</p>
                            <p><strong>Receiver Address:</strong> {billupInfo.receiveAddress}</p>
                          </div>
                          <div>
                            <p><strong>Số kiện:</strong> {billupInfo.packs}</p>
                            <p><strong>Cân nặng:</strong> {billupInfo.weight}kg</p>
                            <p><strong>Cân nặng quy đổi:</strong> {billupInfo.weight2}kg</p>
                            <p><strong>Nhóm hàng:</strong> {billupInfo.group}</p>
                            <p><strong>Dịch vụ:</strong> {billupInfo.service}</p>
                            {
                              billupInfo.note && (
                                <p><strong>Note:</strong> {billupInfo.note}</p>
                              )
                            }
                          </div>
                          {billupInfo.info}
                        </div>
                      </div>
                    )}

                  {(this.state.billInfo.packageType === PACK_TYPE && (
                    <>
                      <FormGroup>
                        <Label for="packContent">Tên Hàng (content) <span className={s.required}>*</span></Label>
                        <Input type="text" id="packContent" value={this.state.billInfo.packContent} name="packContent" invalid={this.state.billValidation.packContent}
                        onChange={(e) => this.onInfoChange(e)} />
                        <FormFeedback>{this.state.billValidation.packContent >0 && "Please input valid pack content"}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="packInvoiceValue">Giá trị khai báo (invoice value)</Label>
                        <div className="input-group">
                          <Input type="number" id="packInvoiceValue" enable="false" value={this.state.billInfo.packInvoiceValue} name="packInvoiceValue"
                          invalid={this.state.billValidation.packInvoiceValue} onChange={(e) => this.onInfoChange(e)} />
                          <div className="input-group-append w-50">
                            <button
                              className="btn btn-outline-secondary btn-sm"
                              type="button"
                              id="btn_gtri"
                              onClick={(e) => this.getInvoiceVal(e)}
                            >
                              GET
                            </button>
                            <Input
                              placeholder="Loại"
                              name="packInvoiceUnit"
                              className="input-group-append"
                              type="text"
                              value={this.state.billInfo.packInvoiceUnit}
                              onChange={(e) => this.onInfoChange(e)}
                              list="typeListUnit"
                            />
                            <datalist id="typeListUnit">
                              {CURRENCY.map(i => (<option value={i}> {i} </option>))}
                            </datalist>
                          </div>
                        </div>
                      </FormGroup>
                    </>
                  )) ||
                    (this.state.billInfo.packageType === DOC_TYPE && (
                      <>
                        <FormGroup>
                          <Label for="docCount">Số Kiện (Number of Packages) <span className={s.required}>*</span></Label>
                          <Input type="number" id="docCount" value={this.state.billInfo.docCount} name="docCount" invalid={this.state.billValidation.docCount}
                          onChange={(e) => this.onInfoChange(e)} />
                          <FormFeedback>{this.state.billValidation.docCount >0 && "Please input valid doc count"}</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <Label for="docWeight">Cân nặng (GrossWeight) <span className={s.required}>*</span></Label>
                          <div className="input-group">
                            <Input type="number" id="docWeight" value={this.state.billInfo.docWeight} name="docWeight" invalid={this.state.billValidation.docWeight}
                            onChange={(e) => this.onInfoChange(e)} />
                            <div className="input-group-append">
                              <span className="input-group-text">(Kg) </span>
                            </div>
                            <FormFeedback>{this.state.billValidation.docWeight >0 && "Please input valid doc weight"}</FormFeedback>
                          </div>
                        </FormGroup>
                      </>
                    ))}

                  <FormGroup row tag="fieldset" style={{padding: "0 15px"}}>
                    <Label>Loại (Type)  &nbsp; &nbsp;</Label>
                    <FormGroup check>
                      <Input
                        name="doc"
                        type="radio"
                        value={DOC_TYPE}
                        checked={this.state.billInfo.packageType === DOC_TYPE}
                        onChange={this.handlePackageTypeChange}
                      />
                      <Label check>
                        DOC  &nbsp; &nbsp;
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        name="pack"
                        type="radio"
                        value={PACK_TYPE}
                        checked={
                          this.state.billInfo.packageType === PACK_TYPE
                        }
                        onChange={this.handlePackageTypeChange}
                      />
                      <Label check>
                        PACK
                      </Label>
                    </FormGroup>
                  </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3" className="mb-4">
                <Card>
                  <CardHeader tag="h5">Thông tin Người gửi (Sender)</CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label for="senderCompanyName">Công ty (Company Name) <span className={s.required}>*</span></Label>
                      <Input id="senderCompanyName" name="senderCompanyName" type="text" value={this.state.billInfo.senderCompanyName}
                      invalid={this.state.billValidation.senderCompanyName} onChange={(e) => this.onInfoChange(e)} />
                      <FormFeedback>{this.state.billValidation.senderCompanyName&&"Please input valid company name"}</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="senderName">Người LH (Contact Name) <span className={s.required}>*</span></Label>
                      <Input id="senderName" name="senderName" type="text" invalid={this.state.billValidation.senderName} onChange={(e) => this.onInfoChange(e)} />
                      <FormFeedback>{this.state.billValidation.senderName&&"Please input valid contract name"}</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="senderContact">Địa chỉ Liên hệ (Contact address) <span className={s.required}>*</span></Label>
                      <Input id="senderContact" name="senderContact" type="text" value={this.state.billInfo.senderContact}
                      invalid={this.state.billValidation.senderAddress} onChange={(e) => this.onInfoChange(e)} />
                      <FormFeedback>{this.state.billValidation.senderAddress&&"Please input valid contract address"}</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="senderPhone">Số Điện thoại (Telephone) <span className={s.required}>*</span></Label>
                      <Input id="senderPhone" name="senderPhone" type="text" invalid={this.state.billValidation.senderPhone}
                      onChange={(e) => this.onInfoChange(e)} />
                      <FormFeedback>{this.state.billValidation.senderPhone&&"Please input valid phone"}</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Label for="senderEmail">Email</Label>
                      <Input id="senderEmail" name="senderEmail" type="email" invalid={this.state.billValidation.senderEmail}
                      onChange={(e) => this.onInfoChange(e)} />
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" md="12" className="mb-4">
                <Card>
                  <CardHeader tag="h5">Thông tin Người nhận (Receiver)</CardHeader>
                  <CardBody>
                  <Row>
                    <Col lg="6" md="6" sm="12">
                      <FormGroup>
                        <Label for="receiverCompanyName">Công ty (Company Name) <span className={s.required}>*</span></Label>
                        <Input id="receiverCompanyName" name="receiverCompanyName" type="text" invalid={this.state.billValidation.receiverCompanyName} onChange={(e) => this.onInfoChange(e)} />
                        <FormFeedback>{this.state.billValidation.receiverCompanyName&&"Please input valid receiver company's name"}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="receiverName">Người Liên hệ (Contact Name) <span className={s.required}>*</span></Label>
                        <Input id="receiverName" name="receiverName" type="text" invalid={this.state.billValidation.receiverName} onChange={(e) => this.onInfoChange(e)} />
                        <FormFeedback>{this.state.billValidation.receiverName&&"Please input valid receiver name"}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="receiverPhone">Số Điện thoại (Telephone) <span className={s.required}>*</span></Label>
                        <Input id="receiverPhone" name="receiverPhone" type="text" invalid={this.state.billValidation.receiverPhone} onChange={(e) => this.onInfoChange(e)} />
                        <FormFeedback>{this.state.billValidation.receiverPhone&&"Please input valid receiver phone"}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="receiverCountry">Quốc gia (Country) <span className={s.required}>*</span></Label>
                        <Input id="receiverCountry" name="receiverCountry" type="select" value={this.state.billInfo.receiverCountry}
                        invalid={this.state.billValidation.receiverCountry} onChange={(e) => this.onInfoChange(e)} >
                           {Country.getAllCountries().map((country) => (
                            <option defaultValue={country.isoCode === "VN"}>
                              {country.name + " - " + country.isoCode}
                            </option>
                          ))}
                        </Input>
                        <FormFeedback>{this.state.billValidation.receiverCountry&&"Please input valid receiver country"}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="receiverPostalCode">Mã Bưu chính (Postal code) <span className={s.required}>*</span></Label>
                        <Input id="receiverPostalCode" name="receiverPostalCode" type="text" invalid={this.state.billValidation.receiverPostalCode} onChange={(e) => this.onInfoChange(e)} />
                        <FormFeedback>{this.state.billValidation.receiverPostalCode&&"Please input valid receiver postal code"}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col lg="6" md="6" sm="12">
                      <FormGroup>
                        <Label for="receiverCity">Thành phố (City) <span className={s.required}>*</span></Label>
                      <Input
                          type="text"
                          className="form-control flex-fill"
                          name="receiverCity"
                          placeholder="Thành phố (City)"
                          onChange={(e) => this.onInfoChange(e)}
                          invalid={this.state.billValidation.receiverCity}
                          list="cityList"
                        />
                        <datalist id="cityList">
                          {this.state.billInfo.receiverCountry.split(" - ") &&
                            City.getCitiesOfCountry(
                              this.state.billInfo.receiverCountry.split(
                                " - "
                              )[1]
                            ).map((city) => <option>{city.name}</option>)}
                        </datalist>


                        {/* <Label for="receiverCity">Thành phố (City) <span className={s.required}>*</span></Label>
                        <FormFeedback>{this.state.billValidation.receiverCity&&"Please input valid receiver city"}</FormFeedback>
                        <Select id="receiverCity" name="receiverCity" value={CitiesOptions.find(o => o.value === this.state.billInfo.receiverCity)}
                        options={CitiesOptions}
                        styles={{
                          control: styles => ({
                            ...styles,
                            borderColor: this.state.billValidation.receiverCity ? 'red' : styles.borderColor,
                            height: 'calc(1.5em + 0.75rem + 2px)',
                            borderRadius: 0
                          })
                        }}
                        onChange={(value, action) => this.onSelectChange(value, action)}
                        /> */}
                        <div className={s.error}>{this.state.billValidation.receiverCity&&"Please input valid receiver city"}</div>
                      </FormGroup>

                      <FormGroup>
                        <Label for="receiverProvince">Tỉnh (State/Province)</Label>
                        <Input id="receiverProvince" name="receiverProvince" type="text" onChange={(e) => this.onInfoChange(e)} />
                      </FormGroup>

                      <FormGroup>
                        <Label for="receiverAddr1">Địa chỉ (address) 1 <span className={s.required}>*</span></Label>
                        <Input id="receiverAddr1" name="receiverAddr1" type="text" invalid={this.state.billValidation.receiverAddr1} onChange={(e) => this.onInfoChange(e)} />
                        <FormFeedback>{this.state.billValidation.receiverAddr1&&"Please input valid receiver address 1"}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="receiverAddr2">Địa chỉ (address) 2 <span className={s.required}>*</span></Label>
                        <Input id="receiverAddr2" name="receiverAddr2" type="text" invalid={this.state.billValidation.receiverAddr2} onChange={(e) => this.onInfoChange(e)} />
                        <FormFeedback>{this.state.billValidation.receiverAddr2&&"Please input valid receiver address 2"}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="receiverAddr3">Địa chỉ (address) 3</Label>
                        <Input id="receiverAddr3" name="receiverAddr3" type="text" onChange={(e) => this.onInfoChange(e)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {packTable}
{/*
            <Row>
              <div className="container mt-3">
                <div className="form-check d-flex justify-content-center  mb-3">
                  <div>
                    {" "}
                    <input
                      name="checkDk"
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                  </div>
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Tôi đã đọc, hiểu và đồng ý với các{" "}
                    <Link to="/app/tou" target="_blank">
                      {" "}
                      điều khoản sử dụng dịch vụ
                    </Link>
                  </label>
                  <small className="form-text  text-danger">
                    {this.state.validDkMess}
                  </small>
                </div>
              </div>
            </Row> */}

            <div className="row">
              <div className="container mt-3">
                <div className="input-group d-flex justify-content-center mb-3">
                  <span>
                    {" "}
                    {
                      !this.state.disableSubmitBtn && (
                        <Button
                          disabled={this.state.disableSubmitBtn}
                          color="success"
                          size="lg"
                          type="button"
                          className="btn-add"
                          onClick={(e) => this.onSubmit(e)}
                        >
                          Tạo Hoá đơn
                        </Button>
                      )
                    }

                  </span>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isCreating: state.packages.isCreating,
    package: state.packages.package,
    errMsg: state.packages.message,
    type: state.packages.type,
  };
}

export default connect(mapStateToProps)(Bill);
