import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jsonwebtoken';
import s from './Dashboard.module.scss';
import './main.css'

import { fetchDashboard, createDashboard } from '../../actions/dashboard';
import { USER_ROLE } from '../../constants';
import moment from 'moment';
import EditorArea from './EditorArea';
import { Link } from 'react-router-dom';
class Dashboard extends Component {
  /* eslint-disable */
  static propTypes = {
    dashboard: PropTypes.any,
    isFetching: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
  };
  /* eslint-enable */

  static defaultProps = {
    isFetching: false,
    dashboard: {},
    message: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      currUser: {},
      modalToggleNewDashboard: false,
      newDashboard: { title: '', body: '', highlight: false },
      isOpenToast: false,
    };
    this.onAddnewDashboard = this.onAddnewDashboard.bind(this);
    this.onChangeNewDashboard = this.onChangeNewDashboard.bind(this);
    this.toggleNewDashboard = this.toggleNewDashboard.bind(this);
  }

  toggleNewDashboard() {
    this.setState({
      modalToggleNewDashboard: !this.state.modalToggleNewDashboard,
    });
  }

  onAddnewDashboard(e) {
    e.preventDefault();
    this.props.dispatch(createDashboard(this.state.newDashboard));
    this.toggleNewDashboard();
    this.setState({
      isOpenToast: true,
      newDashboard: { title: '', body: '', highlight: false },
    });
  }

  onChangeNewDashboard(e, name) {
    // if (e.target.name === 'highlight') {
    //   this.setState({
    //     newDashboard: {
    //       ...this.state.newDashboard,
    //       [e.target.name]: e.target.checked,
    //     },
    //   });
    //   return;
    // }
    if (name) {
      this.setState({
        newDashboard: {
          ...this.state.newDashboard,
          [name]: e,
        },
      });
      return;
    }
    this.setState({
      newDashboard: {
        ...this.state.newDashboard,
        [e.target.name]: e.target.value,
      },
    });
  }

  componentDidMount() {
    let token = localStorage.getItem('id_token');
    let currUser = jwt.decode(token);
    this.setState({ currUser: currUser });

    this.props.dispatch(fetchDashboard());
  }

  render() {
    const { dashboard } = this.props;

    if (this.props.message && this.state.isOpenToast) {
      toast.error(this.props.message);
      this.setState({ isOpenToast: false });
    }
    const sortedDashboard = dashboard.sort(
      (a, b) => moment(b.updatedAt) - moment(a.updatedAt)
    );
    return (
      <div className={s.root}>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <Breadcrumb>
          <BreadcrumbItem>TRANG</BreadcrumbItem>
          <BreadcrumbItem active>Thông báo</BreadcrumbItem>
        </Breadcrumb>

        {/* New content of dashboard */}
        <Modal
          isOpen={this.state.modalToggleNewDashboard}
          toggle={this.toggleNewDashboard}
        >
          <ModalHeader>Tạo mới thông báo</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for='title'>Tiêu đề</Label>
              <Input
                value={this.state.newDashboard.title}
                name='title'
                id='title'
                placeholder='title'
                onChange={(e) => this.onChangeNewDashboard(e)}
              />
            </FormGroup>
            <FormGroup style={{ minHeight: '400px' }}>
              <Label for='content'>Nội dung</Label>
              <EditorArea
                value={this.state.newDashboard.body}
                onChange={(e) => this.onChangeNewDashboard(e, 'body')}
              />
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type='checkbox'
                  name='highlight'
                  value={this.state.newDashboard.highlight}
                  onChange={(e) => this.onChangeNewDashboard(e)}
                />{' '}
                Hiển thị thông báo nỗi bật
              </Label>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type='submit'
              color='secondary'
              onClick={(e) => this.onAddnewDashboard(e)}
            >
              Thêm mới
            </Button>
            <Button color='success' onClick={this.toggleNewDashboard}>
              Huỷ bỏ
            </Button>
          </ModalFooter>
        </Modal>

        <Row>
          {this.state.currUser.role === USER_ROLE.ADMIN && (
            <Col sm={12} className='mb-2 justify-content-end d-flex'>
              <Button className={s.btnAddNotification} onClick={this.toggleNewDashboard}>
                <i className='fa fa-plus-circle' /> Thêm thông báo
              </Button>
            </Col>
          )}
          <ListGroup className={s.listGroup}>
            {dashboard.length > 0 &&
              sortedDashboard.map((item) => (
                <ListGroupItem key={item.id}>
                  <div class={s.listGroupItem}>
                    <Link to={`/app/main/${item.id}`}>
                      <h5 class={`mb-1 ${item.highlight ? 'text-danger' : ''}`}>
                        {item.title || 'UNKNOWN'}
                      </h5>
                    </Link>

                    <small>{moment(item.updatedAt).fromNow()}</small>
                  </div>
                </ListGroupItem>
              ))}
          </ListGroup>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.dashboard.isFetching,
    dashboard: state.dashboard.dashboard,
    message: state.dashboard.message,
  };
}

export default connect(mapStateToProps)(Dashboard);
