import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Alert,
  Button,
  FormGroup,
  Input,
  Row,
  Col,
  Form
} from 'reactstrap';
import s from './Login.module.scss';
import Widget from '../../components/Widget';
import Footer from "../../components/Footer";
import { loginUser } from '../../actions/user';
import jwt from 'jsonwebtoken';
import config from '../../config'
import logo from '../../images/ringo/logo.png';
import bgLogin from '../../images/ringo/bg.jpg';

class Login extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    isFetching: PropTypes.bool,
    location: PropTypes.any, // eslint-disable-line
    errorMessage: PropTypes.string,
  };

  static defaultProps = {
    isAuthenticated: false,
    isFetching: false,
    location: {},
    errorMessage: null,
  };

  static isAuthenticated(token) {
    // We check if app runs with backend mode
    if (!config.isBackend && token) return true;
    if (!token) return;
    const date = new Date().getTime() / 1000;
    const data = jwt.decode(token);
    return date < data.exp;
}

  constructor(props) {
    super(props);

    this.state = {
      isShowPassword: false,
      login: '',
      password: '',
    };
  }

  changeLogin = (event) => {
    this.setState({login: event.target.value});
  }

  changePassword = (event) => {
    this.setState({password: event.target.value});
  }

  changePasswordType = () => {
    const { isShowPassword } = this.state
    this.setState({isShowPassword: !isShowPassword});
  }

  doLogin = (e) => {
    this.props.dispatch(
      loginUser({
        login: this.state.login,
        password: this.state.password,
        role: this.state.role,

      }),
    );
    e.preventDefault();
  }

  render() {
    const {from} = this.props.location.state || {
      from: {pathname: '/app'},
    };

    if (this.props.isAuthenticated) {
      // cant access login page while logged in
      return <Redirect to={from} />;
    }
      const { isShowPassword } = this.state
        return (
          <div className={s.root}>
          <div className={s.wrap + " px-0 overflow-auto"}>
            <Row className="m-0">
                <Col xs={12} sm={6} className={s.xx}  style={{background: '#EFF0F2'}}>
                  <div className={s.textWrap}>
                  <img src={bgLogin} alt="bg-login" className={s.bgLogin}/>
                  </div>
                </Col>
                <Col xs={12} sm={6} className={s.loginForm + " ml-auto login-form"}>
                  <p className="text-center">
                    <img src={logo} alt="LOGO" className={s.loginLogo}/>
                  </p>
                  <Widget className={s.widget}>
                    <h4 className={s.title + " mt-0"} >CHÀO MỪNG</h4>
                    {/*<p className={s.subTitle}>*/}
                    {/* chưa có tài khoản? Hãy liên lạc chúng tôi*/}
                    {/*</p>*/}
                    <Form className="mt" onSubmit={this.doLogin}>
                      {this.props.errorMessage && (
                        <Alert size="sm" color="danger">
                          {this.props.errorMessage}
                        </Alert>
                      )}
                      <FormGroup className="form-group">
                        <Input
                          className="no-border"
                          value={this.state.login}
                          onChange={this.changeLogin}
                          type="text"
                          required
                          name="username"
                          placeholder="Username"
                        />
                      </FormGroup>
                      <FormGroup className="position-relative">
                        <Input
                          className="no-border"
                          value={this.state.password}
                          onChange={this.changePassword}
                          type={isShowPassword ? "text" : "password"}
                          required
                          name="password"
                          placeholder="Password"
                        />
                        <span className={s.passwordIcon} onClick={() => this.changePasswordType()}>
                      <i className={"fa fa-eye" + (isShowPassword ? "-slash" : '')} />
                    </span>
                      </FormGroup>

                      <div>
                        <Link to="/forgot-password" className={s.forgotPassword} >Khôi phục mật khẩu?</Link>
                        <div className={s.buttonLogin}>
                          <Button color="success" size="sm" type="submit">
                            {this.props.isFetching ? 'Loading...' : 'ĐĂNG NHẬP'}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Widget>
                </Col>
              </Row>
          </div>
          <Footer className="text-center" />
          </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
        errorMessage: state.auth.errorMessage,
    };
}

export default withRouter(connect(mapStateToProps)(Login));

