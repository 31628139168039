export const FETCH_DASHBOARD_REQUEST = 'FETCH_DASHBOARD_REQUEST';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE';

export const FETCH_DASHBOARD_DETAIL_REQUEST = 'FETCH_DASHBOARD_DETAIL_REQUEST';
export const FETCH_DASHBOARD_DETAIL_SUCCESS = 'FETCH_DASHBOARD_DETAIL_SUCCESS';
export const FETCH_DASHBOARD_DETAIL_FAILURE = 'FETCH_DASHBOARD_DETAIL_FAILURE';

export const UPDATE_DASHBOARD_REQUEST = 'UPDATE_DASHBOARD_REQUEST';
export const UPDATE_DASHBOARD_SUCCESS = 'UPDATE_DASHBOARD_SUCCESS';
export const UPDATE_DASHBOARD_FAILURE = 'UPDATE_DASHBOARD_FAILURE';

export const DELETE_DASHBOARD_REQUEST  = 'DELETE_DASHBOARD_REQUEST';
export const DELETE_DASHBOARD_SUCCESS  = 'DELETE_DASHBOARD_SUCCESS';
export const DELETE_DASHBOARD_FAILURE  = 'DELETE_DASHBOARD_FAILURE';

export const CREATE_DASHBOARD_REQUEST  = 'CREATE_DASHBOARD_REQUEST';
export const CREATE_DASHBOARD_SUCCESS  = 'CREATE_DASHBOARD_SUCCESS';
export const CREATE_DASHBOARD_FAILURE  = 'CREATE_DASHBOARD_FAILURE';


// /**************************** Get User By ID ****************************/
// export async function fetchUserByID(id) {
//   const config = {
//     method: 'POST',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       query: `{userByID(id:"${id}"){id,displayName,companyName,address,email,role,city,country}}`,
//     }),
//     credentials: 'include',
//   };

//   const resp = await fetch('/graphql', config)
//   const respJSON = await resp.json()
//   if (respJSON.errors) {
//     console.log(respJSON.errors)
//     return {}
//   }

//   return respJSON.data.userByID
// }


/**************************** Fetch Dashboard ****************************/
export function requestDashboard() {
  return {
    type: FETCH_DASHBOARD_REQUEST,
    isFetching: true,
  };
}

export function fetchDashboardSuccess(dashboard) {
  return {
    type: FETCH_DASHBOARD_SUCCESS,
    isFetching: false,
    dashboard,
  };
}

export function fetchDashboardError(message) {
  return {
    type: FETCH_DASHBOARD_FAILURE,
    isFetching: false,
    message,
  };
}

export function fetchDashboard() {
  // const config = {
  //   method: 'POST',
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify({
  //     query: '{dashboard{title,body,highlight,id}}',
  //   }),
  //   credentials: 'include',
  // };

  return dispatch => {
    dispatch(requestDashboard());

    return fetch('/notifications')
      .then(response =>
        response.json().then(responseJson => ({
          dashboard: responseJson.list,
          responseJson,
        })),
      )
      .then(({ dashboard, responseJson }) => {
        if (!responseJson.list) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(fetchDashboardError(responseJson?.errors[0]?.message));
          return Promise.reject(responseJson.errors);
        }
        // Dispatch the success action
        dispatch(fetchDashboardSuccess(dashboard));
        return Promise.resolve(dashboard);
      })
      .catch(err => console.error('Error: ', err));
  };
}


/**************************** Fetch Dashboard Detail ****************************/
export function requestDashboardDetail() {
  return {
    type: FETCH_DASHBOARD_DETAIL_REQUEST,
    isFetching: true,
  };
}

export function fetchDashboardDetailSuccess(dashboardById) {
  return {
    type: FETCH_DASHBOARD_DETAIL_SUCCESS,
    isFetching: false,
    dashboardById,
  };
}

export function fetchDashboardDetailError(message) {
  return {
    type: FETCH_DASHBOARD_DETAIL_FAILURE,
    isFetching: false,
    message,
  };
}

export function fetchDashboardById(dashboardId) {
  return dispatch => {
    dispatch(requestDashboardDetail());

    return fetch(`/notifications/${dashboardId}`)
      .then(response =>
        response.json().then(responseJson => ({
          dashboardById: responseJson,
          responseJson,
        })),
      )
      .then(({ dashboardById, responseJson }) => {
        if (!responseJson) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(fetchDashboardDetailError(responseJson?.errors[0]?.message));
          return Promise.reject(responseJson.errors);
        }
        // Dispatch the success action
        dispatch(fetchDashboardDetailSuccess(dashboardById));
        return Promise.resolve(dashboardById);
      })
      .catch(err => console.error('Error: ', err));
  };
}


/**************************** Update Dashboard ****************************/
export function requestUpdateDashboard() {
  return {
    type: UPDATE_DASHBOARD_REQUEST,
    isUpdating: true,
  };
}

export function updateDashboardSuccess(dashboard) {
  return {
    type: UPDATE_DASHBOARD_SUCCESS,
    isUpdating: false,
    dashboard,
  };
}

export function updateDashboardError(message) {
  return {
    type: UPDATE_DASHBOARD_FAILURE,
    isUpdating: false,
    message,
  };
}

export function updateDashboard(postData) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `mutation{
        updateDashboard(
          id: ${postData.id},
          highlight: ${postData.highlight},
          title: """${postData.title}""",
          body:"""${postData.body}""")
          {title, body, highlight, id}}`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestUpdateDashboard());

    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
          responseJson,
        })),
      )
      .then(({ responseJson }) => {
        if (responseJson.errors) {
          dispatch(updateDashboardError(responseJson.errors[0].message));
          return Promise.reject(responseJson.errors);
        }

        dispatch(updateDashboardSuccess(responseJson.data.updateDashboard));
        return Promise.resolve(responseJson.data.updateDashboard);
      })
      .catch(err => {
        dispatch(updateDashboardError("update dashboard faile"));
        console.error('Error: ', err)
      });
  };
}

/**************************** Delete Dashboard ****************************/
export function requestDeleteDashboard() {
  return {
    type: DELETE_DASHBOARD_REQUEST,
    isUpdating: true,
  };
}

export function deleteDashboardSuccess(dashboard) {
  return {
    type: DELETE_DASHBOARD_SUCCESS,
    isUpdating: false,
    dashboard,
  };
}

export function deleteDashboardError(message) {
  return {
    type: DELETE_DASHBOARD_FAILURE,
    isUpdating: false,
    message,
  };
}

export function deleteDashboard(id) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `mutation{deleteNotification(id: ${id}){id}}`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestDeleteDashboard());
    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
          responseJson,
        })),
      )
      .then(({ responseJson }) => {
        if (responseJson.errors) {
          dispatch(deleteDashboardError(responseJson.errors[0].message));
          return Promise.reject(responseJson.errors);
        }

        dispatch(deleteDashboardSuccess(responseJson.data.deleteNotification));
        return Promise.resolve(responseJson.data.deleteNotification);
      })
      .catch(err => console.error('Error: ', err));
  };
}


/**************************** New Dashboard ****************************/
export function requestCreateDashboard() {
  return {
    type: CREATE_DASHBOARD_REQUEST,
    isUpdating: true,
  };
}

export function createDashboardSuccess(dashboard) {
  return {
    type: CREATE_DASHBOARD_SUCCESS,
    isUpdating: false,
    dashboard,
  };
}

export function createDashboardError(message) {
  return {
    type: CREATE_DASHBOARD_FAILURE,
    isUpdating: false,
    message,
  };
}

export function createDashboard(postData) {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `mutation{
        createNotification(
          highlight: ${postData.highlight},
          title: """${postData.title}""",
          body:"""${postData.body}""")
          {title, body, highlight, id}}`,
    }),
    credentials: 'include',
  };

  return dispatch => {
    dispatch(requestCreateDashboard());

    return fetch('/graphql', config)
      .then(response =>
        response.json().then(responseJson => ({
          responseJson,
        })),
      )
      .then(({ responseJson }) => {
        if (responseJson.errors) {
          dispatch(createDashboardError(responseJson.errors[0].message));
          return Promise.reject(responseJson.errors);
        }

        dispatch(createDashboardSuccess(responseJson.data.createNotification));
        return Promise.resolve(responseJson.data.createNotification);
      })
      .catch(err => {
        dispatch(createDashboardError("New dashboard failed"));
        console.error('Error: ', err)
    });
  };
}


