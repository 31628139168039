import logo from "../../images/logo.png";
import barcode from '../../images/hopdong/barcode.png';
import React from 'react';
import {fetchUserByID} from '../../actions/user'
import {Row} from 'reactstrap';
import {DOC_TYPE} from '../../constants';
import s from './Packet.module.scss';
import Rounding from '../../utils/rounding';
import Barcode from 'react-jsbarcode'
import axios from "axios";

class BillToPrint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            billCreator: {},
            logoUrl: logo,
            billupCreator: {}
        }
    }

    async componentDidMount() {
        this.setState({billCreator: await fetchUserByID(this.props.billData.companyUserID)})
        try {
            const {data: user} = await axios.get(`/user-by-name/${this.props.billData.owner}`)
            this.setState({billupCreator: user})
            if (user?.role === 'ops') {
                this.setState({logoUrl: "/kango_logos/" + this.props.billData.companyUserID + "-logo.png"})
            }
        } catch (error) {
            console.log(error)
        }
    }

    calWeight(packs) {
        return packs.reduce(function (a, b) {
            return a + parseFloat(b["weight"]) * parseFloat(b["quantity"])
        }, 0) || ""
    }

    render() {
        var packs = JSON.parse(this.props.billData.packs)

        const billToPrint = <table id="billprinted" width="100%" bordered="true" style={{"fontSize": "100%"}}>

      <tbody cellPadding="" cellSpacing="0" >
        <tr className="text-center">
          <td rowSpan="2" style={{width: '30%'}} > <img src={logo} className="img-fluid" alt="Responsive" /></td>
          <td colSpan="2" className="text-center" > <h4 className="text-danger text-uppercase"> <strong> Ringo Logistics </strong> </h4> </td>
          <td colSpan="3" > <Barcode style={{width: '150px', height: 'auto'}} options={{displayValue: false}} renderer="image" value={this.props.billData.kgCode}/> </td>
        </tr>

            <tr className="text-center">

          <td padding="5px" colSpan="2" > Tổng đài: 1900 4797</td>
          <td colSpan="3"> Air waybill : <h3><strong>{this.props.billData.kgCode}</strong></h3> </td>
        </tr>
        <tr className="text-center">
          <td padding="5px" colSpan="2"> Website: https://gopost.vn/ </td>
          <td colSpan="3"> Service: {this.props.billData.logisticService} </td>
        </tr>

            <tr className="b-title">
                <td colSpan="6" height="35px" className="table-warning">
                    <div><strong> 1. From <i>(Shipper) </i>:</strong></div>
                </td>
            </tr>
            <tr height="30px">
                <td rowSpan="2" colSpan="3">

                    <Row>
                        <div className="col-3">

                            <span padding-left="5px">Company Name:  </span> <br></br>
                            <span> Address: </span> <br/>
                            <span> City:   </span> <br/>
                            <span> Country: </span> <br/>
                            <span> Email:  </span> <br/>
                            <span> Contact Name:    </span> <br/>
                            <span> Phone/Fax No:  </span>

                        </div>

                        <div className="col-9">

                            <span
                                className="text-uppercase font-weight-bold">   {this.props.billData.senderCompanyName}  </span>
                            <br></br>
                            <span className="font-weight-bold"> {this.props.billData.senderAddress}  </span> <br/>
                            <span className="font-weight-bold"> {this.state.billCreator?.city}   </span> <br/>
                            <span className="text-uppercase font-weight-bold"> {this.state.billCreator?.country} </span>
                            <br/>
                            <span
                                className="border-dark font-weight-bold "> {this.state.billCreator?.email} &nbsp; &nbsp; </span>
                            <br/>
                            <span className="font-weight-bold"> {this.props.billData?.senderName}  </span> <br/>
                            <span className="font-weight-bold">   {this.props.billData?.senderPhone} </span>

                        </div>
                    </Row>

                </td>

                <td className="b-title text-center table-warning font-weight-bold" width="120px" spacing="0px">
                    <div padding="0px" className="table-warning"> Total number of Packages</div>
                </td>
                <td className="b-title text-center table-warning font-weight-bold" width="120px">
                    <div padding="0px" className="table-warning">Gross Weight</div>
                </td>
                <td className="b-title text-center table-warning font-weight-bold" width="120px">
                    <div padding="0px" className="table-warning">Chargeable Weight</div>
                </td>

            </tr>
            <tr className="text-center">
                <td> {(this.props.billData.packageType === DOC_TYPE) ? this.props.billData.docCount : packs.reduce(function (a, b) {
                    return a + parseFloat(b["quantity"])
                }, 0) || ""}</td>
                <td> {(this.props.billData.packageType === DOC_TYPE) ? this.props.billData.docWeight : this.calWeight(packs)}</td>
                <td> {(this.props.billData.packageType === DOC_TYPE) ? this.props.billData.docWeight : Rounding(packs.reduce(function (a, b) {
                    return a + parseFloat(b["chargedWeight"])
                }, 0)) || ""}</td>
            </tr>

            <tr className="b-title ">
                <td colSpan="6" height="35px" className="table-warning">
                    <div><strong> 2. To <i>(Receiver)</i>: </strong></div>
                </td>
            </tr>
            <tr>
                <td rowSpan="4" colSpan="2">

                    <div className="row fluid-inline">
                        <div padding="0px" className="col-3">

                            <span style={{display: 'inline-block', minWidth: '150px'}}>Company Name:</span> <br/>
                            <span> Address: </span> <br/> <br/> <br/>

                            <span> City/Town:    </span> <br/>
                            <span>  Postal code: </span> <br/>
                            <span> Country:    </span> <br/>
                            <span> Contact Name:    </span> <br/>
                            <span>Phone/Fax No:  </span> <br/>

                        </div>

                        <div className="col-9">

                            <span
                                className="text-uppercase font-weight-bold">  &emsp;&nbsp;&nbsp;&nbsp; {this.props.billData.receiverCompanyName}  </span>
                            <br/>
                            <span className="font-weight-bold"> &emsp;&emsp;{this.props.billData.receiverAddr1}
                                <br/> &emsp;&emsp;{this.props.billData.receiverAddr2}
                                <br/> &emsp;&emsp;{this.props.billData.receiverAddr3} <br/> </span>
                            <span
                                className="font-weight-bold"> &emsp;&emsp;{this.props.billData.receiverCity} - {this.props.billData.receiverProvince}</span>
                            <br/>
                            <span
                                className="font-weight-bold"> &emsp;&emsp;{this.props.billData.receiverPostalCode} </span>
                            <br/>
                            <span
                                className="text-uppercase font-weight-bold"> &emsp;&emsp;{this.props.billData.receiverCountry}  </span>
                            <br/>
                            <span
                                className="text-uppercase font-weight-bold"> &emsp;&emsp;{this.props.billData.receiverName}  </span>
                            <br/>
                            <span className="font-weight-bold"> &emsp;&emsp;{this.props.billData.receiverPhone} </span>
                            <br/>

                        </div>
                    </div>
                </td>
                <td className={"font-weight-bold " + s.crossed} width="125px"
                    height="30px"> &emsp; &emsp;&emsp;&nbsp;&nbsp;Dimensions<br/>Pieces
                </td>
                <td className="text-center font-weight-bold"> L (cm)</td>
                <td className="text-center font-weight-bold"> W (cm)</td>
                <td className="text-center font-weight-bold"> H (cm)</td>
            </tr>

            <tr className="text-center" height="25px">
                <td className="text-center"> {(packs.length > 0) && packs[0].quantity} </td>
                <td> {(packs.length > 0) && packs[0].length} </td>
                <td> {(packs.length > 0) && packs[0].width}</td>
                <td> {(packs.length > 0) && packs[0].height}</td>
            </tr>

            <tr className="text-center" height="30px">
                <td className="text-center"> {(packs.length > 1) && packs[1].quantity} </td>
                <td> {(packs.length > 1) && packs[1].length} </td>
                <td> {(packs.length > 1) && packs[1].width}</td>
                <td> {(packs.length > 1) && packs[1].height}</td>
            </tr>

            <tr className="text-center" height="30px">
                <td className="text-center"> {(packs.length > 2) && packs[2].quantity} </td>
                <td> {(packs.length > 2) && packs[2].length} </td>
                <td> {(packs.length > 2) && packs[2].width}</td>
                <td> {(packs.length > 2) && packs[2].height}</td>

            </tr>
            <tr className="b-title ">
                <td colSpan="6" height="60px">
                    <div className="table-warning"><strong> 3. Thông tin Đơn hàng <i>(Shipment Information)</i>
                    </strong></div>
                    <div> Mô tả hàng hóa : ( Description of goods
                        ):<strong> {(this.props.billData.packageType === DOC_TYPE) ? "Documents" : this.props.billData.packContent} </strong>
                    </div>
                </td>

            </tr>
            <tr height="30px">
                <td colSpan="6">
                    Giá trị (value invoice): <strong>{this.props.billData.packInvoiceValue}</strong> &emsp;
                    {this.props.billData.packInvoiceUnit}
                </td>
            </tr>
            <tr>
                <td className="font-weight-bold"> Chữ ký người gửi (Shipper Signature) <br/>
                    Ngày, giờ (Date /time):
                </td>
                <td> {new Date(this.props.billData.createdAt).toLocaleDateString()} </td>
                <td colSpan="3" className="font-weight-bold"> Nhân viên nhận hàng (Picked up by) <br/>
                    Ngày, giờ (Date /time):
                </td>
                <td colSpan="1"> {new Date(this.props.billData.createdAt).toLocaleDateString()}</td>
            </tr>
            <tr height="50px">
                <td colSpan="2">
                </td>
                <td colSpan="4">
                </td>
            </tr>

            </tbody>

        </table>

        return (
            <div>
                <div style={{pageBreakAfter: 'always'}}>
                    {billToPrint}
                </div>
                <br/>
                <div style={{pageBreakAfter: 'always'}}>
                    {billToPrint}
                </div>
                <br/>
                <div style={{pageBreakAfter: 'always'}}>
                    {billToPrint}
                </div>
            </div>

        );
    }
}

export default BillToPrint;
